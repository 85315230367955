#tt-contact-form {
    label.error {
        color: #ee4040;
        font-size: 12px;
        margin: 0;
        margin-top: 4px;
        display: block;
    }

    textarea {
        resize: none;
    }

    input.error,
    textarea.error {
        box-shadow: inset 0 0 0 1px rgba(238, 63, 64, .6);
    }
}

.privacy-block {

    p,
    li {
        font-size: 16px;
    }

    p {
        margin-bottom: 16px;
    }

    p+ul {
        margin-top: -6px;
    }

    li {
        margin-bottom: 6px;
    }


    h2 {
        margin-bottom: 12px;
    }

    h3 {
        margin-bottom: 8px;
    }

    h4 {
        margin-bottom: 4px;
    }

    li {
        margin-bottom: 4px;

        p {
            margin-bottom: 0;
        }
    }



}