/* ---------------------------------------------------------------------------------------
* Template Helper CSS
* 
* Template:		Nui - Creative Portfolio Showcase HTML Website Template
* Author:		Themetorium
* URL:			https://themetorium.net/
*
* Secondary Font: 'Syne', sans-serif;
* Main color:  #b12c0d;
*
* You are free to use these extra prepared helper classes to customize your web site.
--------------------------------------------------------------------------------------- */


/* Table of Content
====================
* Global variables
* Various classes
* Hiding classes
* Background image classes
* Font/text classes
* Hover classes
* Width classes
* Height classes
* Center align classes
* Text color classes
* Link style classes
* Link color classes
* Background color classes
* Rounded classes
* Borders classes
* Paddings classes
* Margin classes
* Grid system (similar logic to the Bootstrap framework).
*/




/* ------------------------------------------------------------- *
 * Global variables
/* ------------------------------------------------------------- */

:root {
	--tt-main-color: #b12c0d; /* Template main color. */
}


/* ------------------------------------------------------------- *
 * Various classes
/* ------------------------------------------------------------- */

/* clear */
.clear { clear: both !important; display: block !important; }
.clear::after {
	content: "";
	display: table;
	clear: both;
}

/* display */
.display-none { display: none !important; }
.block, .display-block { display: block !important; }
.inline-block, .display-inline-block { display: inline-block !important; }
.display-flex { display: flex !important; display: -ms-flexbox !important; }

/* position */
.position-unset { position: unset !important; }
.position-relative { position: relative !important; }
.position-absolute { position: absolute !important; }
.position-static { position: static !important; }

/* overflow */
.overflow-visible { overflow: visible !important; }

.overflow-hidden { overflow: hidden !important; }
.overflow-y-hidden { overflow-y: hidden !important; }
.overflow-x-hidden { overflow-x: hidden !important; }

.overflow-auto { overflow: auto !important; }
.overflow-y-auto { overflow-y: auto !important; }
.overflow-x-auto { overflow-x: auto !important; }

.overflow-scroll { overflow: scroll !important; }
.overflow-y-scroll { overflow-y: scroll !important; }
.overflow-x-scroll { overflow-x: scroll !important; }

/* list styled */
ul.list-styled,
ul.styled-list,
ul.tt-list {
	margin: 0;
	padding: 0;
	list-style: none;
}
ul.tt-list > li {
	margin-bottom: 5px;
}

/* z-index */
.z-index-1 { z-index: 1 !important; }
.z-index-2 { z-index: 2 !important; }
.z-index-3 { z-index: 3 !important; }
.z-index-4 { z-index: 4 !important; }
.z-index-5 { z-index: 5 !important; }
.z-index-6 { z-index: 6 !important; }
.z-index-7 { z-index: 7 !important; }
.z-index-8 { z-index: 8 !important; }
.z-index-9 { z-index: 9 !important; }
.z-index-full { z-index: 99999 !important; }


/* ----------------------------------------------------------------------------------- *
 * Cover classes (used as image overlay)
 * Note: parent element must contain "position: relative;" or "position: absolute;"
/* ----------------------------------------------------------------------------------- */

/* Cover 
========= */
.tt-cover:before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
}


/* Cover opasity
================= */
[class*="cover-opacity-"]::before {
	position: absolute;
	display: block;
	content: "";
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #040404;
	z-index: 1;
	opacity: 0;
}

.cover-opacity-0::before { opacity: 0; }
.cover-opacity-0-5::before { opacity: .05; }
.cover-opacity-1::before { opacity: .1; }
.cover-opacity-1-5::before { opacity: .15; }
.cover-opacity-2::before { opacity: .2; }
.cover-opacity-2-5::before { opacity: .25; }
.cover-opacity-3::before { opacity: .3; }
.cover-opacity-3-5::before { opacity: .35; }
.cover-opacity-4::before { opacity: .4; }
.cover-opacity-4-5::before { opacity: .45; }
.cover-opacity-5::before { opacity: .5; }
.cover-opacity-5-5::before { opacity: .55; }
.cover-opacity-6::before { opacity: .6; }
.cover-opacity-6-5::before { opacity: .65; }
.cover-opacity-7::before { opacity: .7; }
.cover-opacity-7-5::before { opacity: .75; }
.cover-opacity-8::before { opacity: .8; }
.cover-opacity-8-5::before { opacity: .85; }
.cover-opacity-9::before { opacity: .9; }
.cover-opacity-9-5::before { opacity: .95; }


/* ------------------------------------------------------------- *
 * Hiding classes
/* ------------------------------------------------------------- */

.hide { display: none !important; }

/* Hiding via media query breakpoints */
@media (max-width: 1024px) {
	.hide-from-lg { display: none !important; }
}
@media (max-width: 992px) {
	.hide-from-md { display: none !important; }
}
@media (max-width: 768px) {
	.hide-from-sm { display: none !important; }
}
@media (max-width: 480px) {
	.hide-from-xs { display: none !important; }
}

@media (min-width: 1025px) {
	.hide-to-lg { display: none !important; }
}
@media (min-width: 993px) {
	.hide-to-md { display: none !important; }
}
@media (min-width: 769px) {
	.hide-to-sm { display: none !important; }
}
@media (min-width: 481px) {
	.hide-to-xs { display: none !important; }
}

/* Hiding from mobile device */
body.is-mobile .hide-from-mobile { display: none !important; }

/* Hiding if not mobile device */
body:not(.is-mobile) .hide-to-mobile { display: none !important; }


/* ------------------------------------------------------------- *
 * Background image classes
/* ------------------------------------------------------------- */

.no-bg-image { background: none !important; }

.bg-image {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	-webkit-background-size: cover;
	background-size: cover;
}
.bg-image-fixed {
	background-attachment: fixed;
	background-position: 50% 50%;
	-webkit-background-size: cover;
	background-size: cover;
	transform: none !important; /* transform not work well with background-attachment: fixed; */
}
.bg-image-pattern {
	background-repeat: repeat !important;
	-webkit-background-size: inherit !important;
	background-size: inherit !important;
}


/* ------------------------------------------------------------- *
 * Font/text classes
/* ------------------------------------------------------------- */

/* font alter */
.font-alter { 
	font-family: 'Syne', sans-serif !important;
}

/* font styles */
.font-italic, .italic { font-style: italic !important; }

/* font weight */
.font-normal { font-weight: normal !important; font-style: normal !important; }
.font-bold, .font-strong, .bold { font-weight: bold !important; }

.font-100 { font-weight: 100 !important; }
.font-200 { font-weight: 200 !important; }
.font-300 { font-weight: 300 !important; }
.font-400 { font-weight: 400 !important; }
.font-500 { font-weight: 500 !important; }
.font-600 { font-weight: 600 !important; }
.font-700 { font-weight: 700 !important; }
.font-800 { font-weight: 800 !important; }
.font-900 { font-weight: 900 !important; }

/* text sizes */
.text-lg {
	font-size: calc(100% + 0.2vw) !important;
}
.text-lg p,
p.text-lg {
	margin-bottom: 30px !important;
}

.text-xlg {
	font-size: calc(100% + 0.4vw) !important;
}
.text-xlg p,
p.text-xlg {
	margin-bottom: 3.5% !important;
}

.text-xxlg {
	font-size: calc(100% + 0.7vw) !important;
}
.text-xxlg p,
p.text-xxlg {
	margin-bottom: 3.5% !important;
}

.text-xxxlg {
	font-size: calc(100% + 1vw) !important;
}
.text-xxxlg p,
p.text-xxxlg {
	margin-bottom: 4.5% !important;
}

/* letter spacing */
.letter-spacing-0 { letter-spacing: 0 !important; }
.letter-spacing-1 { letter-spacing: 1px !important; }
.letter-spacing-2 { letter-spacing: 2px !important; }
.letter-spacing-3 { letter-spacing: 3px !important; }
.letter-spacing-4 { letter-spacing: 4px !important; }
.letter-spacing-5 { letter-spacing: 5px !important; }
.letter-spacing-6 { letter-spacing: 6px !important; }
.letter-spacing-7 { letter-spacing: 7px !important; }
.letter-spacing-8 { letter-spacing: 8px !important; }
.letter-spacing-9 { letter-spacing: 9px !important; }
.letter-spacing-10 { letter-spacing: 10px !important; }
.letter-spacing-11 { letter-spacing: 11px !important; }
.letter-spacing-12 { letter-spacing: 12px !important; }
.letter-spacing-13 { letter-spacing: 13px !important; }
.letter-spacing-14 { letter-spacing: 14px !important; }
.letter-spacing-15 { letter-spacing: 15px !important; }

/* text normal */
.text-normal { 
	text-transform: none !important; 
	letter-spacing: 0 !important;
}

/* text aligns */
.text-align-unset { text-align: unset !important; }
.text-center { text-align: center !important; }
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }

/* text aligns via breakpoints */
@media (max-width: 1024px) {
	.text-center-from-xlg { text-align: center !important; }
	.text-left-from-xlg { text-align: left !important; }
	.text-right-from-xlg { text-align: right !important; }
}
@media (max-width: 991px) {
	.text-center-from-lg { text-align: center !important; }
	.text-left-from-lg { text-align: left !important; }
	.text-right-from-lg { text-align: right !important; }
}
@media (max-width: 767px) {
	.text-center-from-md { text-align: center !important; }
	.text-left-from-md { text-align: left !important; }
	.text-right-from-md { text-align: right !important; }
}
@media (max-width: 479px) {
	.text-center-from-sm { text-align: center !important; }
	.text-left-from-sm { text-align: left !important; }
	.text-right-from-sm { text-align: right !important; }
}

@media (min-width: 1025px) {
	.text-center-to-xlg { text-align: center !important; }
	.text-left-to-xlg { text-align: left !important; }
	.text-right-to-xlg { text-align: right !important; }
}
@media (min-width: 992px) {
	.text-center-to-lg { text-align: center !important; }
	.text-left-to-lg { text-align: left !important; }
	.text-right-to-lg { text-align: right !important; }
}
@media (min-width: 768px) {
	.text-center-to-md { text-align: center !important; }
	.text-left-to-md { text-align: left !important; }
	.text-right-to-md { text-align: right !important; }
}
@media (min-width: 480px) {
	.text-center-to-sm { text-align: center !important; }
	.text-left-to-sm { text-align: left !important; }
	.text-right-to-sm { text-align: right !important; }
}

/* text transform */
.text-uppercase { text-transform: uppercase !important; }
.text-lowercase { text-transform: lowercase !important; }
.text-capitalize { text-transform: capitalize !important; }
.text-no-transform { text-transform: none !important; }

/* text stroke */
.text-stroke-dark {
	font-weight: bold;
	font-weight: 700;
	color: transparent;
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: #111;
	-webkit-text-fill-color: transparent;
}
.text-stroke-light {
	font-weight: bold;
	font-weight: 700;
	color: transparent;
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: #FFF;
	-webkit-text-fill-color: transparent;
}


/* ------------------------------------------------------------- *
 * Hover classes
/* ------------------------------------------------------------- */

/* hover opacity */
.hover-opacity { 
	transition: all .2s ease-in-out !important;
}
.hover-opacity:hover {
	opacity: 0.8 !important;
}

/* hover zoom */
.hover-zoom { 
	transition: all .3s ease-in-out !important;
}
.hover-zoom:hover {
	transform: scale(1.05);
}

/* hover hop */
.hover-hop { 
	transition: all .3s ease-in-out !important;
}
.hover-hop:hover {
	transform: translateY(-10px) !important;
}

/* hover shadow */
.hover-shadow { 
	transition: all .3s ease-in-out !important;
}
.hover-shadow:hover {
	box-shadow: 0 24px 20px -20px rgba(0, 0, 0, 0.6);
}


/* ------------------------------------------------------------- *
 * Width classes
/* ------------------------------------------------------------- */

/* Width auto */
.width-auto { width: auto !important; }

/* Full width */
.full-width { width: 100% !important; }

/* Minimum width classes */
.min-width { min-width: 100% !important; }
.min-width-100 { min-width: 100px !important; }
.min-width-150 { min-width: 150px !important; }
.min-width-200 { min-width: 200px !important; }
.min-width-250 { min-width: 250px !important; }
.min-width-300 { min-width: 300px !important; }
.min-width-350 { min-width: 350px !important; }
.min-width-400 { min-width: 400px !important; }
.min-width-450 { min-width: 450px !important; }
.min-width-500 { min-width: 500px !important; }
.min-width-550 { min-width: 550px !important; }
.min-width-600 { min-width: 600px !important; }
.min-width-650 { min-width: 650px !important; }
.min-width-700 { min-width: 700px !important; }
.min-width-750 { min-width: 750px !important; }
.min-width-800 { min-width: 800px !important; }
.min-width-850 { min-width: 850px !important; }
.min-width-900 { min-width: 900px !important; }
.min-width-950 { min-width: 950px !important; }
.min-width-1000 { min-width: 1000px !important; }
.min-width-1050 { min-width: 1050px !important; }
.min-width-1150 { min-width: 1150px !important; }
.min-width-1100 { min-width: 1100px !important; }
.min-width-1150 { min-width: 1150px !important; }
.min-width-1170 { min-width: 1170px !important; }
.min-width-1200 { min-width: 1200px !important; }
.min-width-1250 { min-width: 1250px !important; }
.min-width-1300 { min-width: 1300px !important; }
.min-width-1350 { min-width: 1350px !important; }
.min-width-1400 { min-width: 1400px !important; }
.min-width-1450 { min-width: 1450px !important; }
.min-width-1500 { min-width: 1500px !important; }
.min-width-1550 { min-width: 1550px !important; }
.min-width-1600 { min-width: 1600px !important; }

/* Maximum width classes */
.max-width { max-width: 100% !important; }
.max-width-100 { max-width: 100px !important; }
.max-width-150 { max-width: 150px !important; }
.max-width-200 { max-width: 200px !important; }
.max-width-250 { max-width: 250px !important; }
.max-width-300 { max-width: 300px !important; }
.max-width-350 { max-width: 350px !important; }
.max-width-400 { max-width: 400px !important; }
.max-width-450 { max-width: 450px !important; }
.max-width-500 { max-width: 500px !important; }
.max-width-550 { max-width: 550px !important; }
.max-width-600 { max-width: 600px !important; }
.max-width-650 { max-width: 650px !important; }
.max-width-700 { max-width: 700px !important; }
.max-width-750 { max-width: 750px !important; }
.max-width-800 { max-width: 800px !important; }
.max-width-850 { max-width: 850px !important; }
.max-width-900 { max-width: 900px !important; }
.max-width-950 { max-width: 950px !important; }
.max-width-1000 { max-width: 1000px !important; }
.max-width-1050 { max-width: 1050px !important; }
.max-width-1150 { max-width: 1150px !important; }
.max-width-1100 { max-width: 1100px !important; }
.max-width-1150 { max-width: 1150px !important; }
.max-width-1170 { max-width: 1170px !important; }
.max-width-1200 { max-width: 1200px !important; }
.max-width-1250 { max-width: 1250px !important; }
.max-width-1300 { max-width: 1300px !important; }
.max-width-1350 { max-width: 1350px !important; }
.max-width-1400 { max-width: 1400px !important; }
.max-width-1450 { max-width: 1450px !important; }
.max-width-1500 { max-width: 1500px !important; }
.max-width-1550 { max-width: 1550px !important; }
.max-width-1600 { max-width: 1600px !important; }
.max-width-1650 { max-width: 1650px !important; }
.max-width-1700 { max-width: 1700px !important; }
.max-width-1750 { max-width: 1750px !important; }
.max-width-1800 { max-width: 1800px !important; }
.max-width-1850 { max-width: 1850px !important; }
.max-width-1900 { max-width: 1900px !important; }
.max-width-1950 { max-width: 1950px !important; }
.max-width-2000 { max-width: 2000px !important; }
.max-width-2050 { max-width: 2050px !important; }
.max-width-2100 { max-width: 2100px !important; }
.max-width-2150 { max-width: 2150px !important; }
.max-width-2200 { max-width: 2200px !important; }
.max-width-2250 { max-width: 2250px !important; }
.max-width-2300 { max-width: 2300px !important; }
.max-width-2350 { max-width: 2350px !important; }
.max-width-2400 { max-width: 2400px !important; }
.max-width-2450 { max-width: 2450px !important; }
.max-width-2500 { max-width: 2500px !important; }
.max-width-2550 { max-width: 2550px !important; }
.max-width-2600 { max-width: 2600px !important; }
.max-width-2650 { max-width: 2650px !important; }
.max-width-2700 { max-width: 2700px !important; }
.max-width-2750 { max-width: 2750px !important; }
.max-width-2800 { max-width: 2800px !important; }
.max-width-2850 { max-width: 2850px !important; }
.max-width-2900 { max-width: 2900px !important; }
.max-width-2950 { max-width: 2950px !important; }
.max-width-3000 { max-width: 3000px !important; }


/* ------------------------------------------------------------- *
 * Height classes
/* ------------------------------------------------------------- */

.full-height { height: 100% !important; }

/* full height (vh) on desktop only */
@media (min-width: 1025px) {
	.full-height-vh { 
		height: 100vh;
		min-height: 100vh;
	}
	body.header-fixed-top-on .full-height-vh {
		height: calc(100vh - 80px); /* minus same as header height */
		min-height: calc(100vh - 80px); /* minus same as header height */
	}
}

/* full height (vh) on small screens only */
@media (max-width: 1024px) {
	.full-height-vh-m { 
		height: calc(100vh - 64px); /* minus same as mobile header height */
		min-height: calc(100vh - 64px); /* minus same as mobile header height */
	}
	body.header-show-hide-on-scroll-on.page-header-on .full-height-vh-m { 
		height: 100vh;
		min-height: 100vh;
	}
}


/* height classes (vh) */
.height-vh-5 { height: 5vh !important; min-height: 5vh !important; }
.height-vh-10 { height: 10vh !important; min-height: 10vh !important; }
.height-vh-15 { height: 15vh !important; min-height: 15vh !important; }
.height-vh-20 { height: 20vh !important; min-height: 20vh !important; }
.height-vh-25 { height: 25vh !important; min-height: 25vh !important; }
.height-vh-30 { height: 30vh !important; min-height: 30vh !important; }
.height-vh-35 { height: 35vh !important; min-height: 35vh !important; }
.height-vh-40 { height: 40vh !important; min-height: 40vh !important; }
.height-vh-45 { height: 45vh !important; min-height: 45vh !important; }
.height-vh-50 { height: 50vh !important; min-height: 50vh !important; }
.height-vh-55 { height: 55vh !important; min-height: 55vh !important; }
.height-vh-60 { height: 60vh !important; min-height: 60vh !important; }
.height-vh-65 { height: 65vh !important; min-height: 65vh !important; }
.height-vh-70 { height: 70vh !important; min-height: 70vh !important; }
.height-vh-75 { height: 75vh !important; min-height: 75vh !important; }
.height-vh-80 { height: 80vh !important; min-height: 80vh !important; }
.height-vh-85 { height: 85vh !important; min-height: 85vh !important; }
.height-vh-90 { height: 90vh !important; min-height: 90vh !important; }
.height-vh-95 { height: 95vh !important; min-height: 95vh !important; }

/* height classes */
.height-10 { height: 20px; }
.height-20 { height: 20px; }
.height-30 { height: 30px; }
.height-40 { height: 40px; }
.height-50 { height: 50px; }
.height-60 { height: 60px; }
.height-70 { height: 70px; }
.height-80 { height: 80px; }
.height-90 { height: 90px; }
.height-100 { height: 100px; }
.height-110 { height: 110px; }
.height-120 { height: 120px; }
.height-130 { height: 130px; }
.height-140 { height: 140px; }
.height-150 { height: 150px; }
.height-160 { height: 160px; }
.height-170 { height: 170px; }
.height-180 { height: 180px; }
.height-190 { height: 190px; }
.height-200 { height: 200px; }

/* minimum height classes */
.min-height-50 { min-height: 50px !important; }
.min-height-100 { min-height: 100px !important; }
.min-height-150 { min-height: 150px !important; }
.min-height-200 { min-height: 200px !important; }
.min-height-250 { min-height: 250px !important; }
.min-height-300 { min-height: 300px !important; }
.min-height-350 { min-height: 350px !important; }
.min-height-400 { min-height: 400px !important; }
.min-height-450 { min-height: 450px !important; }
.min-height-500 { min-height: 500px !important; }
.min-height-550 { min-height: 550px !important; }
.min-height-600 { min-height: 600px !important; }
.min-height-650 { min-height: 650px !important; }
.min-height-700 { min-height: 700px !important; }
.min-height-750 { min-height: 750px !important; }
.min-height-800 { min-height: 800px !important; }
.min-height-850 { min-height: 850px !important; }
.min-height-900 { min-height: 900px !important; }
.min-height-1000 { min-height: 1000px !important; }

/* maximum height classes */
.max-height-50 { max-height: 50px !important; }
.max-height-100 { max-height: 100px !important; }
.max-height-150 { max-height: 150px !important; }
.max-height-200 { max-height: 200px !important; }
.max-height-250 { max-height: 250px !important; }
.max-height-300 { max-height: 300px !important; }
.max-height-350 { max-height: 350px !important; }
.max-height-400 { max-height: 400px !important; }
.max-height-450 { max-height: 450px !important; }
.max-height-500 { max-height: 500px !important; }
.max-height-550 { max-height: 550px !important; }
.max-height-600 { max-height: 600px !important; }
.max-height-650 { max-height: 650px !important; }
.max-height-700 { max-height: 700px !important; }
.max-height-750 { max-height: 750px !important; }
.max-height-800 { max-height: 800px !important; }
.max-height-850 { max-height: 850px !important; }
.max-height-900 { max-height: 900px !important; }
.max-height-1000 { max-height: 1000px !important; }

/* padding height classes */
.padding-height-5 { padding-bottom: 5% !important; }
.padding-height-10 { padding-bottom: 10% !important; }
.padding-height-15 { padding-bottom: 15% !important; }
.padding-height-20 { padding-bottom: 20% !important; }
.padding-height-25 { padding-bottom: 25% !important; }
.padding-height-30 { padding-bottom: 30% !important; }
.padding-height-35 { padding-bottom: 35% !important; }
.padding-height-40 { padding-bottom: 40% !important; }
.padding-height-45 { padding-bottom: 45% !important; }
.padding-height-50 { padding-bottom: 50% !important; }
.padding-height-55 { padding-bottom: 55% !important; }
.padding-height-60 { padding-bottom: 60% !important; }
.padding-height-65 { padding-bottom: 65% !important; }
.padding-height-70 { padding-bottom: 70% !important; }
.padding-height-75 { padding-bottom: 75% !important; }
.padding-height-80 { padding-bottom: 80% !important; }
.padding-height-85 { padding-bottom: 85% !important; }
.padding-height-90 { padding-bottom: 90% !important; }
.padding-height-95 { padding-bottom: 95% !important; }
.padding-height-100 { padding-bottom: 100% !important; }
.padding-height-105 { padding-bottom: 105% !important; }
.padding-height-110 { padding-bottom: 110% !important; }
.padding-height-115 { padding-bottom: 115% !important; }
.padding-height-120 { padding-bottom: 120% !important; }
.padding-height-125 { padding-bottom: 125% !important; }
.padding-height-130 { padding-bottom: 130% !important; }
.padding-height-135 { padding-bottom: 135% !important; }
.padding-height-140 { padding-bottom: 140% !important; }
.padding-height-145 { padding-bottom: 145% !important; }
.padding-height-150 { padding-bottom: 150% !important; }


/* -------------------------------------------------------------------------- *
 * Center align classes (parent element must contain "position: relative;")
/* -------------------------------------------------------------------------- */

.align-center {
	position: absolute !important;
	top: 50% !important;
	bottom: auto !important;
	left: 50% !important;
	right: auto !important;
	transform: translate(-50%, -50%) !important;
}
.vertical-align-center {
	position: absolute !important;
	top: 50% !important;
	bottom: auto !important;
	transform: translateY(-50%) !important;
}
.horisontal-align-center {
	position: absolute !important;
	left: 50% !important;
	right: auto !important;
	transform: translateX(-50%) !important;
}


/* ------------------------------------------------------------- *
 * Text color classes
/* ------------------------------------------------------------- */

.text-main, 
.text-primary { 
	color: var(--tt-main-color) !important;  /* Template main color */
}
a.text-primary:focus, 
a.text-primary:hover { 
	color: var(--tt-main-color)!important; opacity: .8 !important; 
}

.text-secondary { color: #6c757d !important; }
.text-white { color: #FFF !important; }
.text-gray { color: #b9b9b9 !important; }
.text-gray-2 { color: #CCC !important; }
.text-gray-3 { color: #DDD !important; }
.text-dark { color: #272727 !important; }
.text-dark-2 { color: #444 !important; }
.text-dark-3 { color: #666 !important; }
.text-green { color: #0BC35B !important; }
.text-blue { color: #3042B3 !important; }
.text-yellow { color: #FFE800 !important; }
.text-red { color: #FD2B2B !important; }
.text-brown { color: #a56e00 !important; }
.text-purple { color: #ac0af3 !important; }
.text-pink { color: #F12765 !important; }

.text-muted { color: #888 !important; }


/* ------------------------------------------------------------- *
 * Link style classes
/* ------------------------------------------------------------- */

/* Link style 1 */
a.tt-link {
	background-repeat: no-repeat;
	background-image: -o-linear-gradient(transparent calc(100% - 1px),currentColor 1px);
	background-image: linear-gradient(transparent calc(100% - 1px),currentColor 1px);
	background-image: -ms-linear-gradient(transparent 100%,currentColor 1px);
	transition: 0.6s cubic-bezier(.215,.61,.355,1) !important;
	background-size: 0 100%;
	border: none;
	opacity: 1;
}
a.tt-link:hover,
a.tt-link:focus {
	background-size: 100% 100%;
	opacity: 1;
}

/* Link style 2 */
a.tt-link-2 { 
	color: currentColor;
	opacity: 1;
	border-bottom: 1px solid;
}
a.tt-link-2:hover,
a.tt-link-2:focus { 
	color: currentColor;
	opacity: .6;
}


/* ------------------------------------------------------------- *
 * Link color classes
/* ------------------------------------------------------------- */

a.link-main { color: var(--tt-main-color) !important;} /* Template main color */
a.link-white { color: #FFF !important; }
a.link-gray { color: #888 !important; }
a.link-gray-2 { color: #CCC !important; }
a.link-gray-3 { color: #DDD !important; }
a.link-dark { color: #272727 !important; }
a.link-dark-2 { color: #444 !important; }
a.link-dark-3 { color: #666 !important; }
a.link-green { color: #0BC35B !important; }
a.link-blue { color: #3042B3 !important; }
a.link-yellow { color: #FFE800 !important; }
a.link-red { color: #FD2B2B !important; }
a.link-brown { color: #a56e00 !important; }
a.link-purple { color: #ac0af3 !important; }
a.link-pink { color: #F12765 !important; }

a.link-main:hover,
a.link-white:hover,
a.link-gray:hover,
a.link-gray-2:hover,
a.link-gray-3:hover,
a.link-dark:hover,
a.link-dark-2:hover,
a.link-dark-3:hover,
a.link-green:hover,
a.link-blue:hover,
a.link-yellow:hover,
a.link-red:hover,
a.link-brown:hover,
a.link-purple:hover,
a.link-pink:hover { 
	opacity: .8 !important; 
}


/* ------------------------------------------------------------- *
 * Background color classes
/* ------------------------------------------------------------- */

.bg-main, .bg-primary { background-color: var(--tt-main-color) !important; } /* Template main color */
.bg-secondary { background-color: #6c757d !important; }

/* bg white */
.bg-white { background-color: #FFF !important; }

.bg-white-accent-1 { background-color: rgb(255 255 255 / 1%) !important; }
.bg-white-accent-2 { background-color: rgb(255 255 255 / 2%) !important; }
.bg-white-accent-3 { background-color: rgb(255 255 255 / 3%) !important; }
.bg-white-accent-4 { background-color: rgb(255 255 255 / 4%) !important; }
.bg-white-accent-5 { background-color: rgb(255 255 255 / 5%) !important; }
.bg-white-accent-6 { background-color: rgb(255 255 255 / 6%) !important; }
.bg-white-accent-7 { background-color: rgb(255 255 255 / 7%) !important; }
.bg-white-accent-8 { background-color: rgb(255 255 255 / 8%) !important; }
.bg-white-accent-9 { background-color: rgb(255 255 255 / 9%) !important; }
.bg-white-accent-10 { background-color: rgb(255 255 255 / 10%) !important; }

/* bg dark */
.bg-black { background-color: #000 !important; }
.bg-dark { background-color: #111 !important; }
.bg-dark-2 { background-color: #181818 !important; }
.bg-dark-3 { background-color: #282828 !important; }
.bg-dark-4 { background-color: #383838 !important; }

.bg-dark-accent-1 { background-color: rgb(0 0 0 / 1%) !important; }
.bg-dark-accent-2 { background-color: rgb(0 0 0 / 2%) !important; }
.bg-dark-accent-3 { background-color: rgb(0 0 0 / 3%) !important; }
.bg-dark-accent-4 { background-color: rgb(0 0 0 / 4%) !important; }
.bg-dark-accent-5 { background-color: rgb(0 0 0 / 5%) !important; }
.bg-dark-accent-6 { background-color: rgb(0 0 0 / 6%) !important; }
.bg-dark-accent-7 { background-color: rgb(0 0 0 / 7%) !important; }
.bg-dark-accent-8 { background-color: rgb(0 0 0 / 8%) !important; }
.bg-dark-accent-9 { background-color: rgb(0 0 0 / 9%) !important; }
.bg-dark-accent-10 { background-color: rgb(0 0 0 / 10%) !important; }

/* bg gray */
.bg-gray { background-color: #dadbdc !important; }
.bg-gray-2 { background-color: #eaeaea !important; }
.bg-gray-3 { background-color: #f3f3f3 !important; }

/* bg green */
.bg-green { background-color: #00ef68 !important; }
.bg-green-2 { background-color: #91f8be !important; }
.bg-green-3 { background-color: #e3f5eb !important; }

/* bg blue */
.bg-blue { background-color: #3042B3 !important; }
.bg-blue-2 { background-color: #becfe7 !important; }
.bg-blue-3 { background-color: #e4e6f5 !important; }

/* bg yellow */
.bg-yellow { background-color: #FFE800 !important; }
.bg-yellow-2 { background-color: #fff69e !important; }
.bg-yellow-3 { background-color: #f9f8e5 !important; }

/* bg red */
.bg-red { background-color: #FD2B2B !important; }
.bg-red-2 { background-color: #fd6666 !important; }
.bg-red-3 { background-color: #ffe0e0 !important; }

/* bg brown */
.bg-brown { background-color: #291d02 !important; }
.bg-brown-2 { background-color: #5d481a !important; }
.bg-brown-3 { background-color: #e4ba5c !important; }

/* bg purple */
.bg-purple { background-color: #a900f3 !important; }
.bg-purple-2 { background-color: #c359f2 !important; }
.bg-purple-3 { background-color: #f0d1fe !important; }

/* bg pink */
.bg-pink { background-color: #F12765 !important; }
.bg-pink-2 { background-color: #f6749c !important; }
.bg-pink-3 { background-color: #feebf1 !important; }


/* ------------------------------------------------------------- *
 * Rounded classes
/* ------------------------------------------------------------- */

.no-rounded, .no-radius { border-radius: 0 !important; }
.rounded { border-radius: 3px !important; }
.rounded-2x { border-radius: 6px !important; }
.rounded-3x { border-radius: 12px !important; }
.rounded-4x { border-radius: 20px !important; }
.rounded-5x { border-radius: 40px !important; }
.rounded-full { border-radius: 50% !important; }


/* ------------------------------------------------------------- *
 * Borders classes
/* ------------------------------------------------------------- */

.border { border: 1px solid rgb(132 132 132 / 33%) !important; }
.border-top { border-top: 1px solid rgb(132 132 132 / 33%) !important; }
.border-bottom { border-bottom: 1px solid rgb(132 132 132 / 33%) !important; }
.border-left { border-left: 1px solid rgb(132 132 132 / 33%) !important; }
.border-right { border-right: 1px solid rgb(132 132 132 / 33%) !important; }

/* no border classes */
.no-border { border: none !important; }
.no-border-top { border-top: none !important; }
.no-border-bottom { border-bottom: none !important; }
.no-border-left { border-left: none !important; }
.no-border-right { border-right: none !important; }

/* border width classes */
.border-width-1 { border-width: 1px !important; }
.border-width-2 { border-width: 2px !important; }
.border-width-3 { border-width: 3px !important; }
.border-width-4 { border-width: 4px !important; }
.border-width-5 { border-width: 5px !important; }
.border-width-6 { border-width: 6px !important; }
.border-width-7 { border-width: 7px !important; }
.border-width-8 { border-width: 8px !important; }
.border-width-9 { border-width: 9px !important; }
.border-width-10 { border-width: 10px !important; }

.border-top-width-1 { border-top-width: 1px !important; }
.border-top-width-2 { border-top-width: 2px !important; }
.border-top-width-3 { border-top-width: 3px !important; }
.border-top-width-4 { border-top-width: 4px !important; }
.border-top-width-5 { border-top-width: 5px !important; }
.border-top-width-6 { border-top-width: 6px !important; }
.border-top-width-7 { border-top-width: 7px !important; }
.border-top-width-8 { border-top-width: 8px !important; }
.border-top-width-9 { border-top-width: 9px !important; }
.border-top-width-10 { border-top-width: 10px !important; }

.border-bottom-width-1 { border-bottom-width: 1px !important; }
.border-bottom-width-2 { border-bottom-width: 2px !important; }
.border-bottom-width-3 { border-bottom-width: 3px !important; }
.border-bottom-width-4 { border-bottom-width: 4px !important; }
.border-bottom-width-5 { border-bottom-width: 5px !important; }
.border-bottom-width-6 { border-bottom-width: 6px !important; }
.border-bottom-width-7 { border-bottom-width: 7px !important; }
.border-bottom-width-8 { border-bottom-width: 8px !important; }
.border-bottom-width-9 { border-bottom-width: 9px !important; }
.border-bottom-width-10 { border-bottom-width: 10px !important; }

.border-left-width-1 { border-left-width: 1px !important; }
.border-left-width-2 { border-left-width: 2px !important; }
.border-left-width-3 { border-left-width: 3px !important; }
.border-left-width-4 { border-left-width: 4px !important; }
.border-left-width-5 { border-left-width: 5px !important; }
.border-left-width-6 { border-left-width: 6px !important; }
.border-left-width-7 { border-left-width: 7px !important; }
.border-left-width-8 { border-left-width: 8px !important; }
.border-left-width-9 { border-left-width: 9px !important; }
.border-left-width-10 { border-left-width: 10px !important; }

.border-right-width-1 { border-right-width: 1px !important; }
.border-right-width-2 { border-right-width: 2px !important; }
.border-right-width-3 { border-right-width: 3px !important; }
.border-right-width-4 { border-right-width: 4px !important; }
.border-right-width-5 { border-right-width: 5px !important; }
.border-right-width-6 { border-right-width: 6px !important; }
.border-right-width-7 { border-right-width: 7px !important; }
.border-right-width-8 { border-right-width: 8px !important; }
.border-right-width-9 { border-right-width: 9px !important; }
.border-right-width-10 { border-right-width: 10px !important; }

/* border style classes */
.border-solid { border-style: solid !important; }
.border-top-solid { border-top-style: solid !important; }
.border-bottom-solid { border-bottom-style: solid !important; }
.border-left-solid { border-left-style: solid !important; }
.border-right-solid { border-right-style: solid !important; }

.border-dashed { border-style: dashed !important; }
.border-top-dashed { border-top-style: dashed !important; }
.border-bottom-dashed { border-bottom-style: dashed !important; }
.border-left-dashed { border-left-style: dashed !important; }
.border-right-dashed { border-right-style: dashed !important; }

.border-dotted { border-style: dotted !important; }
.border-top-dotted { border-top-style: dotted !important; }
.border-bottom-dotted { border-bottom-style: dotted !important; }
.border-left-dotted { border-left-style: dotted !important; }
.border-right-dotted { border-right-style: dotted !important; }

.border-double { border-style: double !important; }
.border-top-double { border-top-style: double !important; }
.border-bottom-double { border-bottom-style: double !important; }
.border-left-double { border-left-style: double !important; }
.border-right-double { border-right-style: double !important; }

.border-groove { border-style: groove !important; }
.border-top-groove { border-top-style: groove !important; }
.border-bottom-groove { border-bottom-style: groove !important; }
.border-left-groove { border-left-style: groove !important; }
.border-right-groove { border-right-style: groove !important; }

.border-inset { border-style: inset !important; }
.border-top-inset { border-top-style: inset !important; }
.border-bottom-inset { border-bottom-style: inset !important; }
.border-left-inset { border-left-style: inset !important; }
.border-right-inset { border-right-style: inset !important; }

.border-outset { border-style: outset !important; }
.border-top-outset { border-top-style: outset !important; }
.border-bottom-outset { border-bottom-style: outset !important; }
.border-left-outset { border-left-style: outset !important; }
.border-right-outset { border-right-style: outset !important; }

/* border color classes */
.border-main { border-color: var(--tt-main-color) !important; } /* template main color */
.border-top-main { border-top-color: var(--tt-main-color) !important; } /* template main color */
.border-bottom-main { border-bottom-color: var(--tt-main-color) !important; } /* template main color */
.border-left-main { border-left-color: var(--tt-main-color) !important; } /* template main color */
.border-right-main { border-right-color: var(--tt-main-color) !important; } /* template main color */

.border-white { border-color: #FFF !important; }
.border-top-white { border-top-color: #FFF !important; }
.border-bottom-white { border-bottom-color: #FFF !important; }
.border-left-white { border-left-color: #FFF !important; }
.border-right-white { border-right-color: #FFF !important; }

.border-gray { border-color: #A4AAAD !important; }
.border-gray-2 { border-color: #D1D5D6 !important; }
.border-gray-3 { border-color: #EEE !important; }

.border-top-gray { border-top-color: #999 !important; }
.border-bottom-gray { border-bottom-color: #999 !important; }
.border-left-gray { border-left-color: #999 !important; }
.border-right-gray { border-right-color: #999 !important; }

.border-dark { border-color: #141414 !important; }
.border-dark-2 { border-color: #44484C !important; }
.border-dark-3 { border-color: #333 !important; }
.border-top-dark { border-top-color: #141414 !important; }
.border-top-dark-2 { border-top-color: #44484C !important; }
.border-top-dark-3 { border-top-color: #333 !important; }
.border-bottom-dark { border-bottom-color: #141414 !important; }
.border-bottom-dark-2 { border-bottom-color: #44484C !important; }
.border-bottom-dark-3 { border-bottom-color: #333 !important; }
.border-left-dark { border-left-color: #141414 !important; }
.border-left-dark-2 { border-left-color: #44484C !important; }
.border-left-dark-3 { border-left-color: #333 !important; }
.border-right-dark { border-right-color: #141414 !important; }
.border-right-dark-2 { border-right-color: #44484C !important; }
.border-right-dark-3 { border-right-color: #333 !important; }

.border-green { border-color: #5FC11B !important; }
.border-top-green { border-top-color: #5FC11B !important; }
.border-bottom-green { border-bottom-color: #5FC11B !important; }
.border-left-green { border-left-color: #5FC11B !important; }
.border-right-green { border-right-color: #5FC11B !important; }

.border-blue { border-color: #3067B3 !important; }
.border-top-blue { border-top-color: #3067B3 !important; }
.border-bottom-blue { border-bottom-color: #3067B3 !important; }
.border-left-blue { border-left-color: #3067B3 !important; }
.border-right-blue { border-right-color: #3067B3 !important; }

.border-yellow { border-color: #FFCD00 !important; }
.border-top-yellow { border-top-color: #FFCD00 !important; }
.border-bottom-yellow { border-bottom-color: #FFCD00 !important; }
.border-left-yellow { border-left-color: #FFCD00 !important; }
.border-right-yellow { border-right-color: #FFCD00 !important; }

.border-red { border-color: #FD2B2B !important; }
.border-top-red { border-top-color: #FD2B2B !important; }
.border-bottom-red { border-bottom-color: #FD2B2B !important; }
.border-left-red { border-left-color: #FD2B2B !important; }
.border-right-red { border-right-color: #FD2B2B !important; }

.border-brown { border-color: #A08650 !important; }
.border-top-brown { border-top-color: #A08650 !important; }
.border-bottom-brown { border-bottom-color: #A08650 !important; }
.border-left-brown { border-left-color: #A08650 !important; }
.border-right-brown { border-right-color: #A08650 !important; }

.border-purple { border-color: #ac0af3 !important; }
.border-top-purple { border-top-color: #ac0af3 !important; }
.border-bottom-purple { border-bottom-color: #ac0af3 !important; }
.border-left-purple { border-left-color: #ac0af3 !important; }
.border-right-purple { border-right-color: #ac0af3 !important; }

.border-pink { border-color: #E82D66 !important; }
.border-top-pink { border-top-color: #E82D66 !important; }
.border-bottom-pink { border-bottom-color: #E82D66 !important; }
.border-left-pink { border-left-color: #E82D66 !important; }
.border-right-pink { border-right-color: #E82D66 !important; }

/* border transparent classes */
.border-transparent { border-color: transparent !important; }
.border-top-transparent { border-top-color: transparent !important; }
.border-bottom-transparent { border-bottom-color: transparent !important; }
.border-left-transparent { border-left-color: transparent !important; }
.border-right-transparent { border-right-color: transparent !important; }


/* ------------------------------------------------------------- *
 * Paddings classes
/* ------------------------------------------------------------- */

.padding-5 { padding: 5px !important; }
.padding-10 { padding: 10px !important; }
.padding-15 { padding: 15px !important; }
.padding-20 { padding: 20px !important; }
.padding-25 { padding: 25px !important; }
.padding-30 { padding: 30px !important; }
.padding-35 { padding: 35px !important; }
.padding-40 { padding: 40px !important; }
.padding-45 { padding: 45px !important; }
.padding-50 { padding: 50px !important; }
.padding-55 { padding: 55px !important; }
.padding-60 { padding: 60px !important; }
.padding-65 { padding: 65px !important; }
.padding-70 { padding: 70px !important; }
.padding-75 { padding: 75px !important; }
.padding-80 { padding: 80px !important; }
.padding-85 { padding: 85px !important; }
.padding-100 { padding: 80px !important; }
.padding-105 { padding: 105px !important; }
.padding-110 { padding: 110px !important; }
.padding-115 { padding: 115px !important; }
.padding-120 { padding: 120px !important; }
.padding-125 { padding: 125px !important; }
.padding-130 { padding: 130px !important; }
.padding-135 { padding: 135px !important; }
.padding-140 { padding: 140px !important; }
.padding-145 { padding: 145px !important; }
.padding-150 { padding: 150px !important; }
.padding-155 { padding: 155px !important; }
.padding-160 { padding: 160px !important; }
.padding-165 { padding: 165px !important; }
.padding-170 { padding: 170px !important; }
.padding-175 { padding: 175px !important; }
.padding-180 { padding: 180px !important; }
.padding-185 { padding: 185px !important; }
.padding-190 { padding: 190px !important; }
.padding-195 { padding: 195px !important; }
.padding-200 { padding: 200px !important; }

/* padding top */
.padding-top-5 { padding-top: 5px !important; }
.padding-top-10 { padding-top: 10px !important; }
.padding-top-15 { padding-top: 15px !important; }
.padding-top-20 { padding-top: 20px !important; }
.padding-top-25 { padding-top: 25px !important; }
.padding-top-30 { padding-top: 30px !important; }
.padding-top-35 { padding-top: 35px !important; }
.padding-top-40 { padding-top: 40px !important; }
.padding-top-45 { padding-top: 45px !important; }
.padding-top-50 { padding-top: 50px !important; }
.padding-top-55 { padding-top: 55px !important; }
.padding-top-60 { padding-top: 60px !important; }
.padding-top-65 { padding-top: 65px !important; }
.padding-top-70 { padding-top: 70px !important; }
.padding-top-75 { padding-top: 75px !important; }
.padding-top-80 { padding-top: 80px !important; }
.padding-top-85 { padding-top: 85px !important; }
.padding-top-100 { padding-top: 100px !important; }
.padding-top-105 { padding-top: 105px !important; }
.padding-top-110 { padding-top: 110px !important; }
.padding-top-115 { padding-top: 115px !important; }
.padding-top-120 { padding-top: 120px !important; }
.padding-top-125 { padding-top: 125px !important; }
.padding-top-130 { padding-top: 130px !important; }
.padding-top-135 { padding-top: 135px !important; }
.padding-top-140 { padding-top: 140px !important; }
.padding-top-145 { padding-top: 145px !important; }
.padding-top-150 { padding-top: 150px !important; }
.padding-top-155 { padding-top: 155px !important; }
.padding-top-160 { padding-top: 160px !important; }
.padding-top-165 { padding-top: 165px !important; }
.padding-top-170 { padding-top: 170px !important; }
.padding-top-175 { padding-top: 175px !important; }
.padding-top-180 { padding-top: 180px !important; }
.padding-top-185 { padding-top: 185px !important; }
.padding-top-190 { padding-top: 190px !important; }
.padding-top-195 { padding-top: 195px !important; }
.padding-top-200 { padding-top: 200px !important; }

/* padding bottom */
.padding-bottom-5 { padding-bottom: 5px !important; }
.padding-bottom-10 { padding-bottom: 10px !important; }
.padding-bottom-15 { padding-bottom: 15px !important; }
.padding-bottom-20 { padding-bottom: 20px !important; }
.padding-bottom-25 { padding-bottom: 25px !important; }
.padding-bottom-30 { padding-bottom: 30px !important; }
.padding-bottom-35 { padding-bottom: 35px !important; }
.padding-bottom-40 { padding-bottom: 40px !important; }
.padding-bottom-45 { padding-bottom: 45px !important; }
.padding-bottom-50 { padding-bottom: 50px !important; }
.padding-bottom-55 { padding-bottom: 55px !important; }
.padding-bottom-60 { padding-bottom: 60px !important; }
.padding-bottom-65 { padding-bottom: 65px !important; }
.padding-bottom-70 { padding-bottom: 70px !important; }
.padding-bottom-75 { padding-bottom: 75px !important; }
.padding-bottom-80 { padding-bottom: 80px !important; }
.padding-bottom-85 { padding-bottom: 85px !important; }
.padding-bottom-100 { padding-bottom: 100px !important; }
.padding-bottom-105 { padding-bottom: 105px !important; }
.padding-bottom-110 { padding-bottom: 110px !important; }
.padding-bottom-115 { padding-bottom: 115px !important; }
.padding-bottom-120 { padding-bottom: 120px !important; }
.padding-bottom-125 { padding-bottom: 125px !important; }
.padding-bottom-130 { padding-bottom: 130px !important; }
.padding-bottom-135 { padding-bottom: 135px !important; }
.padding-bottom-140 { padding-bottom: 140px !important; }
.padding-bottom-145 { padding-bottom: 145px !important; }
.padding-bottom-150 { padding-bottom: 150px !important; }
.padding-bottom-155 { padding-bottom: 155px !important; }
.padding-bottom-160 { padding-bottom: 160px !important; }
.padding-bottom-165 { padding-bottom: 165px !important; }
.padding-bottom-170 { padding-bottom: 170px !important; }
.padding-bottom-175 { padding-bottom: 175px !important; }
.padding-bottom-180 { padding-bottom: 180px !important; }
.padding-bottom-185 { padding-bottom: 185px !important; }
.padding-bottom-190 { padding-bottom: 190px !important; }
.padding-bottom-195 { padding-bottom: 195px !important; }
.padding-bottom-200 { padding-bottom: 200px !important; }

/* padding left */
.padding-left-5 { padding-left: 5px !important; }
.padding-left-10 { padding-left: 10px !important; }
.padding-left-15 { padding-left: 15px !important; }
.padding-left-20 { padding-left: 20px !important; }
.padding-left-25 { padding-left: 25px !important; }
.padding-left-30 { padding-left: 30px !important; }
.padding-left-35 { padding-left: 35px !important; }
.padding-left-40 { padding-left: 40px !important; }
.padding-left-45 { padding-left: 45px !important; }
.padding-left-50 { padding-left: 50px !important; }
.padding-left-55 { padding-left: 55px !important; }
.padding-left-60 { padding-left: 60px !important; }
.padding-left-65 { padding-left: 65px !important; }
.padding-left-70 { padding-left: 70px !important; }
.padding-left-75 { padding-left: 75px !important; }
.padding-left-80 { padding-left: 80px !important; }
.padding-left-85 { padding-left: 85px !important; }
.padding-left-100 { padding-left: 100px !important; }
.padding-left-105 { padding-left: 105px !important; }
.padding-left-110 { padding-left: 110px !important; }
.padding-left-115 { padding-left: 115px !important; }
.padding-left-120 { padding-left: 120px !important; }
.padding-left-125 { padding-left: 125px !important; }
.padding-left-130 { padding-left: 130px !important; }
.padding-left-135 { padding-left: 135px !important; }
.padding-left-140 { padding-left: 140px !important; }
.padding-left-145 { padding-left: 145px !important; }
.padding-left-150 { padding-left: 150px !important; }
.padding-left-155 { padding-left: 155px !important; }
.padding-left-160 { padding-left: 160px !important; }
.padding-left-165 { padding-left: 165px !important; }
.padding-left-170 { padding-left: 170px !important; }
.padding-left-175 { padding-left: 175px !important; }
.padding-left-180 { padding-left: 180px !important; }
.padding-left-185 { padding-left: 185px !important; }
.padding-left-190 { padding-left: 190px !important; }
.padding-left-195 { padding-left: 195px !important; }
.padding-left-200 { padding-left: 200px !important; }

/* padding right */
.padding-right-5 { padding-right: 5px !important; }
.padding-right-10 { padding-right: 10px !important; }
.padding-right-15 { padding-right: 15px !important; }
.padding-right-20 { padding-right: 20px !important; }
.padding-right-25 { padding-right: 25px !important; }
.padding-right-30 { padding-right: 30px !important; }
.padding-right-35 { padding-right: 35px !important; }
.padding-right-40 { padding-right: 40px !important; }
.padding-right-45 { padding-right: 45px !important; }
.padding-right-50 { padding-right: 50px !important; }
.padding-right-55 { padding-right: 55px !important; }
.padding-right-60 { padding-right: 60px !important; }
.padding-right-65 { padding-right: 65px !important; }
.padding-right-70 { padding-right: 70px !important; }
.padding-right-75 { padding-right: 75px !important; }
.padding-right-80 { padding-right: 80px !important; }
.padding-right-85 { padding-right: 85px !important; }
.padding-right-100 { padding-right: 100px !important; }
.padding-right-105 { padding-right: 105px !important; }
.padding-right-110 { padding-right: 110px !important; }
.padding-right-115 { padding-right: 115px !important; }
.padding-right-120 { padding-right: 120px !important; }
.padding-right-125 { padding-right: 125px !important; }
.padding-right-130 { padding-right: 130px !important; }
.padding-right-135 { padding-right: 135px !important; }
.padding-right-140 { padding-right: 140px !important; }
.padding-right-145 { padding-right: 145px !important; }
.padding-right-150 { padding-right: 150px !important; }
.padding-right-155 { padding-right: 155px !important; }
.padding-right-160 { padding-right: 160px !important; }
.padding-right-165 { padding-right: 165px !important; }
.padding-right-170 { padding-right: 170px !important; }
.padding-right-175 { padding-right: 175px !important; }
.padding-right-180 { padding-right: 180px !important; }
.padding-right-185 { padding-right: 185px !important; }
.padding-right-190 { padding-right: 190px !important; }
.padding-right-195 { padding-right: 195px !important; }
.padding-right-200 { padding-right: 200px !important; }


/* Padding (percent) 
===================== */
.padding-1-p { padding: 1% !important; }
.padding-2-p { padding: 2% !important; }
.padding-3-p { padding: 3% !important; }
.padding-4-p { padding: 4% !important; }
.padding-5-p { padding: 5% !important; }
.padding-6-p { padding: 6% !important; }
.padding-7-p { padding: 7% !important; }
.padding-8-p { padding: 8% !important; }
.padding-9-p { padding: 9% !important; }
.padding-10-p { padding: 10% !important; }
.padding-11-p { padding: 11% !important; }
.padding-12-p { padding: 12% !important; }
.padding-13-p { padding: 13% !important; }
.padding-14-p { padding: 14% !important; }
.padding-15-p { padding: 15% !important; }
.padding-16-p { padding: 16% !important; }
.padding-17-p { padding: 17% !important; }
.padding-18-p { padding: 18% !important; }
.padding-19-p { padding: 19% !important; }
.padding-20-p { padding: 20% !important; }

/* padding top (percent) */
.padding-top-1-p { padding-top: 1% !important; }
.padding-top-2-p { padding-top: 2% !important; }
.padding-top-3-p { padding-top: 3% !important; }
.padding-top-4-p { padding-top: 4% !important; }
.padding-top-5-p { padding-top: 5% !important; }
.padding-top-6-p { padding-top: 6% !important; }
.padding-top-7-p { padding-top: 7% !important; }
.padding-top-8-p { padding-top: 8% !important; }
.padding-top-9-p { padding-top: 9% !important; }
.padding-top-10-p { padding-top: 10% !important; }
.padding-top-11-p { padding-top: 11% !important; }
.padding-top-12-p { padding-top: 12% !important; }
.padding-top-13-p { padding-top: 13% !important; }
.padding-top-14-p { padding-top: 14% !important; }
.padding-top-15-p { padding-top: 15% !important; }
.padding-top-16-p { padding-top: 16% !important; }
.padding-top-17-p { padding-top: 17% !important; }
.padding-top-18-p { padding-top: 18% !important; }
.padding-top-19-p { padding-top: 19% !important; }
.padding-top-20-p { padding-top: 20% !important; }

/* padding bottom (percent) */
.padding-bottom-1-p { padding-bottom: 1% !important; }
.padding-bottom-2-p { padding-bottom: 2% !important; }
.padding-bottom-3-p { padding-bottom: 3% !important; }
.padding-bottom-4-p { padding-bottom: 4% !important; }
.padding-bottom-5-p { padding-bottom: 5% !important; }
.padding-bottom-6-p { padding-bottom: 6% !important; }
.padding-bottom-7-p { padding-bottom: 7% !important; }
.padding-bottom-8-p { padding-bottom: 8% !important; }
.padding-bottom-9-p { padding-bottom: 9% !important; }
.padding-bottom-10-p { padding-bottom: 10% !important; }
.padding-bottom-11-p { padding-bottom: 11% !important; }
.padding-bottom-12-p { padding-bottom: 12% !important; }
.padding-bottom-13-p { padding-bottom: 13% !important; }
.padding-bottom-14-p { padding-bottom: 14% !important; }
.padding-bottom-15-p { padding-bottom: 15% !important; }
.padding-bottom-16-p { padding-bottom: 16% !important; }
.padding-bottom-17-p { padding-bottom: 17% !important; }
.padding-bottom-18-p { padding-bottom: 18% !important; }
.padding-bottom-19-p { padding-bottom: 19% !important; }
.padding-bottom-20-p { padding-bottom: 20% !important; }

/* padding left (percent) */
.padding-left-1-p { padding-left: 1% !important; }
.padding-left-2-p { padding-left: 2% !important; }
.padding-left-3-p { padding-left: 3% !important; }
.padding-left-4-p { padding-left: 4% !important; }
.padding-left-5-p { padding-left: 5% !important; }
.padding-left-6-p { padding-left: 6% !important; }
.padding-left-7-p { padding-left: 7% !important; }
.padding-left-8-p { padding-left: 8% !important; }
.padding-left-9-p { padding-left: 9% !important; }
.padding-left-10-p { padding-left: 10% !important; }
.padding-left-11-p { padding-left: 11% !important; }
.padding-left-12-p { padding-left: 12% !important; }
.padding-left-13-p { padding-left: 13% !important; }
.padding-left-14-p { padding-left: 14% !important; }
.padding-left-15-p { padding-left: 15% !important; }
.padding-left-16-p { padding-left: 16% !important; }
.padding-left-17-p { padding-left: 17% !important; }
.padding-left-18-p { padding-left: 18% !important; }
.padding-left-19-p { padding-left: 19% !important; }
.padding-left-20-p { padding-left: 20% !important; }

/* padding right (percent) */
.padding-right-1-p { padding-right: 1% !important; }
.padding-right-2-p { padding-right: 2% !important; }
.padding-right-3-p { padding-right: 3% !important; }
.padding-right-4-p { padding-right: 4% !important; }
.padding-right-5-p { padding-right: 5% !important; }
.padding-right-6-p { padding-right: 6% !important; }
.padding-right-7-p { padding-right: 7% !important; }
.padding-right-8-p { padding-right: 8% !important; }
.padding-right-9-p { padding-right: 9% !important; }
.padding-right-10-p { padding-right: 10% !important; }
.padding-right-11-p { padding-right: 11% !important; }
.padding-right-12-p { padding-right: 12% !important; }
.padding-right-13-p { padding-right: 13% !important; }
.padding-right-14-p { padding-right: 14% !important; }
.padding-right-15-p { padding-right: 15% !important; }
.padding-right-16-p { padding-right: 16% !important; }
.padding-right-17-p { padding-right: 17% !important; }
.padding-right-18-p { padding-right: 18% !important; }
.padding-right-19-p { padding-right: 19% !important; }
.padding-right-20-p { padding-right: 20% !important; }

/* no paddings classes */
.no-padding { padding: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }


/* Breakpoints 
=============== */
@media (min-width: 576px) {
	.padding-sm-5 { padding: 5px !important; }
	.padding-sm-10 { padding: 10px !important; }
	.padding-sm-15 { padding: 15px !important; }
	.padding-sm-20 { padding: 20px !important; }
	.padding-sm-25 { padding: 25px !important; }
	.padding-sm-30 { padding: 30px !important; }
	.padding-sm-35 { padding: 35px !important; }
	.padding-sm-40 { padding: 40px !important; }
	.padding-sm-45 { padding: 45px !important; }
	.padding-sm-50 { padding: 50px !important; }
	.padding-sm-55 { padding: 55px !important; }
	.padding-sm-60 { padding: 60px !important; }
	.padding-sm-65 { padding: 65px !important; }
	.padding-sm-70 { padding: 70px !important; }
	.padding-sm-75 { padding: 75px !important; }
	.padding-sm-80 { padding: 80px !important; }
	.padding-sm-85 { padding: 85px !important; }
	.padding-sm-100 { padding: 80px !important; }
	.padding-sm-105 { padding: 105px !important; }
	.padding-sm-110 { padding: 110px !important; }
	.padding-sm-115 { padding: 115px !important; }
	.padding-sm-120 { padding: 120px !important; }
	.padding-sm-125 { padding: 125px !important; }
	.padding-sm-130 { padding: 130px !important; }
	.padding-sm-135 { padding: 135px !important; }
	.padding-sm-140 { padding: 140px !important; }
	.padding-sm-145 { padding: 145px !important; }
	.padding-sm-150 { padding: 150px !important; }
	.padding-sm-155 { padding: 155px !important; }
	.padding-sm-160 { padding: 160px !important; }
	.padding-sm-165 { padding: 165px !important; }
	.padding-sm-170 { padding: 170px !important; }
	.padding-sm-175 { padding: 175px !important; }
	.padding-sm-180 { padding: 180px !important; }
	.padding-sm-185 { padding: 185px !important; }
	.padding-sm-190 { padding: 190px !important; }
	.padding-sm-195 { padding: 195px !important; }
	.padding-sm-200 { padding: 200px !important; }

	/* padding top */
	.padding-top-sm-5 { padding-top: 5px !important; }
	.padding-top-sm-10 { padding-top: 10px !important; }
	.padding-top-sm-15 { padding-top: 15px !important; }
	.padding-top-sm-20 { padding-top: 20px !important; }
	.padding-top-sm-25 { padding-top: 25px !important; }
	.padding-top-sm-30 { padding-top: 30px !important; }
	.padding-top-sm-35 { padding-top: 35px !important; }
	.padding-top-sm-40 { padding-top: 40px !important; }
	.padding-top-sm-45 { padding-top: 45px !important; }
	.padding-top-sm-50 { padding-top: 50px !important; }
	.padding-top-sm-55 { padding-top: 55px !important; }
	.padding-top-sm-60 { padding-top: 60px !important; }
	.padding-top-sm-65 { padding-top: 65px !important; }
	.padding-top-sm-70 { padding-top: 70px !important; }
	.padding-top-sm-75 { padding-top: 75px !important; }
	.padding-top-sm-80 { padding-top: 80px !important; }
	.padding-top-sm-85 { padding-top: 85px !important; }
	.padding-top-sm-100 { padding-top: 100px !important; }
	.padding-top-sm-105 { padding-top: 105px !important; }
	.padding-top-sm-110 { padding-top: 110px !important; }
	.padding-top-sm-115 { padding-top: 115px !important; }
	.padding-top-sm-120 { padding-top: 120px !important; }
	.padding-top-sm-125 { padding-top: 125px !important; }
	.padding-top-sm-130 { padding-top: 130px !important; }
	.padding-top-sm-135 { padding-top: 135px !important; }
	.padding-top-sm-140 { padding-top: 140px !important; }
	.padding-top-sm-145 { padding-top: 145px !important; }
	.padding-top-sm-150 { padding-top: 150px !important; }
	.padding-top-sm-155 { padding-top: 155px !important; }
	.padding-top-sm-160 { padding-top: 160px !important; }
	.padding-top-sm-165 { padding-top: 165px !important; }
	.padding-top-sm-170 { padding-top: 170px !important; }
	.padding-top-sm-175 { padding-top: 175px !important; }
	.padding-top-sm-180 { padding-top: 180px !important; }
	.padding-top-sm-185 { padding-top: 185px !important; }
	.padding-top-sm-190 { padding-top: 190px !important; }
	.padding-top-sm-195 { padding-top: 195px !important; }
	.padding-top-sm-200 { padding-top: 200px !important; }

	/* padding bottom */
	.padding-bottom-sm-5 { padding-bottom: 5px !important; }
	.padding-bottom-sm-10 { padding-bottom: 10px !important; }
	.padding-bottom-sm-15 { padding-bottom: 15px !important; }
	.padding-bottom-sm-20 { padding-bottom: 20px !important; }
	.padding-bottom-sm-25 { padding-bottom: 25px !important; }
	.padding-bottom-sm-30 { padding-bottom: 30px !important; }
	.padding-bottom-sm-35 { padding-bottom: 35px !important; }
	.padding-bottom-sm-40 { padding-bottom: 40px !important; }
	.padding-bottom-sm-45 { padding-bottom: 45px !important; }
	.padding-bottom-sm-50 { padding-bottom: 50px !important; }
	.padding-bottom-sm-55 { padding-bottom: 55px !important; }
	.padding-bottom-sm-60 { padding-bottom: 60px !important; }
	.padding-bottom-sm-65 { padding-bottom: 65px !important; }
	.padding-bottom-sm-70 { padding-bottom: 70px !important; }
	.padding-bottom-sm-75 { padding-bottom: 75px !important; }
	.padding-bottom-sm-80 { padding-bottom: 80px !important; }
	.padding-bottom-sm-85 { padding-bottom: 85px !important; }
	.padding-bottom-sm-100 { padding-bottom: 100px !important; }
	.padding-bottom-sm-105 { padding-bottom: 105px !important; }
	.padding-bottom-sm-110 { padding-bottom: 110px !important; }
	.padding-bottom-sm-115 { padding-bottom: 115px !important; }
	.padding-bottom-sm-120 { padding-bottom: 120px !important; }
	.padding-bottom-sm-125 { padding-bottom: 125px !important; }
	.padding-bottom-sm-130 { padding-bottom: 130px !important; }
	.padding-bottom-sm-135 { padding-bottom: 135px !important; }
	.padding-bottom-sm-140 { padding-bottom: 140px !important; }
	.padding-bottom-sm-145 { padding-bottom: 145px !important; }
	.padding-bottom-sm-150 { padding-bottom: 150px !important; }
	.padding-bottom-sm-155 { padding-bottom: 155px !important; }
	.padding-bottom-sm-160 { padding-bottom: 160px !important; }
	.padding-bottom-sm-165 { padding-bottom: 165px !important; }
	.padding-bottom-sm-170 { padding-bottom: 170px !important; }
	.padding-bottom-sm-175 { padding-bottom: 175px !important; }
	.padding-bottom-sm-180 { padding-bottom: 180px !important; }
	.padding-bottom-sm-185 { padding-bottom: 185px !important; }
	.padding-bottom-sm-190 { padding-bottom: 190px !important; }
	.padding-bottom-sm-195 { padding-bottom: 195px !important; }
	.padding-bottom-sm-200 { padding-bottom: 200px !important; }

	/* padding left */
	.padding-left-sm-5 { padding-left: 5px !important; }
	.padding-left-sm-10 { padding-left: 10px !important; }
	.padding-left-sm-15 { padding-left: 15px !important; }
	.padding-left-sm-20 { padding-left: 20px !important; }
	.padding-left-sm-25 { padding-left: 25px !important; }
	.padding-left-sm-30 { padding-left: 30px !important; }
	.padding-left-sm-35 { padding-left: 35px !important; }
	.padding-left-sm-40 { padding-left: 40px !important; }
	.padding-left-sm-45 { padding-left: 45px !important; }
	.padding-left-sm-50 { padding-left: 50px !important; }
	.padding-left-sm-55 { padding-left: 55px !important; }
	.padding-left-sm-60 { padding-left: 60px !important; }
	.padding-left-sm-65 { padding-left: 65px !important; }
	.padding-left-sm-70 { padding-left: 70px !important; }
	.padding-left-sm-75 { padding-left: 75px !important; }
	.padding-left-sm-80 { padding-left: 80px !important; }
	.padding-left-sm-85 { padding-left: 85px !important; }
	.padding-left-sm-100 { padding-left: 100px !important; }
	.padding-left-sm-105 { padding-left: 105px !important; }
	.padding-left-sm-110 { padding-left: 110px !important; }
	.padding-left-sm-115 { padding-left: 115px !important; }
	.padding-left-sm-120 { padding-left: 120px !important; }
	.padding-left-sm-125 { padding-left: 125px !important; }
	.padding-left-sm-130 { padding-left: 130px !important; }
	.padding-left-sm-135 { padding-left: 135px !important; }
	.padding-left-sm-140 { padding-left: 140px !important; }
	.padding-left-sm-145 { padding-left: 145px !important; }
	.padding-left-sm-150 { padding-left: 150px !important; }
	.padding-left-sm-155 { padding-left: 155px !important; }
	.padding-left-sm-160 { padding-left: 160px !important; }
	.padding-left-sm-165 { padding-left: 165px !important; }
	.padding-left-sm-170 { padding-left: 170px !important; }
	.padding-left-sm-175 { padding-left: 175px !important; }
	.padding-left-sm-180 { padding-left: 180px !important; }
	.padding-left-sm-185 { padding-left: 185px !important; }
	.padding-left-sm-190 { padding-left: 190px !important; }
	.padding-left-sm-195 { padding-left: 195px !important; }
	.padding-left-sm-200 { padding-left: 200px !important; }

	/* padding right */
	.padding-right-sm-5 { padding-right: 5px !important; }
	.padding-right-sm-10 { padding-right: 10px !important; }
	.padding-right-sm-15 { padding-right: 15px !important; }
	.padding-right-sm-20 { padding-right: 20px !important; }
	.padding-right-sm-25 { padding-right: 25px !important; }
	.padding-right-sm-30 { padding-right: 30px !important; }
	.padding-right-sm-35 { padding-right: 35px !important; }
	.padding-right-sm-40 { padding-right: 40px !important; }
	.padding-right-sm-45 { padding-right: 45px !important; }
	.padding-right-sm-50 { padding-right: 50px !important; }
	.padding-right-sm-55 { padding-right: 55px !important; }
	.padding-right-sm-60 { padding-right: 60px !important; }
	.padding-right-sm-65 { padding-right: 65px !important; }
	.padding-right-sm-70 { padding-right: 70px !important; }
	.padding-right-sm-75 { padding-right: 75px !important; }
	.padding-right-sm-80 { padding-right: 80px !important; }
	.padding-right-sm-85 { padding-right: 85px !important; }
	.padding-right-sm-100 { padding-right: 100px !important; }
	.padding-right-sm-105 { padding-right: 105px !important; }
	.padding-right-sm-110 { padding-right: 110px !important; }
	.padding-right-sm-115 { padding-right: 115px !important; }
	.padding-right-sm-120 { padding-right: 120px !important; }
	.padding-right-sm-125 { padding-right: 125px !important; }
	.padding-right-sm-130 { padding-right: 130px !important; }
	.padding-right-sm-135 { padding-right: 135px !important; }
	.padding-right-sm-140 { padding-right: 140px !important; }
	.padding-right-sm-145 { padding-right: 145px !important; }
	.padding-right-sm-150 { padding-right: 150px !important; }
	.padding-right-sm-155 { padding-right: 155px !important; }
	.padding-right-sm-160 { padding-right: 160px !important; }
	.padding-right-sm-165 { padding-right: 165px !important; }
	.padding-right-sm-170 { padding-right: 170px !important; }
	.padding-right-sm-175 { padding-right: 175px !important; }
	.padding-right-sm-180 { padding-right: 180px !important; }
	.padding-right-sm-185 { padding-right: 185px !important; }
	.padding-right-sm-190 { padding-right: 190px !important; }
	.padding-right-sm-195 { padding-right: 195px !important; }
	.padding-right-sm-200 { padding-right: 200px !important; }


	/* Padding (percent) 
	===================== */
	.padding-sm-1-p { padding: 1% !important; }
	.padding-sm-2-p { padding: 2% !important; }
	.padding-sm-3-p { padding: 3% !important; }
	.padding-sm-4-p { padding: 4% !important; }
	.padding-sm-5-p { padding: 5% !important; }
	.padding-sm-6-p { padding: 6% !important; }
	.padding-sm-7-p { padding: 7% !important; }
	.padding-sm-8-p { padding: 8% !important; }
	.padding-sm-9-p { padding: 9% !important; }
	.padding-sm-10-p { padding: 10% !important; }
	.padding-sm-11-p { padding: 11% !important; }
	.padding-sm-12-p { padding: 12% !important; }
	.padding-sm-13-p { padding: 13% !important; }
	.padding-sm-14-p { padding: 14% !important; }
	.padding-sm-15-p { padding: 15% !important; }
	.padding-sm-16-p { padding: 16% !important; }
	.padding-sm-17-p { padding: 17% !important; }
	.padding-sm-18-p { padding: 18% !important; }
	.padding-sm-19-p { padding: 19% !important; }
	.padding-sm-20-p { padding: 20% !important; }

	/* padding top (percent) */
	.padding-top-sm-1-p { padding-top: 1% !important; }
	.padding-top-sm-2-p { padding-top: 2% !important; }
	.padding-top-sm-3-p { padding-top: 3% !important; }
	.padding-top-sm-4-p { padding-top: 4% !important; }
	.padding-top-sm-5-p { padding-top: 5% !important; }
	.padding-top-sm-6-p { padding-top: 6% !important; }
	.padding-top-sm-7-p { padding-top: 7% !important; }
	.padding-top-sm-8-p { padding-top: 8% !important; }
	.padding-top-sm-9-p { padding-top: 9% !important; }
	.padding-top-sm-10-p { padding-top: 10% !important; }
	.padding-top-sm-11-p { padding-top: 11% !important; }
	.padding-top-sm-12-p { padding-top: 12% !important; }
	.padding-top-sm-13-p { padding-top: 13% !important; }
	.padding-top-sm-14-p { padding-top: 14% !important; }
	.padding-top-sm-15-p { padding-top: 15% !important; }
	.padding-top-sm-16-p { padding-top: 16% !important; }
	.padding-top-sm-17-p { padding-top: 17% !important; }
	.padding-top-sm-18-p { padding-top: 18% !important; }
	.padding-top-sm-19-p { padding-top: 19% !important; }
	.padding-top-sm-20-p { padding-top: 20% !important; }

	/* padding bottom (percent) */
	.padding-bottom-sm-1-p { padding-bottom: 1% !important; }
	.padding-bottom-sm-2-p { padding-bottom: 2% !important; }
	.padding-bottom-sm-3-p { padding-bottom: 3% !important; }
	.padding-bottom-sm-4-p { padding-bottom: 4% !important; }
	.padding-bottom-sm-5-p { padding-bottom: 5% !important; }
	.padding-bottom-sm-6-p { padding-bottom: 6% !important; }
	.padding-bottom-sm-7-p { padding-bottom: 7% !important; }
	.padding-bottom-sm-8-p { padding-bottom: 8% !important; }
	.padding-bottom-sm-9-p { padding-bottom: 9% !important; }
	.padding-bottom-sm-10-p { padding-bottom: 10% !important; }
	.padding-bottom-sm-11-p { padding-bottom: 11% !important; }
	.padding-bottom-sm-12-p { padding-bottom: 12% !important; }
	.padding-bottom-sm-13-p { padding-bottom: 13% !important; }
	.padding-bottom-sm-14-p { padding-bottom: 14% !important; }
	.padding-bottom-sm-15-p { padding-bottom: 15% !important; }
	.padding-bottom-sm-16-p { padding-bottom: 16% !important; }
	.padding-bottom-sm-17-p { padding-bottom: 17% !important; }
	.padding-bottom-sm-18-p { padding-bottom: 18% !important; }
	.padding-bottom-sm-19-p { padding-bottom: 19% !important; }
	.padding-bottom-sm-20-p { padding-bottom: 20% !important; }

	/* padding left (percent) */
	.padding-left-sm-1-p { padding-left: 1% !important; }
	.padding-left-sm-2-p { padding-left: 2% !important; }
	.padding-left-sm-3-p { padding-left: 3% !important; }
	.padding-left-sm-4-p { padding-left: 4% !important; }
	.padding-left-sm-5-p { padding-left: 5% !important; }
	.padding-left-sm-6-p { padding-left: 6% !important; }
	.padding-left-sm-7-p { padding-left: 7% !important; }
	.padding-left-sm-8-p { padding-left: 8% !important; }
	.padding-left-sm-9-p { padding-left: 9% !important; }
	.padding-left-sm-10-p { padding-left: 10% !important; }
	.padding-left-sm-11-p { padding-left: 11% !important; }
	.padding-left-sm-12-p { padding-left: 12% !important; }
	.padding-left-sm-13-p { padding-left: 13% !important; }
	.padding-left-sm-14-p { padding-left: 14% !important; }
	.padding-left-sm-15-p { padding-left: 15% !important; }
	.padding-left-sm-16-p { padding-left: 16% !important; }
	.padding-left-sm-17-p { padding-left: 17% !important; }
	.padding-left-sm-18-p { padding-left: 18% !important; }
	.padding-left-sm-19-p { padding-left: 19% !important; }
	.padding-left-sm-20-p { padding-left: 20% !important; }

	/* padding right (percent) */
	.padding-right-sm-1-p { padding-right: 1% !important; }
	.padding-right-sm-2-p { padding-right: 2% !important; }
	.padding-right-sm-3-p { padding-right: 3% !important; }
	.padding-right-sm-4-p { padding-right: 4% !important; }
	.padding-right-sm-5-p { padding-right: 5% !important; }
	.padding-right-sm-6-p { padding-right: 6% !important; }
	.padding-right-sm-7-p { padding-right: 7% !important; }
	.padding-right-sm-8-p { padding-right: 8% !important; }
	.padding-right-sm-9-p { padding-right: 9% !important; }
	.padding-right-sm-10-p { padding-right: 10% !important; }
	.padding-right-sm-11-p { padding-right: 11% !important; }
	.padding-right-sm-12-p { padding-right: 12% !important; }
	.padding-right-sm-13-p { padding-right: 13% !important; }
	.padding-right-sm-14-p { padding-right: 14% !important; }
	.padding-right-sm-15-p { padding-right: 15% !important; }
	.padding-right-sm-16-p { padding-right: 16% !important; }
	.padding-right-sm-17-p { padding-right: 17% !important; }
	.padding-right-sm-18-p { padding-right: 18% !important; }
	.padding-right-sm-19-p { padding-right: 19% !important; }
	.padding-right-sm-20-p { padding-right: 20% !important; }

	/* no paddings classes */
	.no-padding-sm { padding: 0 !important; }
	.no-padding-sm-top { padding-top: 0 !important; }
	.no-padding-sm-bottom { padding-bottom: 0 !important; }
	.no-padding-sm-left { padding-left: 0 !important; }
	.no-padding-sm-right { padding-right: 0 !important; }
}
@media (min-width: 768px) {
	.padding-md-5 { padding: 5px !important; }
	.padding-md-10 { padding: 10px !important; }
	.padding-md-15 { padding: 15px !important; }
	.padding-md-20 { padding: 20px !important; }
	.padding-md-25 { padding: 25px !important; }
	.padding-md-30 { padding: 30px !important; }
	.padding-md-35 { padding: 35px !important; }
	.padding-md-40 { padding: 40px !important; }
	.padding-md-45 { padding: 45px !important; }
	.padding-md-50 { padding: 50px !important; }
	.padding-md-55 { padding: 55px !important; }
	.padding-md-60 { padding: 60px !important; }
	.padding-md-65 { padding: 65px !important; }
	.padding-md-70 { padding: 70px !important; }
	.padding-md-75 { padding: 75px !important; }
	.padding-md-80 { padding: 80px !important; }
	.padding-md-85 { padding: 85px !important; }
	.padding-md-100 { padding: 80px !important; }
	.padding-md-105 { padding: 105px !important; }
	.padding-md-110 { padding: 110px !important; }
	.padding-md-115 { padding: 115px !important; }
	.padding-md-120 { padding: 120px !important; }
	.padding-md-125 { padding: 125px !important; }
	.padding-md-130 { padding: 130px !important; }
	.padding-md-135 { padding: 135px !important; }
	.padding-md-140 { padding: 140px !important; }
	.padding-md-145 { padding: 145px !important; }
	.padding-md-150 { padding: 150px !important; }
	.padding-md-155 { padding: 155px !important; }
	.padding-md-160 { padding: 160px !important; }
	.padding-md-165 { padding: 165px !important; }
	.padding-md-170 { padding: 170px !important; }
	.padding-md-175 { padding: 175px !important; }
	.padding-md-180 { padding: 180px !important; }
	.padding-md-185 { padding: 185px !important; }
	.padding-md-190 { padding: 190px !important; }
	.padding-md-195 { padding: 195px !important; }
	.padding-md-200 { padding: 200px !important; }

	/* padding top */
	.padding-top-md-5 { padding-top: 5px !important; }
	.padding-top-md-10 { padding-top: 10px !important; }
	.padding-top-md-15 { padding-top: 15px !important; }
	.padding-top-md-20 { padding-top: 20px !important; }
	.padding-top-md-25 { padding-top: 25px !important; }
	.padding-top-md-30 { padding-top: 30px !important; }
	.padding-top-md-35 { padding-top: 35px !important; }
	.padding-top-md-40 { padding-top: 40px !important; }
	.padding-top-md-45 { padding-top: 45px !important; }
	.padding-top-md-50 { padding-top: 50px !important; }
	.padding-top-md-55 { padding-top: 55px !important; }
	.padding-top-md-60 { padding-top: 60px !important; }
	.padding-top-md-65 { padding-top: 65px !important; }
	.padding-top-md-70 { padding-top: 70px !important; }
	.padding-top-md-75 { padding-top: 75px !important; }
	.padding-top-md-80 { padding-top: 80px !important; }
	.padding-top-md-85 { padding-top: 85px !important; }
	.padding-top-md-100 { padding-top: 100px !important; }
	.padding-top-md-105 { padding-top: 105px !important; }
	.padding-top-md-110 { padding-top: 110px !important; }
	.padding-top-md-115 { padding-top: 115px !important; }
	.padding-top-md-120 { padding-top: 120px !important; }
	.padding-top-md-125 { padding-top: 125px !important; }
	.padding-top-md-130 { padding-top: 130px !important; }
	.padding-top-md-135 { padding-top: 135px !important; }
	.padding-top-md-140 { padding-top: 140px !important; }
	.padding-top-md-145 { padding-top: 145px !important; }
	.padding-top-md-150 { padding-top: 150px !important; }
	.padding-top-md-155 { padding-top: 155px !important; }
	.padding-top-md-160 { padding-top: 160px !important; }
	.padding-top-md-165 { padding-top: 165px !important; }
	.padding-top-md-170 { padding-top: 170px !important; }
	.padding-top-md-175 { padding-top: 175px !important; }
	.padding-top-md-180 { padding-top: 180px !important; }
	.padding-top-md-185 { padding-top: 185px !important; }
	.padding-top-md-190 { padding-top: 190px !important; }
	.padding-top-md-195 { padding-top: 195px !important; }
	.padding-top-md-200 { padding-top: 200px !important; }

	/* padding bottom */
	.padding-bottom-md-5 { padding-bottom: 5px !important; }
	.padding-bottom-md-10 { padding-bottom: 10px !important; }
	.padding-bottom-md-15 { padding-bottom: 15px !important; }
	.padding-bottom-md-20 { padding-bottom: 20px !important; }
	.padding-bottom-md-25 { padding-bottom: 25px !important; }
	.padding-bottom-md-30 { padding-bottom: 30px !important; }
	.padding-bottom-md-35 { padding-bottom: 35px !important; }
	.padding-bottom-md-40 { padding-bottom: 40px !important; }
	.padding-bottom-md-45 { padding-bottom: 45px !important; }
	.padding-bottom-md-50 { padding-bottom: 50px !important; }
	.padding-bottom-md-55 { padding-bottom: 55px !important; }
	.padding-bottom-md-60 { padding-bottom: 60px !important; }
	.padding-bottom-md-65 { padding-bottom: 65px !important; }
	.padding-bottom-md-70 { padding-bottom: 70px !important; }
	.padding-bottom-md-75 { padding-bottom: 75px !important; }
	.padding-bottom-md-80 { padding-bottom: 80px !important; }
	.padding-bottom-md-85 { padding-bottom: 85px !important; }
	.padding-bottom-md-100 { padding-bottom: 100px !important; }
	.padding-bottom-md-105 { padding-bottom: 105px !important; }
	.padding-bottom-md-110 { padding-bottom: 110px !important; }
	.padding-bottom-md-115 { padding-bottom: 115px !important; }
	.padding-bottom-md-120 { padding-bottom: 120px !important; }
	.padding-bottom-md-125 { padding-bottom: 125px !important; }
	.padding-bottom-md-130 { padding-bottom: 130px !important; }
	.padding-bottom-md-135 { padding-bottom: 135px !important; }
	.padding-bottom-md-140 { padding-bottom: 140px !important; }
	.padding-bottom-md-145 { padding-bottom: 145px !important; }
	.padding-bottom-md-150 { padding-bottom: 150px !important; }
	.padding-bottom-md-155 { padding-bottom: 155px !important; }
	.padding-bottom-md-160 { padding-bottom: 160px !important; }
	.padding-bottom-md-165 { padding-bottom: 165px !important; }
	.padding-bottom-md-170 { padding-bottom: 170px !important; }
	.padding-bottom-md-175 { padding-bottom: 175px !important; }
	.padding-bottom-md-180 { padding-bottom: 180px !important; }
	.padding-bottom-md-185 { padding-bottom: 185px !important; }
	.padding-bottom-md-190 { padding-bottom: 190px !important; }
	.padding-bottom-md-195 { padding-bottom: 195px !important; }
	.padding-bottom-md-200 { padding-bottom: 200px !important; }

	/* padding left */
	.padding-left-md-5 { padding-left: 5px !important; }
	.padding-left-md-10 { padding-left: 10px !important; }
	.padding-left-md-15 { padding-left: 15px !important; }
	.padding-left-md-20 { padding-left: 20px !important; }
	.padding-left-md-25 { padding-left: 25px !important; }
	.padding-left-md-30 { padding-left: 30px !important; }
	.padding-left-md-35 { padding-left: 35px !important; }
	.padding-left-md-40 { padding-left: 40px !important; }
	.padding-left-md-45 { padding-left: 45px !important; }
	.padding-left-md-50 { padding-left: 50px !important; }
	.padding-left-md-55 { padding-left: 55px !important; }
	.padding-left-md-60 { padding-left: 60px !important; }
	.padding-left-md-65 { padding-left: 65px !important; }
	.padding-left-md-70 { padding-left: 70px !important; }
	.padding-left-md-75 { padding-left: 75px !important; }
	.padding-left-md-80 { padding-left: 80px !important; }
	.padding-left-md-85 { padding-left: 85px !important; }
	.padding-left-md-100 { padding-left: 100px !important; }
	.padding-left-md-105 { padding-left: 105px !important; }
	.padding-left-md-110 { padding-left: 110px !important; }
	.padding-left-md-115 { padding-left: 115px !important; }
	.padding-left-md-120 { padding-left: 120px !important; }
	.padding-left-md-125 { padding-left: 125px !important; }
	.padding-left-md-130 { padding-left: 130px !important; }
	.padding-left-md-135 { padding-left: 135px !important; }
	.padding-left-md-140 { padding-left: 140px !important; }
	.padding-left-md-145 { padding-left: 145px !important; }
	.padding-left-md-150 { padding-left: 150px !important; }
	.padding-left-md-155 { padding-left: 155px !important; }
	.padding-left-md-160 { padding-left: 160px !important; }
	.padding-left-md-165 { padding-left: 165px !important; }
	.padding-left-md-170 { padding-left: 170px !important; }
	.padding-left-md-175 { padding-left: 175px !important; }
	.padding-left-md-180 { padding-left: 180px !important; }
	.padding-left-md-185 { padding-left: 185px !important; }
	.padding-left-md-190 { padding-left: 190px !important; }
	.padding-left-md-195 { padding-left: 195px !important; }
	.padding-left-md-200 { padding-left: 200px !important; }

	/* padding right */
	.padding-right-md-5 { padding-right: 5px !important; }
	.padding-right-md-10 { padding-right: 10px !important; }
	.padding-right-md-15 { padding-right: 15px !important; }
	.padding-right-md-20 { padding-right: 20px !important; }
	.padding-right-md-25 { padding-right: 25px !important; }
	.padding-right-md-30 { padding-right: 30px !important; }
	.padding-right-md-35 { padding-right: 35px !important; }
	.padding-right-md-40 { padding-right: 40px !important; }
	.padding-right-md-45 { padding-right: 45px !important; }
	.padding-right-md-50 { padding-right: 50px !important; }
	.padding-right-md-55 { padding-right: 55px !important; }
	.padding-right-md-60 { padding-right: 60px !important; }
	.padding-right-md-65 { padding-right: 65px !important; }
	.padding-right-md-70 { padding-right: 70px !important; }
	.padding-right-md-75 { padding-right: 75px !important; }
	.padding-right-md-80 { padding-right: 80px !important; }
	.padding-right-md-85 { padding-right: 85px !important; }
	.padding-right-md-100 { padding-right: 100px !important; }
	.padding-right-md-105 { padding-right: 105px !important; }
	.padding-right-md-110 { padding-right: 110px !important; }
	.padding-right-md-115 { padding-right: 115px !important; }
	.padding-right-md-120 { padding-right: 120px !important; }
	.padding-right-md-125 { padding-right: 125px !important; }
	.padding-right-md-130 { padding-right: 130px !important; }
	.padding-right-md-135 { padding-right: 135px !important; }
	.padding-right-md-140 { padding-right: 140px !important; }
	.padding-right-md-145 { padding-right: 145px !important; }
	.padding-right-md-150 { padding-right: 150px !important; }
	.padding-right-md-155 { padding-right: 155px !important; }
	.padding-right-md-160 { padding-right: 160px !important; }
	.padding-right-md-165 { padding-right: 165px !important; }
	.padding-right-md-170 { padding-right: 170px !important; }
	.padding-right-md-175 { padding-right: 175px !important; }
	.padding-right-md-180 { padding-right: 180px !important; }
	.padding-right-md-185 { padding-right: 185px !important; }
	.padding-right-md-190 { padding-right: 190px !important; }
	.padding-right-md-195 { padding-right: 195px !important; }
	.padding-right-md-200 { padding-right: 200px !important; }


	/* Padding (percent) 
	===================== */
	.padding-md-1-p { padding: 1% !important; }
	.padding-md-2-p { padding: 2% !important; }
	.padding-md-3-p { padding: 3% !important; }
	.padding-md-4-p { padding: 4% !important; }
	.padding-md-5-p { padding: 5% !important; }
	.padding-md-6-p { padding: 6% !important; }
	.padding-md-7-p { padding: 7% !important; }
	.padding-md-8-p { padding: 8% !important; }
	.padding-md-9-p { padding: 9% !important; }
	.padding-md-10-p { padding: 10% !important; }
	.padding-md-11-p { padding: 11% !important; }
	.padding-md-12-p { padding: 12% !important; }
	.padding-md-13-p { padding: 13% !important; }
	.padding-md-14-p { padding: 14% !important; }
	.padding-md-15-p { padding: 15% !important; }
	.padding-md-16-p { padding: 16% !important; }
	.padding-md-17-p { padding: 17% !important; }
	.padding-md-18-p { padding: 18% !important; }
	.padding-md-19-p { padding: 19% !important; }
	.padding-md-20-p { padding: 20% !important; }

	/* padding top (percent) */
	.padding-top-md-1-p { padding-top: 1% !important; }
	.padding-top-md-2-p { padding-top: 2% !important; }
	.padding-top-md-3-p { padding-top: 3% !important; }
	.padding-top-md-4-p { padding-top: 4% !important; }
	.padding-top-md-5-p { padding-top: 5% !important; }
	.padding-top-md-6-p { padding-top: 6% !important; }
	.padding-top-md-7-p { padding-top: 7% !important; }
	.padding-top-md-8-p { padding-top: 8% !important; }
	.padding-top-md-9-p { padding-top: 9% !important; }
	.padding-top-md-10-p { padding-top: 10% !important; }
	.padding-top-md-11-p { padding-top: 11% !important; }
	.padding-top-md-12-p { padding-top: 12% !important; }
	.padding-top-md-13-p { padding-top: 13% !important; }
	.padding-top-md-14-p { padding-top: 14% !important; }
	.padding-top-md-15-p { padding-top: 15% !important; }
	.padding-top-md-16-p { padding-top: 16% !important; }
	.padding-top-md-17-p { padding-top: 17% !important; }
	.padding-top-md-18-p { padding-top: 18% !important; }
	.padding-top-md-19-p { padding-top: 19% !important; }
	.padding-top-md-20-p { padding-top: 20% !important; }

	/* padding bottom (percent) */
	.padding-bottom-md-1-p { padding-bottom: 1% !important; }
	.padding-bottom-md-2-p { padding-bottom: 2% !important; }
	.padding-bottom-md-3-p { padding-bottom: 3% !important; }
	.padding-bottom-md-4-p { padding-bottom: 4% !important; }
	.padding-bottom-md-5-p { padding-bottom: 5% !important; }
	.padding-bottom-md-6-p { padding-bottom: 6% !important; }
	.padding-bottom-md-7-p { padding-bottom: 7% !important; }
	.padding-bottom-md-8-p { padding-bottom: 8% !important; }
	.padding-bottom-md-9-p { padding-bottom: 9% !important; }
	.padding-bottom-md-10-p { padding-bottom: 10% !important; }
	.padding-bottom-md-11-p { padding-bottom: 11% !important; }
	.padding-bottom-md-12-p { padding-bottom: 12% !important; }
	.padding-bottom-md-13-p { padding-bottom: 13% !important; }
	.padding-bottom-md-14-p { padding-bottom: 14% !important; }
	.padding-bottom-md-15-p { padding-bottom: 15% !important; }
	.padding-bottom-md-16-p { padding-bottom: 16% !important; }
	.padding-bottom-md-17-p { padding-bottom: 17% !important; }
	.padding-bottom-md-18-p { padding-bottom: 18% !important; }
	.padding-bottom-md-19-p { padding-bottom: 19% !important; }
	.padding-bottom-md-20-p { padding-bottom: 20% !important; }

	/* padding left (percent) */
	.padding-left-md-1-p { padding-left: 1% !important; }
	.padding-left-md-2-p { padding-left: 2% !important; }
	.padding-left-md-3-p { padding-left: 3% !important; }
	.padding-left-md-4-p { padding-left: 4% !important; }
	.padding-left-md-5-p { padding-left: 5% !important; }
	.padding-left-md-6-p { padding-left: 6% !important; }
	.padding-left-md-7-p { padding-left: 7% !important; }
	.padding-left-md-8-p { padding-left: 8% !important; }
	.padding-left-md-9-p { padding-left: 9% !important; }
	.padding-left-md-10-p { padding-left: 10% !important; }
	.padding-left-md-11-p { padding-left: 11% !important; }
	.padding-left-md-12-p { padding-left: 12% !important; }
	.padding-left-md-13-p { padding-left: 13% !important; }
	.padding-left-md-14-p { padding-left: 14% !important; }
	.padding-left-md-15-p { padding-left: 15% !important; }
	.padding-left-md-16-p { padding-left: 16% !important; }
	.padding-left-md-17-p { padding-left: 17% !important; }
	.padding-left-md-18-p { padding-left: 18% !important; }
	.padding-left-md-19-p { padding-left: 19% !important; }
	.padding-left-md-20-p { padding-left: 20% !important; }

	/* padding right (percent) */
	.padding-right-md-1-p { padding-right: 1% !important; }
	.padding-right-md-2-p { padding-right: 2% !important; }
	.padding-right-md-3-p { padding-right: 3% !important; }
	.padding-right-md-4-p { padding-right: 4% !important; }
	.padding-right-md-5-p { padding-right: 5% !important; }
	.padding-right-md-6-p { padding-right: 6% !important; }
	.padding-right-md-7-p { padding-right: 7% !important; }
	.padding-right-md-8-p { padding-right: 8% !important; }
	.padding-right-md-9-p { padding-right: 9% !important; }
	.padding-right-md-10-p { padding-right: 10% !important; }
	.padding-right-md-11-p { padding-right: 11% !important; }
	.padding-right-md-12-p { padding-right: 12% !important; }
	.padding-right-md-13-p { padding-right: 13% !important; }
	.padding-right-md-14-p { padding-right: 14% !important; }
	.padding-right-md-15-p { padding-right: 15% !important; }
	.padding-right-md-16-p { padding-right: 16% !important; }
	.padding-right-md-17-p { padding-right: 17% !important; }
	.padding-right-md-18-p { padding-right: 18% !important; }
	.padding-right-md-19-p { padding-right: 19% !important; }
	.padding-right-md-20-p { padding-right: 20% !important; }

	/* no paddings classes */
	.no-padding-md { padding: 0 !important; }
	.no-padding-md-top { padding-top: 0 !important; }
	.no-padding-md-bottom { padding-bottom: 0 !important; }
	.no-padding-md-left { padding-left: 0 !important; }
	.no-padding-md-right { padding-right: 0 !important; }
}
@media (min-width: 992px) {
	.padding-lg-5 { padding: 5px !important; }
	.padding-lg-10 { padding: 10px !important; }
	.padding-lg-15 { padding: 15px !important; }
	.padding-lg-20 { padding: 20px !important; }
	.padding-lg-25 { padding: 25px !important; }
	.padding-lg-30 { padding: 30px !important; }
	.padding-lg-35 { padding: 35px !important; }
	.padding-lg-40 { padding: 40px !important; }
	.padding-lg-45 { padding: 45px !important; }
	.padding-lg-50 { padding: 50px !important; }
	.padding-lg-55 { padding: 55px !important; }
	.padding-lg-60 { padding: 60px !important; }
	.padding-lg-65 { padding: 65px !important; }
	.padding-lg-70 { padding: 70px !important; }
	.padding-lg-75 { padding: 75px !important; }
	.padding-lg-80 { padding: 80px !important; }
	.padding-lg-85 { padding: 85px !important; }
	.padding-lg-100 { padding: 80px !important; }
	.padding-lg-105 { padding: 105px !important; }
	.padding-lg-110 { padding: 110px !important; }
	.padding-lg-115 { padding: 115px !important; }
	.padding-lg-120 { padding: 120px !important; }
	.padding-lg-125 { padding: 125px !important; }
	.padding-lg-130 { padding: 130px !important; }
	.padding-lg-135 { padding: 135px !important; }
	.padding-lg-140 { padding: 140px !important; }
	.padding-lg-145 { padding: 145px !important; }
	.padding-lg-150 { padding: 150px !important; }
	.padding-lg-155 { padding: 155px !important; }
	.padding-lg-160 { padding: 160px !important; }
	.padding-lg-165 { padding: 165px !important; }
	.padding-lg-170 { padding: 170px !important; }
	.padding-lg-175 { padding: 175px !important; }
	.padding-lg-180 { padding: 180px !important; }
	.padding-lg-185 { padding: 185px !important; }
	.padding-lg-190 { padding: 190px !important; }
	.padding-lg-195 { padding: 195px !important; }
	.padding-lg-200 { padding: 200px !important; }

	/* padding top */
	.padding-top-lg-5 { padding-top: 5px !important; }
	.padding-top-lg-10 { padding-top: 10px !important; }
	.padding-top-lg-15 { padding-top: 15px !important; }
	.padding-top-lg-20 { padding-top: 20px !important; }
	.padding-top-lg-25 { padding-top: 25px !important; }
	.padding-top-lg-30 { padding-top: 30px !important; }
	.padding-top-lg-35 { padding-top: 35px !important; }
	.padding-top-lg-40 { padding-top: 40px !important; }
	.padding-top-lg-45 { padding-top: 45px !important; }
	.padding-top-lg-50 { padding-top: 50px !important; }
	.padding-top-lg-55 { padding-top: 55px !important; }
	.padding-top-lg-60 { padding-top: 60px !important; }
	.padding-top-lg-65 { padding-top: 65px !important; }
	.padding-top-lg-70 { padding-top: 70px !important; }
	.padding-top-lg-75 { padding-top: 75px !important; }
	.padding-top-lg-80 { padding-top: 80px !important; }
	.padding-top-lg-85 { padding-top: 85px !important; }
	.padding-top-lg-100 { padding-top: 100px !important; }
	.padding-top-lg-105 { padding-top: 105px !important; }
	.padding-top-lg-110 { padding-top: 110px !important; }
	.padding-top-lg-115 { padding-top: 115px !important; }
	.padding-top-lg-120 { padding-top: 120px !important; }
	.padding-top-lg-125 { padding-top: 125px !important; }
	.padding-top-lg-130 { padding-top: 130px !important; }
	.padding-top-lg-135 { padding-top: 135px !important; }
	.padding-top-lg-140 { padding-top: 140px !important; }
	.padding-top-lg-145 { padding-top: 145px !important; }
	.padding-top-lg-150 { padding-top: 150px !important; }
	.padding-top-lg-155 { padding-top: 155px !important; }
	.padding-top-lg-160 { padding-top: 160px !important; }
	.padding-top-lg-165 { padding-top: 165px !important; }
	.padding-top-lg-170 { padding-top: 170px !important; }
	.padding-top-lg-175 { padding-top: 175px !important; }
	.padding-top-lg-180 { padding-top: 180px !important; }
	.padding-top-lg-185 { padding-top: 185px !important; }
	.padding-top-lg-190 { padding-top: 190px !important; }
	.padding-top-lg-195 { padding-top: 195px !important; }
	.padding-top-lg-200 { padding-top: 200px !important; }

	/* padding bottom */
	.padding-bottom-lg-5 { padding-bottom: 5px !important; }
	.padding-bottom-lg-10 { padding-bottom: 10px !important; }
	.padding-bottom-lg-15 { padding-bottom: 15px !important; }
	.padding-bottom-lg-20 { padding-bottom: 20px !important; }
	.padding-bottom-lg-25 { padding-bottom: 25px !important; }
	.padding-bottom-lg-30 { padding-bottom: 30px !important; }
	.padding-bottom-lg-35 { padding-bottom: 35px !important; }
	.padding-bottom-lg-40 { padding-bottom: 40px !important; }
	.padding-bottom-lg-45 { padding-bottom: 45px !important; }
	.padding-bottom-lg-50 { padding-bottom: 50px !important; }
	.padding-bottom-lg-55 { padding-bottom: 55px !important; }
	.padding-bottom-lg-60 { padding-bottom: 60px !important; }
	.padding-bottom-lg-65 { padding-bottom: 65px !important; }
	.padding-bottom-lg-70 { padding-bottom: 70px !important; }
	.padding-bottom-lg-75 { padding-bottom: 75px !important; }
	.padding-bottom-lg-80 { padding-bottom: 80px !important; }
	.padding-bottom-lg-85 { padding-bottom: 85px !important; }
	.padding-bottom-lg-100 { padding-bottom: 100px !important; }
	.padding-bottom-lg-105 { padding-bottom: 105px !important; }
	.padding-bottom-lg-110 { padding-bottom: 110px !important; }
	.padding-bottom-lg-115 { padding-bottom: 115px !important; }
	.padding-bottom-lg-120 { padding-bottom: 120px !important; }
	.padding-bottom-lg-125 { padding-bottom: 125px !important; }
	.padding-bottom-lg-130 { padding-bottom: 130px !important; }
	.padding-bottom-lg-135 { padding-bottom: 135px !important; }
	.padding-bottom-lg-140 { padding-bottom: 140px !important; }
	.padding-bottom-lg-145 { padding-bottom: 145px !important; }
	.padding-bottom-lg-150 { padding-bottom: 150px !important; }
	.padding-bottom-lg-155 { padding-bottom: 155px !important; }
	.padding-bottom-lg-160 { padding-bottom: 160px !important; }
	.padding-bottom-lg-165 { padding-bottom: 165px !important; }
	.padding-bottom-lg-170 { padding-bottom: 170px !important; }
	.padding-bottom-lg-175 { padding-bottom: 175px !important; }
	.padding-bottom-lg-180 { padding-bottom: 180px !important; }
	.padding-bottom-lg-185 { padding-bottom: 185px !important; }
	.padding-bottom-lg-190 { padding-bottom: 190px !important; }
	.padding-bottom-lg-195 { padding-bottom: 195px !important; }
	.padding-bottom-lg-200 { padding-bottom: 200px !important; }

	/* padding left */
	.padding-left-lg-5 { padding-left: 5px !important; }
	.padding-left-lg-10 { padding-left: 10px !important; }
	.padding-left-lg-15 { padding-left: 15px !important; }
	.padding-left-lg-20 { padding-left: 20px !important; }
	.padding-left-lg-25 { padding-left: 25px !important; }
	.padding-left-lg-30 { padding-left: 30px !important; }
	.padding-left-lg-35 { padding-left: 35px !important; }
	.padding-left-lg-40 { padding-left: 40px !important; }
	.padding-left-lg-45 { padding-left: 45px !important; }
	.padding-left-lg-50 { padding-left: 50px !important; }
	.padding-left-lg-55 { padding-left: 55px !important; }
	.padding-left-lg-60 { padding-left: 60px !important; }
	.padding-left-lg-65 { padding-left: 65px !important; }
	.padding-left-lg-70 { padding-left: 70px !important; }
	.padding-left-lg-75 { padding-left: 75px !important; }
	.padding-left-lg-80 { padding-left: 80px !important; }
	.padding-left-lg-85 { padding-left: 85px !important; }
	.padding-left-lg-100 { padding-left: 100px !important; }
	.padding-left-lg-105 { padding-left: 105px !important; }
	.padding-left-lg-110 { padding-left: 110px !important; }
	.padding-left-lg-115 { padding-left: 115px !important; }
	.padding-left-lg-120 { padding-left: 120px !important; }
	.padding-left-lg-125 { padding-left: 125px !important; }
	.padding-left-lg-130 { padding-left: 130px !important; }
	.padding-left-lg-135 { padding-left: 135px !important; }
	.padding-left-lg-140 { padding-left: 140px !important; }
	.padding-left-lg-145 { padding-left: 145px !important; }
	.padding-left-lg-150 { padding-left: 150px !important; }
	.padding-left-lg-155 { padding-left: 155px !important; }
	.padding-left-lg-160 { padding-left: 160px !important; }
	.padding-left-lg-165 { padding-left: 165px !important; }
	.padding-left-lg-170 { padding-left: 170px !important; }
	.padding-left-lg-175 { padding-left: 175px !important; }
	.padding-left-lg-180 { padding-left: 180px !important; }
	.padding-left-lg-185 { padding-left: 185px !important; }
	.padding-left-lg-190 { padding-left: 190px !important; }
	.padding-left-lg-195 { padding-left: 195px !important; }
	.padding-left-lg-200 { padding-left: 200px !important; }

	/* padding right */
	.padding-right-lg-5 { padding-right: 5px !important; }
	.padding-right-lg-10 { padding-right: 10px !important; }
	.padding-right-lg-15 { padding-right: 15px !important; }
	.padding-right-lg-20 { padding-right: 20px !important; }
	.padding-right-lg-25 { padding-right: 25px !important; }
	.padding-right-lg-30 { padding-right: 30px !important; }
	.padding-right-lg-35 { padding-right: 35px !important; }
	.padding-right-lg-40 { padding-right: 40px !important; }
	.padding-right-lg-45 { padding-right: 45px !important; }
	.padding-right-lg-50 { padding-right: 50px !important; }
	.padding-right-lg-55 { padding-right: 55px !important; }
	.padding-right-lg-60 { padding-right: 60px !important; }
	.padding-right-lg-65 { padding-right: 65px !important; }
	.padding-right-lg-70 { padding-right: 70px !important; }
	.padding-right-lg-75 { padding-right: 75px !important; }
	.padding-right-lg-80 { padding-right: 80px !important; }
	.padding-right-lg-85 { padding-right: 85px !important; }
	.padding-right-lg-100 { padding-right: 100px !important; }
	.padding-right-lg-105 { padding-right: 105px !important; }
	.padding-right-lg-110 { padding-right: 110px !important; }
	.padding-right-lg-115 { padding-right: 115px !important; }
	.padding-right-lg-120 { padding-right: 120px !important; }
	.padding-right-lg-125 { padding-right: 125px !important; }
	.padding-right-lg-130 { padding-right: 130px !important; }
	.padding-right-lg-135 { padding-right: 135px !important; }
	.padding-right-lg-140 { padding-right: 140px !important; }
	.padding-right-lg-145 { padding-right: 145px !important; }
	.padding-right-lg-150 { padding-right: 150px !important; }
	.padding-right-lg-155 { padding-right: 155px !important; }
	.padding-right-lg-160 { padding-right: 160px !important; }
	.padding-right-lg-165 { padding-right: 165px !important; }
	.padding-right-lg-170 { padding-right: 170px !important; }
	.padding-right-lg-175 { padding-right: 175px !important; }
	.padding-right-lg-180 { padding-right: 180px !important; }
	.padding-right-lg-185 { padding-right: 185px !important; }
	.padding-right-lg-190 { padding-right: 190px !important; }
	.padding-right-lg-195 { padding-right: 195px !important; }
	.padding-right-lg-200 { padding-right: 200px !important; }


	/* Padding (percent) 
	===================== */
	.padding-lg-1-p { padding: 1% !important; }
	.padding-lg-2-p { padding: 2% !important; }
	.padding-lg-3-p { padding: 3% !important; }
	.padding-lg-4-p { padding: 4% !important; }
	.padding-lg-5-p { padding: 5% !important; }
	.padding-lg-6-p { padding: 6% !important; }
	.padding-lg-7-p { padding: 7% !important; }
	.padding-lg-8-p { padding: 8% !important; }
	.padding-lg-9-p { padding: 9% !important; }
	.padding-lg-10-p { padding: 10% !important; }
	.padding-lg-11-p { padding: 11% !important; }
	.padding-lg-12-p { padding: 12% !important; }
	.padding-lg-13-p { padding: 13% !important; }
	.padding-lg-14-p { padding: 14% !important; }
	.padding-lg-15-p { padding: 15% !important; }
	.padding-lg-16-p { padding: 16% !important; }
	.padding-lg-17-p { padding: 17% !important; }
	.padding-lg-18-p { padding: 18% !important; }
	.padding-lg-19-p { padding: 19% !important; }
	.padding-lg-20-p { padding: 20% !important; }

	/* padding top (percent) */
	.padding-top-lg-1-p { padding-top: 1% !important; }
	.padding-top-lg-2-p { padding-top: 2% !important; }
	.padding-top-lg-3-p { padding-top: 3% !important; }
	.padding-top-lg-4-p { padding-top: 4% !important; }
	.padding-top-lg-5-p { padding-top: 5% !important; }
	.padding-top-lg-6-p { padding-top: 6% !important; }
	.padding-top-lg-7-p { padding-top: 7% !important; }
	.padding-top-lg-8-p { padding-top: 8% !important; }
	.padding-top-lg-9-p { padding-top: 9% !important; }
	.padding-top-lg-10-p { padding-top: 10% !important; }
	.padding-top-lg-11-p { padding-top: 11% !important; }
	.padding-top-lg-12-p { padding-top: 12% !important; }
	.padding-top-lg-13-p { padding-top: 13% !important; }
	.padding-top-lg-14-p { padding-top: 14% !important; }
	.padding-top-lg-15-p { padding-top: 15% !important; }
	.padding-top-lg-16-p { padding-top: 16% !important; }
	.padding-top-lg-17-p { padding-top: 17% !important; }
	.padding-top-lg-18-p { padding-top: 18% !important; }
	.padding-top-lg-19-p { padding-top: 19% !important; }
	.padding-top-lg-20-p { padding-top: 20% !important; }

	/* padding bottom (percent) */
	.padding-bottom-lg-1-p { padding-bottom: 1% !important; }
	.padding-bottom-lg-2-p { padding-bottom: 2% !important; }
	.padding-bottom-lg-3-p { padding-bottom: 3% !important; }
	.padding-bottom-lg-4-p { padding-bottom: 4% !important; }
	.padding-bottom-lg-5-p { padding-bottom: 5% !important; }
	.padding-bottom-lg-6-p { padding-bottom: 6% !important; }
	.padding-bottom-lg-7-p { padding-bottom: 7% !important; }
	.padding-bottom-lg-8-p { padding-bottom: 8% !important; }
	.padding-bottom-lg-9-p { padding-bottom: 9% !important; }
	.padding-bottom-lg-10-p { padding-bottom: 10% !important; }
	.padding-bottom-lg-11-p { padding-bottom: 11% !important; }
	.padding-bottom-lg-12-p { padding-bottom: 12% !important; }
	.padding-bottom-lg-13-p { padding-bottom: 13% !important; }
	.padding-bottom-lg-14-p { padding-bottom: 14% !important; }
	.padding-bottom-lg-15-p { padding-bottom: 15% !important; }
	.padding-bottom-lg-16-p { padding-bottom: 16% !important; }
	.padding-bottom-lg-17-p { padding-bottom: 17% !important; }
	.padding-bottom-lg-18-p { padding-bottom: 18% !important; }
	.padding-bottom-lg-19-p { padding-bottom: 19% !important; }
	.padding-bottom-lg-20-p { padding-bottom: 20% !important; }

	/* padding left (percent) */
	.padding-left-lg-1-p { padding-left: 1% !important; }
	.padding-left-lg-2-p { padding-left: 2% !important; }
	.padding-left-lg-3-p { padding-left: 3% !important; }
	.padding-left-lg-4-p { padding-left: 4% !important; }
	.padding-left-lg-5-p { padding-left: 5% !important; }
	.padding-left-lg-6-p { padding-left: 6% !important; }
	.padding-left-lg-7-p { padding-left: 7% !important; }
	.padding-left-lg-8-p { padding-left: 8% !important; }
	.padding-left-lg-9-p { padding-left: 9% !important; }
	.padding-left-lg-10-p { padding-left: 10% !important; }
	.padding-left-lg-11-p { padding-left: 11% !important; }
	.padding-left-lg-12-p { padding-left: 12% !important; }
	.padding-left-lg-13-p { padding-left: 13% !important; }
	.padding-left-lg-14-p { padding-left: 14% !important; }
	.padding-left-lg-15-p { padding-left: 15% !important; }
	.padding-left-lg-16-p { padding-left: 16% !important; }
	.padding-left-lg-17-p { padding-left: 17% !important; }
	.padding-left-lg-18-p { padding-left: 18% !important; }
	.padding-left-lg-19-p { padding-left: 19% !important; }
	.padding-left-lg-20-p { padding-left: 20% !important; }

	/* padding right (percent) */
	.padding-right-lg-1-p { padding-right: 1% !important; }
	.padding-right-lg-2-p { padding-right: 2% !important; }
	.padding-right-lg-3-p { padding-right: 3% !important; }
	.padding-right-lg-4-p { padding-right: 4% !important; }
	.padding-right-lg-5-p { padding-right: 5% !important; }
	.padding-right-lg-6-p { padding-right: 6% !important; }
	.padding-right-lg-7-p { padding-right: 7% !important; }
	.padding-right-lg-8-p { padding-right: 8% !important; }
	.padding-right-lg-9-p { padding-right: 9% !important; }
	.padding-right-lg-10-p { padding-right: 10% !important; }
	.padding-right-lg-11-p { padding-right: 11% !important; }
	.padding-right-lg-12-p { padding-right: 12% !important; }
	.padding-right-lg-13-p { padding-right: 13% !important; }
	.padding-right-lg-14-p { padding-right: 14% !important; }
	.padding-right-lg-15-p { padding-right: 15% !important; }
	.padding-right-lg-16-p { padding-right: 16% !important; }
	.padding-right-lg-17-p { padding-right: 17% !important; }
	.padding-right-lg-18-p { padding-right: 18% !important; }
	.padding-right-lg-19-p { padding-right: 19% !important; }
	.padding-right-lg-20-p { padding-right: 20% !important; }

	/* no paddings classes */
	.no-padding-lg { padding: 0 !important; }
	.no-padding-lg-top { padding-top: 0 !important; }
	.no-padding-lg-bottom { padding-bottom: 0 !important; }
	.no-padding-lg-left { padding-left: 0 !important; }
	.no-padding-lg-right { padding-right: 0 !important; }
}
@media (min-width: 1025px) {
	.padding-xlg-5 { padding: 5px !important; }
	.padding-xlg-10 { padding: 10px !important; }
	.padding-xlg-15 { padding: 15px !important; }
	.padding-xlg-20 { padding: 20px !important; }
	.padding-xlg-25 { padding: 25px !important; }
	.padding-xlg-30 { padding: 30px !important; }
	.padding-xlg-35 { padding: 35px !important; }
	.padding-xlg-40 { padding: 40px !important; }
	.padding-xlg-45 { padding: 45px !important; }
	.padding-xlg-50 { padding: 50px !important; }
	.padding-xlg-55 { padding: 55px !important; }
	.padding-xlg-60 { padding: 60px !important; }
	.padding-xlg-65 { padding: 65px !important; }
	.padding-xlg-70 { padding: 70px !important; }
	.padding-xlg-75 { padding: 75px !important; }
	.padding-xlg-80 { padding: 80px !important; }
	.padding-xlg-85 { padding: 85px !important; }
	.padding-xlg-100 { padding: 80px !important; }
	.padding-xlg-105 { padding: 105px !important; }
	.padding-xlg-110 { padding: 110px !important; }
	.padding-xlg-115 { padding: 115px !important; }
	.padding-xlg-120 { padding: 120px !important; }
	.padding-xlg-125 { padding: 125px !important; }
	.padding-xlg-130 { padding: 130px !important; }
	.padding-xlg-135 { padding: 135px !important; }
	.padding-xlg-140 { padding: 140px !important; }
	.padding-xlg-145 { padding: 145px !important; }
	.padding-xlg-150 { padding: 150px !important; }
	.padding-xlg-155 { padding: 155px !important; }
	.padding-xlg-160 { padding: 160px !important; }
	.padding-xlg-165 { padding: 165px !important; }
	.padding-xlg-170 { padding: 170px !important; }
	.padding-xlg-175 { padding: 175px !important; }
	.padding-xlg-180 { padding: 180px !important; }
	.padding-xlg-185 { padding: 185px !important; }
	.padding-xlg-190 { padding: 190px !important; }
	.padding-xlg-195 { padding: 195px !important; }
	.padding-xlg-200 { padding: 200px !important; }

	/* padding top */
	.padding-top-xlg-5 { padding-top: 5px !important; }
	.padding-top-xlg-10 { padding-top: 10px !important; }
	.padding-top-xlg-15 { padding-top: 15px !important; }
	.padding-top-xlg-20 { padding-top: 20px !important; }
	.padding-top-xlg-25 { padding-top: 25px !important; }
	.padding-top-xlg-30 { padding-top: 30px !important; }
	.padding-top-xlg-35 { padding-top: 35px !important; }
	.padding-top-xlg-40 { padding-top: 40px !important; }
	.padding-top-xlg-45 { padding-top: 45px !important; }
	.padding-top-xlg-50 { padding-top: 50px !important; }
	.padding-top-xlg-55 { padding-top: 55px !important; }
	.padding-top-xlg-60 { padding-top: 60px !important; }
	.padding-top-xlg-65 { padding-top: 65px !important; }
	.padding-top-xlg-70 { padding-top: 70px !important; }
	.padding-top-xlg-75 { padding-top: 75px !important; }
	.padding-top-xlg-80 { padding-top: 80px !important; }
	.padding-top-xlg-85 { padding-top: 85px !important; }
	.padding-top-xlg-100 { padding-top: 100px !important; }
	.padding-top-xlg-105 { padding-top: 105px !important; }
	.padding-top-xlg-110 { padding-top: 110px !important; }
	.padding-top-xlg-115 { padding-top: 115px !important; }
	.padding-top-xlg-120 { padding-top: 120px !important; }
	.padding-top-xlg-125 { padding-top: 125px !important; }
	.padding-top-xlg-130 { padding-top: 130px !important; }
	.padding-top-xlg-135 { padding-top: 135px !important; }
	.padding-top-xlg-140 { padding-top: 140px !important; }
	.padding-top-xlg-145 { padding-top: 145px !important; }
	.padding-top-xlg-150 { padding-top: 150px !important; }
	.padding-top-xlg-155 { padding-top: 155px !important; }
	.padding-top-xlg-160 { padding-top: 160px !important; }
	.padding-top-xlg-165 { padding-top: 165px !important; }
	.padding-top-xlg-170 { padding-top: 170px !important; }
	.padding-top-xlg-175 { padding-top: 175px !important; }
	.padding-top-xlg-180 { padding-top: 180px !important; }
	.padding-top-xlg-185 { padding-top: 185px !important; }
	.padding-top-xlg-190 { padding-top: 190px !important; }
	.padding-top-xlg-195 { padding-top: 195px !important; }
	.padding-top-xlg-200 { padding-top: 200px !important; }

	/* padding bottom */
	.padding-bottom-xlg-5 { padding-bottom: 5px !important; }
	.padding-bottom-xlg-10 { padding-bottom: 10px !important; }
	.padding-bottom-xlg-15 { padding-bottom: 15px !important; }
	.padding-bottom-xlg-20 { padding-bottom: 20px !important; }
	.padding-bottom-xlg-25 { padding-bottom: 25px !important; }
	.padding-bottom-xlg-30 { padding-bottom: 30px !important; }
	.padding-bottom-xlg-35 { padding-bottom: 35px !important; }
	.padding-bottom-xlg-40 { padding-bottom: 40px !important; }
	.padding-bottom-xlg-45 { padding-bottom: 45px !important; }
	.padding-bottom-xlg-50 { padding-bottom: 50px !important; }
	.padding-bottom-xlg-55 { padding-bottom: 55px !important; }
	.padding-bottom-xlg-60 { padding-bottom: 60px !important; }
	.padding-bottom-xlg-65 { padding-bottom: 65px !important; }
	.padding-bottom-xlg-70 { padding-bottom: 70px !important; }
	.padding-bottom-xlg-75 { padding-bottom: 75px !important; }
	.padding-bottom-xlg-80 { padding-bottom: 80px !important; }
	.padding-bottom-xlg-85 { padding-bottom: 85px !important; }
	.padding-bottom-xlg-100 { padding-bottom: 100px !important; }
	.padding-bottom-xlg-105 { padding-bottom: 105px !important; }
	.padding-bottom-xlg-110 { padding-bottom: 110px !important; }
	.padding-bottom-xlg-115 { padding-bottom: 115px !important; }
	.padding-bottom-xlg-120 { padding-bottom: 120px !important; }
	.padding-bottom-xlg-125 { padding-bottom: 125px !important; }
	.padding-bottom-xlg-130 { padding-bottom: 130px !important; }
	.padding-bottom-xlg-135 { padding-bottom: 135px !important; }
	.padding-bottom-xlg-140 { padding-bottom: 140px !important; }
	.padding-bottom-xlg-145 { padding-bottom: 145px !important; }
	.padding-bottom-xlg-150 { padding-bottom: 150px !important; }
	.padding-bottom-xlg-155 { padding-bottom: 155px !important; }
	.padding-bottom-xlg-160 { padding-bottom: 160px !important; }
	.padding-bottom-xlg-165 { padding-bottom: 165px !important; }
	.padding-bottom-xlg-170 { padding-bottom: 170px !important; }
	.padding-bottom-xlg-175 { padding-bottom: 175px !important; }
	.padding-bottom-xlg-180 { padding-bottom: 180px !important; }
	.padding-bottom-xlg-185 { padding-bottom: 185px !important; }
	.padding-bottom-xlg-190 { padding-bottom: 190px !important; }
	.padding-bottom-xlg-195 { padding-bottom: 195px !important; }
	.padding-bottom-xlg-200 { padding-bottom: 200px !important; }

	/* padding left */
	.padding-left-xlg-5 { padding-left: 5px !important; }
	.padding-left-xlg-10 { padding-left: 10px !important; }
	.padding-left-xlg-15 { padding-left: 15px !important; }
	.padding-left-xlg-20 { padding-left: 20px !important; }
	.padding-left-xlg-25 { padding-left: 25px !important; }
	.padding-left-xlg-30 { padding-left: 30px !important; }
	.padding-left-xlg-35 { padding-left: 35px !important; }
	.padding-left-xlg-40 { padding-left: 40px !important; }
	.padding-left-xlg-45 { padding-left: 45px !important; }
	.padding-left-xlg-50 { padding-left: 50px !important; }
	.padding-left-xlg-55 { padding-left: 55px !important; }
	.padding-left-xlg-60 { padding-left: 60px !important; }
	.padding-left-xlg-65 { padding-left: 65px !important; }
	.padding-left-xlg-70 { padding-left: 70px !important; }
	.padding-left-xlg-75 { padding-left: 75px !important; }
	.padding-left-xlg-80 { padding-left: 80px !important; }
	.padding-left-xlg-85 { padding-left: 85px !important; }
	.padding-left-xlg-100 { padding-left: 100px !important; }
	.padding-left-xlg-105 { padding-left: 105px !important; }
	.padding-left-xlg-110 { padding-left: 110px !important; }
	.padding-left-xlg-115 { padding-left: 115px !important; }
	.padding-left-xlg-120 { padding-left: 120px !important; }
	.padding-left-xlg-125 { padding-left: 125px !important; }
	.padding-left-xlg-130 { padding-left: 130px !important; }
	.padding-left-xlg-135 { padding-left: 135px !important; }
	.padding-left-xlg-140 { padding-left: 140px !important; }
	.padding-left-xlg-145 { padding-left: 145px !important; }
	.padding-left-xlg-150 { padding-left: 150px !important; }
	.padding-left-xlg-155 { padding-left: 155px !important; }
	.padding-left-xlg-160 { padding-left: 160px !important; }
	.padding-left-xlg-165 { padding-left: 165px !important; }
	.padding-left-xlg-170 { padding-left: 170px !important; }
	.padding-left-xlg-175 { padding-left: 175px !important; }
	.padding-left-xlg-180 { padding-left: 180px !important; }
	.padding-left-xlg-185 { padding-left: 185px !important; }
	.padding-left-xlg-190 { padding-left: 190px !important; }
	.padding-left-xlg-195 { padding-left: 195px !important; }
	.padding-left-xlg-200 { padding-left: 200px !important; }

	/* padding right */
	.padding-right-xlg-5 { padding-right: 5px !important; }
	.padding-right-xlg-10 { padding-right: 10px !important; }
	.padding-right-xlg-15 { padding-right: 15px !important; }
	.padding-right-xlg-20 { padding-right: 20px !important; }
	.padding-right-xlg-25 { padding-right: 25px !important; }
	.padding-right-xlg-30 { padding-right: 30px !important; }
	.padding-right-xlg-35 { padding-right: 35px !important; }
	.padding-right-xlg-40 { padding-right: 40px !important; }
	.padding-right-xlg-45 { padding-right: 45px !important; }
	.padding-right-xlg-50 { padding-right: 50px !important; }
	.padding-right-xlg-55 { padding-right: 55px !important; }
	.padding-right-xlg-60 { padding-right: 60px !important; }
	.padding-right-xlg-65 { padding-right: 65px !important; }
	.padding-right-xlg-70 { padding-right: 70px !important; }
	.padding-right-xlg-75 { padding-right: 75px !important; }
	.padding-right-xlg-80 { padding-right: 80px !important; }
	.padding-right-xlg-85 { padding-right: 85px !important; }
	.padding-right-xlg-100 { padding-right: 100px !important; }
	.padding-right-xlg-105 { padding-right: 105px !important; }
	.padding-right-xlg-110 { padding-right: 110px !important; }
	.padding-right-xlg-115 { padding-right: 115px !important; }
	.padding-right-xlg-120 { padding-right: 120px !important; }
	.padding-right-xlg-125 { padding-right: 125px !important; }
	.padding-right-xlg-130 { padding-right: 130px !important; }
	.padding-right-xlg-135 { padding-right: 135px !important; }
	.padding-right-xlg-140 { padding-right: 140px !important; }
	.padding-right-xlg-145 { padding-right: 145px !important; }
	.padding-right-xlg-150 { padding-right: 150px !important; }
	.padding-right-xlg-155 { padding-right: 155px !important; }
	.padding-right-xlg-160 { padding-right: 160px !important; }
	.padding-right-xlg-165 { padding-right: 165px !important; }
	.padding-right-xlg-170 { padding-right: 170px !important; }
	.padding-right-xlg-175 { padding-right: 175px !important; }
	.padding-right-xlg-180 { padding-right: 180px !important; }
	.padding-right-xlg-185 { padding-right: 185px !important; }
	.padding-right-xlg-190 { padding-right: 190px !important; }
	.padding-right-xlg-195 { padding-right: 195px !important; }
	.padding-right-xlg-200 { padding-right: 200px !important; }


	/* Padding (percent) 
	===================== */
	.padding-xlg-1-p { padding: 1% !important; }
	.padding-xlg-2-p { padding: 2% !important; }
	.padding-xlg-3-p { padding: 3% !important; }
	.padding-xlg-4-p { padding: 4% !important; }
	.padding-xlg-5-p { padding: 5% !important; }
	.padding-xlg-6-p { padding: 6% !important; }
	.padding-xlg-7-p { padding: 7% !important; }
	.padding-xlg-8-p { padding: 8% !important; }
	.padding-xlg-9-p { padding: 9% !important; }
	.padding-xlg-10-p { padding: 10% !important; }
	.padding-xlg-11-p { padding: 11% !important; }
	.padding-xlg-12-p { padding: 12% !important; }
	.padding-xlg-13-p { padding: 13% !important; }
	.padding-xlg-14-p { padding: 14% !important; }
	.padding-xlg-15-p { padding: 15% !important; }
	.padding-xlg-16-p { padding: 16% !important; }
	.padding-xlg-17-p { padding: 17% !important; }
	.padding-xlg-18-p { padding: 18% !important; }
	.padding-xlg-19-p { padding: 19% !important; }
	.padding-xlg-20-p { padding: 20% !important; }

	/* padding top (percent) */
	.padding-top-xlg-1-p { padding-top: 1% !important; }
	.padding-top-xlg-2-p { padding-top: 2% !important; }
	.padding-top-xlg-3-p { padding-top: 3% !important; }
	.padding-top-xlg-4-p { padding-top: 4% !important; }
	.padding-top-xlg-5-p { padding-top: 5% !important; }
	.padding-top-xlg-6-p { padding-top: 6% !important; }
	.padding-top-xlg-7-p { padding-top: 7% !important; }
	.padding-top-xlg-8-p { padding-top: 8% !important; }
	.padding-top-xlg-9-p { padding-top: 9% !important; }
	.padding-top-xlg-10-p { padding-top: 10% !important; }
	.padding-top-xlg-11-p { padding-top: 11% !important; }
	.padding-top-xlg-12-p { padding-top: 12% !important; }
	.padding-top-xlg-13-p { padding-top: 13% !important; }
	.padding-top-xlg-14-p { padding-top: 14% !important; }
	.padding-top-xlg-15-p { padding-top: 15% !important; }
	.padding-top-xlg-16-p { padding-top: 16% !important; }
	.padding-top-xlg-17-p { padding-top: 17% !important; }
	.padding-top-xlg-18-p { padding-top: 18% !important; }
	.padding-top-xlg-19-p { padding-top: 19% !important; }
	.padding-top-xlg-20-p { padding-top: 20% !important; }

	/* padding bottom (percent) */
	.padding-bottom-xlg-1-p { padding-bottom: 1% !important; }
	.padding-bottom-xlg-2-p { padding-bottom: 2% !important; }
	.padding-bottom-xlg-3-p { padding-bottom: 3% !important; }
	.padding-bottom-xlg-4-p { padding-bottom: 4% !important; }
	.padding-bottom-xlg-5-p { padding-bottom: 5% !important; }
	.padding-bottom-xlg-6-p { padding-bottom: 6% !important; }
	.padding-bottom-xlg-7-p { padding-bottom: 7% !important; }
	.padding-bottom-xlg-8-p { padding-bottom: 8% !important; }
	.padding-bottom-xlg-9-p { padding-bottom: 9% !important; }
	.padding-bottom-xlg-10-p { padding-bottom: 10% !important; }
	.padding-bottom-xlg-11-p { padding-bottom: 11% !important; }
	.padding-bottom-xlg-12-p { padding-bottom: 12% !important; }
	.padding-bottom-xlg-13-p { padding-bottom: 13% !important; }
	.padding-bottom-xlg-14-p { padding-bottom: 14% !important; }
	.padding-bottom-xlg-15-p { padding-bottom: 15% !important; }
	.padding-bottom-xlg-16-p { padding-bottom: 16% !important; }
	.padding-bottom-xlg-17-p { padding-bottom: 17% !important; }
	.padding-bottom-xlg-18-p { padding-bottom: 18% !important; }
	.padding-bottom-xlg-19-p { padding-bottom: 19% !important; }
	.padding-bottom-xlg-20-p { padding-bottom: 20% !important; }

	/* padding left (percent) */
	.padding-left-xlg-1-p { padding-left: 1% !important; }
	.padding-left-xlg-2-p { padding-left: 2% !important; }
	.padding-left-xlg-3-p { padding-left: 3% !important; }
	.padding-left-xlg-4-p { padding-left: 4% !important; }
	.padding-left-xlg-5-p { padding-left: 5% !important; }
	.padding-left-xlg-6-p { padding-left: 6% !important; }
	.padding-left-xlg-7-p { padding-left: 7% !important; }
	.padding-left-xlg-8-p { padding-left: 8% !important; }
	.padding-left-xlg-9-p { padding-left: 9% !important; }
	.padding-left-xlg-10-p { padding-left: 10% !important; }
	.padding-left-xlg-11-p { padding-left: 11% !important; }
	.padding-left-xlg-12-p { padding-left: 12% !important; }
	.padding-left-xlg-13-p { padding-left: 13% !important; }
	.padding-left-xlg-14-p { padding-left: 14% !important; }
	.padding-left-xlg-15-p { padding-left: 15% !important; }
	.padding-left-xlg-16-p { padding-left: 16% !important; }
	.padding-left-xlg-17-p { padding-left: 17% !important; }
	.padding-left-xlg-18-p { padding-left: 18% !important; }
	.padding-left-xlg-19-p { padding-left: 19% !important; }
	.padding-left-xlg-20-p { padding-left: 20% !important; }

	/* padding right (percent) */
	.padding-right-xlg-1-p { padding-right: 1% !important; }
	.padding-right-xlg-2-p { padding-right: 2% !important; }
	.padding-right-xlg-3-p { padding-right: 3% !important; }
	.padding-right-xlg-4-p { padding-right: 4% !important; }
	.padding-right-xlg-5-p { padding-right: 5% !important; }
	.padding-right-xlg-6-p { padding-right: 6% !important; }
	.padding-right-xlg-7-p { padding-right: 7% !important; }
	.padding-right-xlg-8-p { padding-right: 8% !important; }
	.padding-right-xlg-9-p { padding-right: 9% !important; }
	.padding-right-xlg-10-p { padding-right: 10% !important; }
	.padding-right-xlg-11-p { padding-right: 11% !important; }
	.padding-right-xlg-12-p { padding-right: 12% !important; }
	.padding-right-xlg-13-p { padding-right: 13% !important; }
	.padding-right-xlg-14-p { padding-right: 14% !important; }
	.padding-right-xlg-15-p { padding-right: 15% !important; }
	.padding-right-xlg-16-p { padding-right: 16% !important; }
	.padding-right-xlg-17-p { padding-right: 17% !important; }
	.padding-right-xlg-18-p { padding-right: 18% !important; }
	.padding-right-xlg-19-p { padding-right: 19% !important; }
	.padding-right-xlg-20-p { padding-right: 20% !important; }

	/* no paddings classes */
	.no-padding-xlg { padding: 0 !important; }
	.no-padding-xlg-top { padding-top: 0 !important; }
	.no-padding-xlg-bottom { padding-bottom: 0 !important; }
	.no-padding-xlg-left { padding-left: 0 !important; }
	.no-padding-xlg-right { padding-right: 0 !important; }
}


/* ------------------------------------------------------------- *
 * Margin classes
/* ------------------------------------------------------------- */

.margin-auto { 
	margin-left: auto !important; 
	margin-right: auto !important; 
}

.margin-5 { margin: 5px !important; }
.margin-10 { margin: 10px !important; }
.margin-15 { margin: 15px !important; }
.margin-20 { margin: 20px !important; }
.margin-25 { margin: 25px !important; }
.margin-30 { margin: 30px !important; }
.margin-35 { margin: 35px !important; }
.margin-40 { margin: 40px !important; }
.margin-45 { margin: 45px !important; }
.margin-50 { margin: 50px !important; }
.margin-55 { margin: 55px !important; }
.margin-60 { margin: 60px !important; }
.margin-65 { margin: 65px !important; }
.margin-70 { margin: 70px !important; }
.margin-75 { margin: 75px !important; }
.margin-80 { margin: 80px !important; }
.margin-85 { margin: 85px !important; }
.margin-100 { margin: 80px !important; }
.margin-105 { margin: 105px !important; }
.margin-110 { margin: 110px !important; }
.margin-115 { margin: 115px !important; }
.margin-120 { margin: 120px !important; }
.margin-125 { margin: 125px !important; }
.margin-130 { margin: 130px !important; }
.margin-135 { margin: 135px !important; }
.margin-140 { margin: 140px !important; }
.margin-145 { margin: 145px !important; }
.margin-150 { margin: 150px !important; }
.margin-155 { margin: 155px !important; }
.margin-160 { margin: 160px !important; }
.margin-165 { margin: 165px !important; }
.margin-170 { margin: 170px !important; }
.margin-175 { margin: 175px !important; }
.margin-180 { margin: 180px !important; }
.margin-185 { margin: 185px !important; }
.margin-190 { margin: 190px !important; }
.margin-195 { margin: 195px !important; }
.margin-200 { margin: 200px !important; }

/* margin top */
.margin-top-5 { margin-top: 5px !important; }
.margin-top-10 { margin-top: 10px !important; }
.margin-top-15 { margin-top: 15px !important; }
.margin-top-20 { margin-top: 20px !important; }
.margin-top-25 { margin-top: 25px !important; }
.margin-top-30 { margin-top: 30px !important; }
.margin-top-35 { margin-top: 35px !important; }
.margin-top-40 { margin-top: 40px !important; }
.margin-top-45 { margin-top: 45px !important; }
.margin-top-50 { margin-top: 50px !important; }
.margin-top-55 { margin-top: 55px !important; }
.margin-top-60 { margin-top: 60px !important; }
.margin-top-65 { margin-top: 65px !important; }
.margin-top-70 { margin-top: 70px !important; }
.margin-top-75 { margin-top: 75px !important; }
.margin-top-80 { margin-top: 80px !important; }
.margin-top-85 { margin-top: 85px !important; }
.margin-top-100 { margin-top: 100px !important; }
.margin-top-105 { margin-top: 105px !important; }
.margin-top-110 { margin-top: 110px !important; }
.margin-top-115 { margin-top: 115px !important; }
.margin-top-120 { margin-top: 120px !important; }
.margin-top-125 { margin-top: 125px !important; }
.margin-top-130 { margin-top: 130px !important; }
.margin-top-135 { margin-top: 135px !important; }
.margin-top-140 { margin-top: 140px !important; }
.margin-top-145 { margin-top: 145px !important; }
.margin-top-150 { margin-top: 150px !important; }
.margin-top-155 { margin-top: 155px !important; }
.margin-top-160 { margin-top: 160px !important; }
.margin-top-165 { margin-top: 165px !important; }
.margin-top-170 { margin-top: 170px !important; }
.margin-top-175 { margin-top: 175px !important; }
.margin-top-180 { margin-top: 180px !important; }
.margin-top-185 { margin-top: 185px !important; }
.margin-top-190 { margin-top: 190px !important; }
.margin-top-195 { margin-top: 195px !important; }
.margin-top-200 { margin-top: 200px !important; }

/* margin bottom */
.margin-bottom-5 { margin-bottom: 5px !important; }
.margin-bottom-10 { margin-bottom: 10px !important; }
.margin-bottom-15 { margin-bottom: 15px !important; }
.margin-bottom-20 { margin-bottom: 20px !important; }
.margin-bottom-25 { margin-bottom: 25px !important; }
.margin-bottom-30 { margin-bottom: 30px !important; }
.margin-bottom-35 { margin-bottom: 35px !important; }
.margin-bottom-40 { margin-bottom: 40px !important; }
.margin-bottom-45 { margin-bottom: 45px !important; }
.margin-bottom-50 { margin-bottom: 50px !important; }
.margin-bottom-55 { margin-bottom: 55px !important; }
.margin-bottom-60 { margin-bottom: 60px !important; }
.margin-bottom-65 { margin-bottom: 65px !important; }
.margin-bottom-70 { margin-bottom: 70px !important; }
.margin-bottom-75 { margin-bottom: 75px !important; }
.margin-bottom-80 { margin-bottom: 80px !important; }
.margin-bottom-85 { margin-bottom: 85px !important; }
.margin-bottom-100 { margin-bottom: 100px !important; }
.margin-bottom-105 { margin-bottom: 105px !important; }
.margin-bottom-110 { margin-bottom: 110px !important; }
.margin-bottom-115 { margin-bottom: 115px !important; }
.margin-bottom-120 { margin-bottom: 120px !important; }
.margin-bottom-125 { margin-bottom: 125px !important; }
.margin-bottom-130 { margin-bottom: 130px !important; }
.margin-bottom-135 { margin-bottom: 135px !important; }
.margin-bottom-140 { margin-bottom: 140px !important; }
.margin-bottom-145 { margin-bottom: 145px !important; }
.margin-bottom-150 { margin-bottom: 150px !important; }
.margin-bottom-155 { margin-bottom: 155px !important; }
.margin-bottom-160 { margin-bottom: 160px !important; }
.margin-bottom-165 { margin-bottom: 165px !important; }
.margin-bottom-170 { margin-bottom: 170px !important; }
.margin-bottom-175 { margin-bottom: 175px !important; }
.margin-bottom-180 { margin-bottom: 180px !important; }
.margin-bottom-185 { margin-bottom: 185px !important; }
.margin-bottom-190 { margin-bottom: 190px !important; }
.margin-bottom-195 { margin-bottom: 195px !important; }
.margin-bottom-200 { margin-bottom: 200px !important; }

/* margin left */
.margin-left-5 { margin-left: 5px !important; }
.margin-left-10 { margin-left: 10px !important; }
.margin-left-15 { margin-left: 15px !important; }
.margin-left-20 { margin-left: 20px !important; }
.margin-left-25 { margin-left: 25px !important; }
.margin-left-30 { margin-left: 30px !important; }
.margin-left-35 { margin-left: 35px !important; }
.margin-left-40 { margin-left: 40px !important; }
.margin-left-45 { margin-left: 45px !important; }
.margin-left-50 { margin-left: 50px !important; }
.margin-left-55 { margin-left: 55px !important; }
.margin-left-60 { margin-left: 60px !important; }
.margin-left-65 { margin-left: 65px !important; }
.margin-left-70 { margin-left: 70px !important; }
.margin-left-75 { margin-left: 75px !important; }
.margin-left-80 { margin-left: 80px !important; }
.margin-left-85 { margin-left: 85px !important; }
.margin-left-100 { margin-left: 100px !important; }
.margin-left-105 { margin-left: 105px !important; }
.margin-left-110 { margin-left: 110px !important; }
.margin-left-115 { margin-left: 115px !important; }
.margin-left-120 { margin-left: 120px !important; }
.margin-left-125 { margin-left: 125px !important; }
.margin-left-130 { margin-left: 130px !important; }
.margin-left-135 { margin-left: 135px !important; }
.margin-left-140 { margin-left: 140px !important; }
.margin-left-145 { margin-left: 145px !important; }
.margin-left-150 { margin-left: 150px !important; }
.margin-left-155 { margin-left: 155px !important; }
.margin-left-160 { margin-left: 160px !important; }
.margin-left-165 { margin-left: 165px !important; }
.margin-left-170 { margin-left: 170px !important; }
.margin-left-175 { margin-left: 175px !important; }
.margin-left-180 { margin-left: 180px !important; }
.margin-left-185 { margin-left: 185px !important; }
.margin-left-190 { margin-left: 190px !important; }
.margin-left-195 { margin-left: 195px !important; }
.margin-left-200 { margin-left: 200px !important; }

/* margin right */
.margin-right-5 { margin-right: 5px !important; }
.margin-right-10 { margin-right: 10px !important; }
.margin-right-15 { margin-right: 15px !important; }
.margin-right-20 { margin-right: 20px !important; }
.margin-right-25 { margin-right: 25px !important; }
.margin-right-30 { margin-right: 30px !important; }
.margin-right-35 { margin-right: 35px !important; }
.margin-right-40 { margin-right: 40px !important; }
.margin-right-45 { margin-right: 45px !important; }
.margin-right-50 { margin-right: 50px !important; }
.margin-right-55 { margin-right: 55px !important; }
.margin-right-60 { margin-right: 60px !important; }
.margin-right-65 { margin-right: 65px !important; }
.margin-right-70 { margin-right: 70px !important; }
.margin-right-75 { margin-right: 75px !important; }
.margin-right-80 { margin-right: 80px !important; }
.margin-right-85 { margin-right: 85px !important; }
.margin-right-100 { margin-right: 100px !important; }
.margin-right-105 { margin-right: 105px !important; }
.margin-right-110 { margin-right: 110px !important; }
.margin-right-115 { margin-right: 115px !important; }
.margin-right-120 { margin-right: 120px !important; }
.margin-right-125 { margin-right: 125px !important; }
.margin-right-130 { margin-right: 130px !important; }
.margin-right-135 { margin-right: 135px !important; }
.margin-right-140 { margin-right: 140px !important; }
.margin-right-145 { margin-right: 145px !important; }
.margin-right-150 { margin-right: 150px !important; }
.margin-right-155 { margin-right: 155px !important; }
.margin-right-160 { margin-right: 160px !important; }
.margin-right-165 { margin-right: 165px !important; }
.margin-right-170 { margin-right: 170px !important; }
.margin-right-175 { margin-right: 175px !important; }
.margin-right-180 { margin-right: 180px !important; }
.margin-right-185 { margin-right: 185px !important; }
.margin-right-190 { margin-right: 190px !important; }
.margin-right-195 { margin-right: 195px !important; }
.margin-right-200 { margin-right: 200px !important; }


/* Margin (percent) 
===================== */
.margin-1-p { margin: 1% !important; }
.margin-2-p { margin: 2% !important; }
.margin-3-p { margin: 3% !important; }
.margin-4-p { margin: 4% !important; }
.margin-5-p { margin: 5% !important; }
.margin-6-p { margin: 6% !important; }
.margin-7-p { margin: 7% !important; }
.margin-8-p { margin: 8% !important; }
.margin-9-p { margin: 9% !important; }
.margin-10-p { margin: 10% !important; }
.margin-11-p { margin: 11% !important; }
.margin-12-p { margin: 12% !important; }
.margin-13-p { margin: 13% !important; }
.margin-14-p { margin: 14% !important; }
.margin-15-p { margin: 15% !important; }
.margin-16-p { margin: 16% !important; }
.margin-17-p { margin: 17% !important; }
.margin-18-p { margin: 18% !important; }
.margin-19-p { margin: 19% !important; }
.margin-20-p { margin: 20% !important; }

/* margin top (percent) */
.margin-top-1-p { margin-top: 1% !important; }
.margin-top-2-p { margin-top: 2% !important; }
.margin-top-3-p { margin-top: 3% !important; }
.margin-top-4-p { margin-top: 4% !important; }
.margin-top-5-p { margin-top: 5% !important; }
.margin-top-6-p { margin-top: 6% !important; }
.margin-top-7-p { margin-top: 7% !important; }
.margin-top-8-p { margin-top: 8% !important; }
.margin-top-9-p { margin-top: 9% !important; }
.margin-top-10-p { margin-top: 10% !important; }
.margin-top-11-p { margin-top: 11% !important; }
.margin-top-12-p { margin-top: 12% !important; }
.margin-top-13-p { margin-top: 13% !important; }
.margin-top-14-p { margin-top: 14% !important; }
.margin-top-15-p { margin-top: 15% !important; }
.margin-top-16-p { margin-top: 16% !important; }
.margin-top-17-p { margin-top: 17% !important; }
.margin-top-18-p { margin-top: 18% !important; }
.margin-top-19-p { margin-top: 19% !important; }
.margin-top-20-p { margin-top: 20% !important; }

/* margin bottom (percent) */
.margin-bottom-1-p { margin-bottom: 1% !important; }
.margin-bottom-2-p { margin-bottom: 2% !important; }
.margin-bottom-3-p { margin-bottom: 3% !important; }
.margin-bottom-4-p { margin-bottom: 4% !important; }
.margin-bottom-5-p { margin-bottom: 5% !important; }
.margin-bottom-6-p { margin-bottom: 6% !important; }
.margin-bottom-7-p { margin-bottom: 7% !important; }
.margin-bottom-8-p { margin-bottom: 8% !important; }
.margin-bottom-9-p { margin-bottom: 9% !important; }
.margin-bottom-10-p { margin-bottom: 10% !important; }
.margin-bottom-11-p { margin-bottom: 11% !important; }
.margin-bottom-12-p { margin-bottom: 12% !important; }
.margin-bottom-13-p { margin-bottom: 13% !important; }
.margin-bottom-14-p { margin-bottom: 14% !important; }
.margin-bottom-15-p { margin-bottom: 15% !important; }
.margin-bottom-16-p { margin-bottom: 16% !important; }
.margin-bottom-17-p { margin-bottom: 17% !important; }
.margin-bottom-18-p { margin-bottom: 18% !important; }
.margin-bottom-19-p { margin-bottom: 19% !important; }
.margin-bottom-20-p { margin-bottom: 20% !important; }

/* margin left (percent) */
.margin-left-1-p { margin-left: 1% !important; }
.margin-left-2-p { margin-left: 2% !important; }
.margin-left-3-p { margin-left: 3% !important; }
.margin-left-4-p { margin-left: 4% !important; }
.margin-left-5-p { margin-left: 5% !important; }
.margin-left-6-p { margin-left: 6% !important; }
.margin-left-7-p { margin-left: 7% !important; }
.margin-left-8-p { margin-left: 8% !important; }
.margin-left-9-p { margin-left: 9% !important; }
.margin-left-10-p { margin-left: 10% !important; }
.margin-left-11-p { margin-left: 11% !important; }
.margin-left-12-p { margin-left: 12% !important; }
.margin-left-13-p { margin-left: 13% !important; }
.margin-left-14-p { margin-left: 14% !important; }
.margin-left-15-p { margin-left: 15% !important; }
.margin-left-16-p { margin-left: 16% !important; }
.margin-left-17-p { margin-left: 17% !important; }
.margin-left-18-p { margin-left: 18% !important; }
.margin-left-19-p { margin-left: 19% !important; }
.margin-left-20-p { margin-left: 20% !important; }

/* margin right (percent) */
.margin-right-1-p { margin-right: 1% !important; }
.margin-right-2-p { margin-right: 2% !important; }
.margin-right-3-p { margin-right: 3% !important; }
.margin-right-4-p { margin-right: 4% !important; }
.margin-right-5-p { margin-right: 5% !important; }
.margin-right-6-p { margin-right: 6% !important; }
.margin-right-7-p { margin-right: 7% !important; }
.margin-right-8-p { margin-right: 8% !important; }
.margin-right-9-p { margin-right: 9% !important; }
.margin-right-10-p { margin-right: 10% !important; }
.margin-right-11-p { margin-right: 11% !important; }
.margin-right-12-p { margin-right: 12% !important; }
.margin-right-13-p { margin-right: 13% !important; }
.margin-right-14-p { margin-right: 14% !important; }
.margin-right-15-p { margin-right: 15% !important; }
.margin-right-16-p { margin-right: 16% !important; }
.margin-right-17-p { margin-right: 17% !important; }
.margin-right-18-p { margin-right: 18% !important; }
.margin-right-19-p { margin-right: 19% !important; }
.margin-right-20-p { margin-right: 20% !important; }

/* no margins classes */
.no-margin { margin: 0 !important; }
.no-margin-top { margin-top: 0 !important; }
.no-margin-bottom { margin-bottom: 0 !important; }
.no-margin-left { margin-left: 0 !important; }
.no-margin-right { margin-right: 0 !important; }


/* Breakpoints 
=============== */
@media (min-width: 576px) {
	.margin-sm-5 { margin: 5px !important; }
	.margin-sm-10 { margin: 10px !important; }
	.margin-sm-15 { margin: 15px !important; }
	.margin-sm-20 { margin: 20px !important; }
	.margin-sm-25 { margin: 25px !important; }
	.margin-sm-30 { margin: 30px !important; }
	.margin-sm-35 { margin: 35px !important; }
	.margin-sm-40 { margin: 40px !important; }
	.margin-sm-45 { margin: 45px !important; }
	.margin-sm-50 { margin: 50px !important; }
	.margin-sm-55 { margin: 55px !important; }
	.margin-sm-60 { margin: 60px !important; }
	.margin-sm-65 { margin: 65px !important; }
	.margin-sm-70 { margin: 70px !important; }
	.margin-sm-75 { margin: 75px !important; }
	.margin-sm-80 { margin: 80px !important; }
	.margin-sm-85 { margin: 85px !important; }
	.margin-sm-100 { margin: 80px !important; }
	.margin-sm-105 { margin: 105px !important; }
	.margin-sm-110 { margin: 110px !important; }
	.margin-sm-115 { margin: 115px !important; }
	.margin-sm-120 { margin: 120px !important; }
	.margin-sm-125 { margin: 125px !important; }
	.margin-sm-130 { margin: 130px !important; }
	.margin-sm-135 { margin: 135px !important; }
	.margin-sm-140 { margin: 140px !important; }
	.margin-sm-145 { margin: 145px !important; }
	.margin-sm-150 { margin: 150px !important; }
	.margin-sm-155 { margin: 155px !important; }
	.margin-sm-160 { margin: 160px !important; }
	.margin-sm-165 { margin: 165px !important; }
	.margin-sm-170 { margin: 170px !important; }
	.margin-sm-175 { margin: 175px !important; }
	.margin-sm-180 { margin: 180px !important; }
	.margin-sm-185 { margin: 185px !important; }
	.margin-sm-190 { margin: 190px !important; }
	.margin-sm-195 { margin: 195px !important; }
	.margin-sm-200 { margin: 200px !important; }

	/* margin top */
	.margin-top-sm-5 { margin-top: 5px !important; }
	.margin-top-sm-10 { margin-top: 10px !important; }
	.margin-top-sm-15 { margin-top: 15px !important; }
	.margin-top-sm-20 { margin-top: 20px !important; }
	.margin-top-sm-25 { margin-top: 25px !important; }
	.margin-top-sm-30 { margin-top: 30px !important; }
	.margin-top-sm-35 { margin-top: 35px !important; }
	.margin-top-sm-40 { margin-top: 40px !important; }
	.margin-top-sm-45 { margin-top: 45px !important; }
	.margin-top-sm-50 { margin-top: 50px !important; }
	.margin-top-sm-55 { margin-top: 55px !important; }
	.margin-top-sm-60 { margin-top: 60px !important; }
	.margin-top-sm-65 { margin-top: 65px !important; }
	.margin-top-sm-70 { margin-top: 70px !important; }
	.margin-top-sm-75 { margin-top: 75px !important; }
	.margin-top-sm-80 { margin-top: 80px !important; }
	.margin-top-sm-85 { margin-top: 85px !important; }
	.margin-top-sm-100 { margin-top: 100px !important; }
	.margin-top-sm-105 { margin-top: 105px !important; }
	.margin-top-sm-110 { margin-top: 110px !important; }
	.margin-top-sm-115 { margin-top: 115px !important; }
	.margin-top-sm-120 { margin-top: 120px !important; }
	.margin-top-sm-125 { margin-top: 125px !important; }
	.margin-top-sm-130 { margin-top: 130px !important; }
	.margin-top-sm-135 { margin-top: 135px !important; }
	.margin-top-sm-140 { margin-top: 140px !important; }
	.margin-top-sm-145 { margin-top: 145px !important; }
	.margin-top-sm-150 { margin-top: 150px !important; }
	.margin-top-sm-155 { margin-top: 155px !important; }
	.margin-top-sm-160 { margin-top: 160px !important; }
	.margin-top-sm-165 { margin-top: 165px !important; }
	.margin-top-sm-170 { margin-top: 170px !important; }
	.margin-top-sm-175 { margin-top: 175px !important; }
	.margin-top-sm-180 { margin-top: 180px !important; }
	.margin-top-sm-185 { margin-top: 185px !important; }
	.margin-top-sm-190 { margin-top: 190px !important; }
	.margin-top-sm-195 { margin-top: 195px !important; }
	.margin-top-sm-200 { margin-top: 200px !important; }

	/* margin bottom */
	.margin-bottom-sm-5 { margin-bottom: 5px !important; }
	.margin-bottom-sm-10 { margin-bottom: 10px !important; }
	.margin-bottom-sm-15 { margin-bottom: 15px !important; }
	.margin-bottom-sm-20 { margin-bottom: 20px !important; }
	.margin-bottom-sm-25 { margin-bottom: 25px !important; }
	.margin-bottom-sm-30 { margin-bottom: 30px !important; }
	.margin-bottom-sm-35 { margin-bottom: 35px !important; }
	.margin-bottom-sm-40 { margin-bottom: 40px !important; }
	.margin-bottom-sm-45 { margin-bottom: 45px !important; }
	.margin-bottom-sm-50 { margin-bottom: 50px !important; }
	.margin-bottom-sm-55 { margin-bottom: 55px !important; }
	.margin-bottom-sm-60 { margin-bottom: 60px !important; }
	.margin-bottom-sm-65 { margin-bottom: 65px !important; }
	.margin-bottom-sm-70 { margin-bottom: 70px !important; }
	.margin-bottom-sm-75 { margin-bottom: 75px !important; }
	.margin-bottom-sm-80 { margin-bottom: 80px !important; }
	.margin-bottom-sm-85 { margin-bottom: 85px !important; }
	.margin-bottom-sm-100 { margin-bottom: 100px !important; }
	.margin-bottom-sm-105 { margin-bottom: 105px !important; }
	.margin-bottom-sm-110 { margin-bottom: 110px !important; }
	.margin-bottom-sm-115 { margin-bottom: 115px !important; }
	.margin-bottom-sm-120 { margin-bottom: 120px !important; }
	.margin-bottom-sm-125 { margin-bottom: 125px !important; }
	.margin-bottom-sm-130 { margin-bottom: 130px !important; }
	.margin-bottom-sm-135 { margin-bottom: 135px !important; }
	.margin-bottom-sm-140 { margin-bottom: 140px !important; }
	.margin-bottom-sm-145 { margin-bottom: 145px !important; }
	.margin-bottom-sm-150 { margin-bottom: 150px !important; }
	.margin-bottom-sm-155 { margin-bottom: 155px !important; }
	.margin-bottom-sm-160 { margin-bottom: 160px !important; }
	.margin-bottom-sm-165 { margin-bottom: 165px !important; }
	.margin-bottom-sm-170 { margin-bottom: 170px !important; }
	.margin-bottom-sm-175 { margin-bottom: 175px !important; }
	.margin-bottom-sm-180 { margin-bottom: 180px !important; }
	.margin-bottom-sm-185 { margin-bottom: 185px !important; }
	.margin-bottom-sm-190 { margin-bottom: 190px !important; }
	.margin-bottom-sm-195 { margin-bottom: 195px !important; }
	.margin-bottom-sm-200 { margin-bottom: 200px !important; }

	/* margin left */
	.margin-left-sm-5 { margin-left: 5px !important; }
	.margin-left-sm-10 { margin-left: 10px !important; }
	.margin-left-sm-15 { margin-left: 15px !important; }
	.margin-left-sm-20 { margin-left: 20px !important; }
	.margin-left-sm-25 { margin-left: 25px !important; }
	.margin-left-sm-30 { margin-left: 30px !important; }
	.margin-left-sm-35 { margin-left: 35px !important; }
	.margin-left-sm-40 { margin-left: 40px !important; }
	.margin-left-sm-45 { margin-left: 45px !important; }
	.margin-left-sm-50 { margin-left: 50px !important; }
	.margin-left-sm-55 { margin-left: 55px !important; }
	.margin-left-sm-60 { margin-left: 60px !important; }
	.margin-left-sm-65 { margin-left: 65px !important; }
	.margin-left-sm-70 { margin-left: 70px !important; }
	.margin-left-sm-75 { margin-left: 75px !important; }
	.margin-left-sm-80 { margin-left: 80px !important; }
	.margin-left-sm-85 { margin-left: 85px !important; }
	.margin-left-sm-100 { margin-left: 100px !important; }
	.margin-left-sm-105 { margin-left: 105px !important; }
	.margin-left-sm-110 { margin-left: 110px !important; }
	.margin-left-sm-115 { margin-left: 115px !important; }
	.margin-left-sm-120 { margin-left: 120px !important; }
	.margin-left-sm-125 { margin-left: 125px !important; }
	.margin-left-sm-130 { margin-left: 130px !important; }
	.margin-left-sm-135 { margin-left: 135px !important; }
	.margin-left-sm-140 { margin-left: 140px !important; }
	.margin-left-sm-145 { margin-left: 145px !important; }
	.margin-left-sm-150 { margin-left: 150px !important; }
	.margin-left-sm-155 { margin-left: 155px !important; }
	.margin-left-sm-160 { margin-left: 160px !important; }
	.margin-left-sm-165 { margin-left: 165px !important; }
	.margin-left-sm-170 { margin-left: 170px !important; }
	.margin-left-sm-175 { margin-left: 175px !important; }
	.margin-left-sm-180 { margin-left: 180px !important; }
	.margin-left-sm-185 { margin-left: 185px !important; }
	.margin-left-sm-190 { margin-left: 190px !important; }
	.margin-left-sm-195 { margin-left: 195px !important; }
	.margin-left-sm-200 { margin-left: 200px !important; }

	/* margin right */
	.margin-right-sm-5 { margin-right: 5px !important; }
	.margin-right-sm-10 { margin-right: 10px !important; }
	.margin-right-sm-15 { margin-right: 15px !important; }
	.margin-right-sm-20 { margin-right: 20px !important; }
	.margin-right-sm-25 { margin-right: 25px !important; }
	.margin-right-sm-30 { margin-right: 30px !important; }
	.margin-right-sm-35 { margin-right: 35px !important; }
	.margin-right-sm-40 { margin-right: 40px !important; }
	.margin-right-sm-45 { margin-right: 45px !important; }
	.margin-right-sm-50 { margin-right: 50px !important; }
	.margin-right-sm-55 { margin-right: 55px !important; }
	.margin-right-sm-60 { margin-right: 60px !important; }
	.margin-right-sm-65 { margin-right: 65px !important; }
	.margin-right-sm-70 { margin-right: 70px !important; }
	.margin-right-sm-75 { margin-right: 75px !important; }
	.margin-right-sm-80 { margin-right: 80px !important; }
	.margin-right-sm-85 { margin-right: 85px !important; }
	.margin-right-sm-100 { margin-right: 100px !important; }
	.margin-right-sm-105 { margin-right: 105px !important; }
	.margin-right-sm-110 { margin-right: 110px !important; }
	.margin-right-sm-115 { margin-right: 115px !important; }
	.margin-right-sm-120 { margin-right: 120px !important; }
	.margin-right-sm-125 { margin-right: 125px !important; }
	.margin-right-sm-130 { margin-right: 130px !important; }
	.margin-right-sm-135 { margin-right: 135px !important; }
	.margin-right-sm-140 { margin-right: 140px !important; }
	.margin-right-sm-145 { margin-right: 145px !important; }
	.margin-right-sm-150 { margin-right: 150px !important; }
	.margin-right-sm-155 { margin-right: 155px !important; }
	.margin-right-sm-160 { margin-right: 160px !important; }
	.margin-right-sm-165 { margin-right: 165px !important; }
	.margin-right-sm-170 { margin-right: 170px !important; }
	.margin-right-sm-175 { margin-right: 175px !important; }
	.margin-right-sm-180 { margin-right: 180px !important; }
	.margin-right-sm-185 { margin-right: 185px !important; }
	.margin-right-sm-190 { margin-right: 190px !important; }
	.margin-right-sm-195 { margin-right: 195px !important; }
	.margin-right-sm-200 { margin-right: 200px !important; }


	/* Margin (percent) 
	===================== */
	.margin-sm-1-p { margin: 1% !important; }
	.margin-sm-2-p { margin: 2% !important; }
	.margin-sm-3-p { margin: 3% !important; }
	.margin-sm-4-p { margin: 4% !important; }
	.margin-sm-5-p { margin: 5% !important; }
	.margin-sm-6-p { margin: 6% !important; }
	.margin-sm-7-p { margin: 7% !important; }
	.margin-sm-8-p { margin: 8% !important; }
	.margin-sm-9-p { margin: 9% !important; }
	.margin-sm-10-p { margin: 10% !important; }
	.margin-sm-11-p { margin: 11% !important; }
	.margin-sm-12-p { margin: 12% !important; }
	.margin-sm-13-p { margin: 13% !important; }
	.margin-sm-14-p { margin: 14% !important; }
	.margin-sm-15-p { margin: 15% !important; }
	.margin-sm-16-p { margin: 16% !important; }
	.margin-sm-17-p { margin: 17% !important; }
	.margin-sm-18-p { margin: 18% !important; }
	.margin-sm-19-p { margin: 19% !important; }
	.margin-sm-20-p { margin: 20% !important; }

	/* margin top (percent) */
	.margin-top-sm-1-p { margin-top: 1% !important; }
	.margin-top-sm-2-p { margin-top: 2% !important; }
	.margin-top-sm-3-p { margin-top: 3% !important; }
	.margin-top-sm-4-p { margin-top: 4% !important; }
	.margin-top-sm-5-p { margin-top: 5% !important; }
	.margin-top-sm-6-p { margin-top: 6% !important; }
	.margin-top-sm-7-p { margin-top: 7% !important; }
	.margin-top-sm-8-p { margin-top: 8% !important; }
	.margin-top-sm-9-p { margin-top: 9% !important; }
	.margin-top-sm-10-p { margin-top: 10% !important; }
	.margin-top-sm-11-p { margin-top: 11% !important; }
	.margin-top-sm-12-p { margin-top: 12% !important; }
	.margin-top-sm-13-p { margin-top: 13% !important; }
	.margin-top-sm-14-p { margin-top: 14% !important; }
	.margin-top-sm-15-p { margin-top: 15% !important; }
	.margin-top-sm-16-p { margin-top: 16% !important; }
	.margin-top-sm-17-p { margin-top: 17% !important; }
	.margin-top-sm-18-p { margin-top: 18% !important; }
	.margin-top-sm-19-p { margin-top: 19% !important; }
	.margin-top-sm-20-p { margin-top: 20% !important; }

	/* margin bottom (percent) */
	.margin-bottom-sm-1-p { margin-bottom: 1% !important; }
	.margin-bottom-sm-2-p { margin-bottom: 2% !important; }
	.margin-bottom-sm-3-p { margin-bottom: 3% !important; }
	.margin-bottom-sm-4-p { margin-bottom: 4% !important; }
	.margin-bottom-sm-5-p { margin-bottom: 5% !important; }
	.margin-bottom-sm-6-p { margin-bottom: 6% !important; }
	.margin-bottom-sm-7-p { margin-bottom: 7% !important; }
	.margin-bottom-sm-8-p { margin-bottom: 8% !important; }
	.margin-bottom-sm-9-p { margin-bottom: 9% !important; }
	.margin-bottom-sm-10-p { margin-bottom: 10% !important; }
	.margin-bottom-sm-11-p { margin-bottom: 11% !important; }
	.margin-bottom-sm-12-p { margin-bottom: 12% !important; }
	.margin-bottom-sm-13-p { margin-bottom: 13% !important; }
	.margin-bottom-sm-14-p { margin-bottom: 14% !important; }
	.margin-bottom-sm-15-p { margin-bottom: 15% !important; }
	.margin-bottom-sm-16-p { margin-bottom: 16% !important; }
	.margin-bottom-sm-17-p { margin-bottom: 17% !important; }
	.margin-bottom-sm-18-p { margin-bottom: 18% !important; }
	.margin-bottom-sm-19-p { margin-bottom: 19% !important; }
	.margin-bottom-sm-20-p { margin-bottom: 20% !important; }

	/* margin left (percent) */
	.margin-left-sm-1-p { margin-left: 1% !important; }
	.margin-left-sm-2-p { margin-left: 2% !important; }
	.margin-left-sm-3-p { margin-left: 3% !important; }
	.margin-left-sm-4-p { margin-left: 4% !important; }
	.margin-left-sm-5-p { margin-left: 5% !important; }
	.margin-left-sm-6-p { margin-left: 6% !important; }
	.margin-left-sm-7-p { margin-left: 7% !important; }
	.margin-left-sm-8-p { margin-left: 8% !important; }
	.margin-left-sm-9-p { margin-left: 9% !important; }
	.margin-left-sm-10-p { margin-left: 10% !important; }
	.margin-left-sm-11-p { margin-left: 11% !important; }
	.margin-left-sm-12-p { margin-left: 12% !important; }
	.margin-left-sm-13-p { margin-left: 13% !important; }
	.margin-left-sm-14-p { margin-left: 14% !important; }
	.margin-left-sm-15-p { margin-left: 15% !important; }
	.margin-left-sm-16-p { margin-left: 16% !important; }
	.margin-left-sm-17-p { margin-left: 17% !important; }
	.margin-left-sm-18-p { margin-left: 18% !important; }
	.margin-left-sm-19-p { margin-left: 19% !important; }
	.margin-left-sm-20-p { margin-left: 20% !important; }

	/* margin right (percent) */
	.margin-right-sm-1-p { margin-right: 1% !important; }
	.margin-right-sm-2-p { margin-right: 2% !important; }
	.margin-right-sm-3-p { margin-right: 3% !important; }
	.margin-right-sm-4-p { margin-right: 4% !important; }
	.margin-right-sm-5-p { margin-right: 5% !important; }
	.margin-right-sm-6-p { margin-right: 6% !important; }
	.margin-right-sm-7-p { margin-right: 7% !important; }
	.margin-right-sm-8-p { margin-right: 8% !important; }
	.margin-right-sm-9-p { margin-right: 9% !important; }
	.margin-right-sm-10-p { margin-right: 10% !important; }
	.margin-right-sm-11-p { margin-right: 11% !important; }
	.margin-right-sm-12-p { margin-right: 12% !important; }
	.margin-right-sm-13-p { margin-right: 13% !important; }
	.margin-right-sm-14-p { margin-right: 14% !important; }
	.margin-right-sm-15-p { margin-right: 15% !important; }
	.margin-right-sm-16-p { margin-right: 16% !important; }
	.margin-right-sm-17-p { margin-right: 17% !important; }
	.margin-right-sm-18-p { margin-right: 18% !important; }
	.margin-right-sm-19-p { margin-right: 19% !important; }
	.margin-right-sm-20-p { margin-right: 20% !important; }

	/* no margins classes */
	.no-margin-sm { margin: 0 !important; }
	.no-margin-sm-top { margin-top: 0 !important; }
	.no-margin-sm-bottom { margin-bottom: 0 !important; }
	.no-margin-sm-left { margin-left: 0 !important; }
	.no-margin-sm-right { margin-right: 0 !important; }
}
@media (min-width: 768px) {
	.margin-md-5 { margin: 5px !important; }
	.margin-md-10 { margin: 10px !important; }
	.margin-md-15 { margin: 15px !important; }
	.margin-md-20 { margin: 20px !important; }
	.margin-md-25 { margin: 25px !important; }
	.margin-md-30 { margin: 30px !important; }
	.margin-md-35 { margin: 35px !important; }
	.margin-md-40 { margin: 40px !important; }
	.margin-md-45 { margin: 45px !important; }
	.margin-md-50 { margin: 50px !important; }
	.margin-md-55 { margin: 55px !important; }
	.margin-md-60 { margin: 60px !important; }
	.margin-md-65 { margin: 65px !important; }
	.margin-md-70 { margin: 70px !important; }
	.margin-md-75 { margin: 75px !important; }
	.margin-md-80 { margin: 80px !important; }
	.margin-md-85 { margin: 85px !important; }
	.margin-md-100 { margin: 80px !important; }
	.margin-md-105 { margin: 105px !important; }
	.margin-md-110 { margin: 110px !important; }
	.margin-md-115 { margin: 115px !important; }
	.margin-md-120 { margin: 120px !important; }
	.margin-md-125 { margin: 125px !important; }
	.margin-md-130 { margin: 130px !important; }
	.margin-md-135 { margin: 135px !important; }
	.margin-md-140 { margin: 140px !important; }
	.margin-md-145 { margin: 145px !important; }
	.margin-md-150 { margin: 150px !important; }
	.margin-md-155 { margin: 155px !important; }
	.margin-md-160 { margin: 160px !important; }
	.margin-md-165 { margin: 165px !important; }
	.margin-md-170 { margin: 170px !important; }
	.margin-md-175 { margin: 175px !important; }
	.margin-md-180 { margin: 180px !important; }
	.margin-md-185 { margin: 185px !important; }
	.margin-md-190 { margin: 190px !important; }
	.margin-md-195 { margin: 195px !important; }
	.margin-md-200 { margin: 200px !important; }

	/* margin top */
	.margin-top-md-5 { margin-top: 5px !important; }
	.margin-top-md-10 { margin-top: 10px !important; }
	.margin-top-md-15 { margin-top: 15px !important; }
	.margin-top-md-20 { margin-top: 20px !important; }
	.margin-top-md-25 { margin-top: 25px !important; }
	.margin-top-md-30 { margin-top: 30px !important; }
	.margin-top-md-35 { margin-top: 35px !important; }
	.margin-top-md-40 { margin-top: 40px !important; }
	.margin-top-md-45 { margin-top: 45px !important; }
	.margin-top-md-50 { margin-top: 50px !important; }
	.margin-top-md-55 { margin-top: 55px !important; }
	.margin-top-md-60 { margin-top: 60px !important; }
	.margin-top-md-65 { margin-top: 65px !important; }
	.margin-top-md-70 { margin-top: 70px !important; }
	.margin-top-md-75 { margin-top: 75px !important; }
	.margin-top-md-80 { margin-top: 80px !important; }
	.margin-top-md-85 { margin-top: 85px !important; }
	.margin-top-md-100 { margin-top: 100px !important; }
	.margin-top-md-105 { margin-top: 105px !important; }
	.margin-top-md-110 { margin-top: 110px !important; }
	.margin-top-md-115 { margin-top: 115px !important; }
	.margin-top-md-120 { margin-top: 120px !important; }
	.margin-top-md-125 { margin-top: 125px !important; }
	.margin-top-md-130 { margin-top: 130px !important; }
	.margin-top-md-135 { margin-top: 135px !important; }
	.margin-top-md-140 { margin-top: 140px !important; }
	.margin-top-md-145 { margin-top: 145px !important; }
	.margin-top-md-150 { margin-top: 150px !important; }
	.margin-top-md-155 { margin-top: 155px !important; }
	.margin-top-md-160 { margin-top: 160px !important; }
	.margin-top-md-165 { margin-top: 165px !important; }
	.margin-top-md-170 { margin-top: 170px !important; }
	.margin-top-md-175 { margin-top: 175px !important; }
	.margin-top-md-180 { margin-top: 180px !important; }
	.margin-top-md-185 { margin-top: 185px !important; }
	.margin-top-md-190 { margin-top: 190px !important; }
	.margin-top-md-195 { margin-top: 195px !important; }
	.margin-top-md-200 { margin-top: 200px !important; }

	/* margin bottom */
	.margin-bottom-md-5 { margin-bottom: 5px !important; }
	.margin-bottom-md-10 { margin-bottom: 10px !important; }
	.margin-bottom-md-15 { margin-bottom: 15px !important; }
	.margin-bottom-md-20 { margin-bottom: 20px !important; }
	.margin-bottom-md-25 { margin-bottom: 25px !important; }
	.margin-bottom-md-30 { margin-bottom: 30px !important; }
	.margin-bottom-md-35 { margin-bottom: 35px !important; }
	.margin-bottom-md-40 { margin-bottom: 40px !important; }
	.margin-bottom-md-45 { margin-bottom: 45px !important; }
	.margin-bottom-md-50 { margin-bottom: 50px !important; }
	.margin-bottom-md-55 { margin-bottom: 55px !important; }
	.margin-bottom-md-60 { margin-bottom: 60px !important; }
	.margin-bottom-md-65 { margin-bottom: 65px !important; }
	.margin-bottom-md-70 { margin-bottom: 70px !important; }
	.margin-bottom-md-75 { margin-bottom: 75px !important; }
	.margin-bottom-md-80 { margin-bottom: 80px !important; }
	.margin-bottom-md-85 { margin-bottom: 85px !important; }
	.margin-bottom-md-100 { margin-bottom: 100px !important; }
	.margin-bottom-md-105 { margin-bottom: 105px !important; }
	.margin-bottom-md-110 { margin-bottom: 110px !important; }
	.margin-bottom-md-115 { margin-bottom: 115px !important; }
	.margin-bottom-md-120 { margin-bottom: 120px !important; }
	.margin-bottom-md-125 { margin-bottom: 125px !important; }
	.margin-bottom-md-130 { margin-bottom: 130px !important; }
	.margin-bottom-md-135 { margin-bottom: 135px !important; }
	.margin-bottom-md-140 { margin-bottom: 140px !important; }
	.margin-bottom-md-145 { margin-bottom: 145px !important; }
	.margin-bottom-md-150 { margin-bottom: 150px !important; }
	.margin-bottom-md-155 { margin-bottom: 155px !important; }
	.margin-bottom-md-160 { margin-bottom: 160px !important; }
	.margin-bottom-md-165 { margin-bottom: 165px !important; }
	.margin-bottom-md-170 { margin-bottom: 170px !important; }
	.margin-bottom-md-175 { margin-bottom: 175px !important; }
	.margin-bottom-md-180 { margin-bottom: 180px !important; }
	.margin-bottom-md-185 { margin-bottom: 185px !important; }
	.margin-bottom-md-190 { margin-bottom: 190px !important; }
	.margin-bottom-md-195 { margin-bottom: 195px !important; }
	.margin-bottom-md-200 { margin-bottom: 200px !important; }

	/* margin left */
	.margin-left-md-5 { margin-left: 5px !important; }
	.margin-left-md-10 { margin-left: 10px !important; }
	.margin-left-md-15 { margin-left: 15px !important; }
	.margin-left-md-20 { margin-left: 20px !important; }
	.margin-left-md-25 { margin-left: 25px !important; }
	.margin-left-md-30 { margin-left: 30px !important; }
	.margin-left-md-35 { margin-left: 35px !important; }
	.margin-left-md-40 { margin-left: 40px !important; }
	.margin-left-md-45 { margin-left: 45px !important; }
	.margin-left-md-50 { margin-left: 50px !important; }
	.margin-left-md-55 { margin-left: 55px !important; }
	.margin-left-md-60 { margin-left: 60px !important; }
	.margin-left-md-65 { margin-left: 65px !important; }
	.margin-left-md-70 { margin-left: 70px !important; }
	.margin-left-md-75 { margin-left: 75px !important; }
	.margin-left-md-80 { margin-left: 80px !important; }
	.margin-left-md-85 { margin-left: 85px !important; }
	.margin-left-md-100 { margin-left: 100px !important; }
	.margin-left-md-105 { margin-left: 105px !important; }
	.margin-left-md-110 { margin-left: 110px !important; }
	.margin-left-md-115 { margin-left: 115px !important; }
	.margin-left-md-120 { margin-left: 120px !important; }
	.margin-left-md-125 { margin-left: 125px !important; }
	.margin-left-md-130 { margin-left: 130px !important; }
	.margin-left-md-135 { margin-left: 135px !important; }
	.margin-left-md-140 { margin-left: 140px !important; }
	.margin-left-md-145 { margin-left: 145px !important; }
	.margin-left-md-150 { margin-left: 150px !important; }
	.margin-left-md-155 { margin-left: 155px !important; }
	.margin-left-md-160 { margin-left: 160px !important; }
	.margin-left-md-165 { margin-left: 165px !important; }
	.margin-left-md-170 { margin-left: 170px !important; }
	.margin-left-md-175 { margin-left: 175px !important; }
	.margin-left-md-180 { margin-left: 180px !important; }
	.margin-left-md-185 { margin-left: 185px !important; }
	.margin-left-md-190 { margin-left: 190px !important; }
	.margin-left-md-195 { margin-left: 195px !important; }
	.margin-left-md-200 { margin-left: 200px !important; }

	/* margin right */
	.margin-right-md-5 { margin-right: 5px !important; }
	.margin-right-md-10 { margin-right: 10px !important; }
	.margin-right-md-15 { margin-right: 15px !important; }
	.margin-right-md-20 { margin-right: 20px !important; }
	.margin-right-md-25 { margin-right: 25px !important; }
	.margin-right-md-30 { margin-right: 30px !important; }
	.margin-right-md-35 { margin-right: 35px !important; }
	.margin-right-md-40 { margin-right: 40px !important; }
	.margin-right-md-45 { margin-right: 45px !important; }
	.margin-right-md-50 { margin-right: 50px !important; }
	.margin-right-md-55 { margin-right: 55px !important; }
	.margin-right-md-60 { margin-right: 60px !important; }
	.margin-right-md-65 { margin-right: 65px !important; }
	.margin-right-md-70 { margin-right: 70px !important; }
	.margin-right-md-75 { margin-right: 75px !important; }
	.margin-right-md-80 { margin-right: 80px !important; }
	.margin-right-md-85 { margin-right: 85px !important; }
	.margin-right-md-100 { margin-right: 100px !important; }
	.margin-right-md-105 { margin-right: 105px !important; }
	.margin-right-md-110 { margin-right: 110px !important; }
	.margin-right-md-115 { margin-right: 115px !important; }
	.margin-right-md-120 { margin-right: 120px !important; }
	.margin-right-md-125 { margin-right: 125px !important; }
	.margin-right-md-130 { margin-right: 130px !important; }
	.margin-right-md-135 { margin-right: 135px !important; }
	.margin-right-md-140 { margin-right: 140px !important; }
	.margin-right-md-145 { margin-right: 145px !important; }
	.margin-right-md-150 { margin-right: 150px !important; }
	.margin-right-md-155 { margin-right: 155px !important; }
	.margin-right-md-160 { margin-right: 160px !important; }
	.margin-right-md-165 { margin-right: 165px !important; }
	.margin-right-md-170 { margin-right: 170px !important; }
	.margin-right-md-175 { margin-right: 175px !important; }
	.margin-right-md-180 { margin-right: 180px !important; }
	.margin-right-md-185 { margin-right: 185px !important; }
	.margin-right-md-190 { margin-right: 190px !important; }
	.margin-right-md-195 { margin-right: 195px !important; }
	.margin-right-md-200 { margin-right: 200px !important; }


	/* Margin (percent) 
	===================== */
	.margin-md-1-p { margin: 1% !important; }
	.margin-md-2-p { margin: 2% !important; }
	.margin-md-3-p { margin: 3% !important; }
	.margin-md-4-p { margin: 4% !important; }
	.margin-md-5-p { margin: 5% !important; }
	.margin-md-6-p { margin: 6% !important; }
	.margin-md-7-p { margin: 7% !important; }
	.margin-md-8-p { margin: 8% !important; }
	.margin-md-9-p { margin: 9% !important; }
	.margin-md-10-p { margin: 10% !important; }
	.margin-md-11-p { margin: 11% !important; }
	.margin-md-12-p { margin: 12% !important; }
	.margin-md-13-p { margin: 13% !important; }
	.margin-md-14-p { margin: 14% !important; }
	.margin-md-15-p { margin: 15% !important; }
	.margin-md-16-p { margin: 16% !important; }
	.margin-md-17-p { margin: 17% !important; }
	.margin-md-18-p { margin: 18% !important; }
	.margin-md-19-p { margin: 19% !important; }
	.margin-md-20-p { margin: 20% !important; }

	/* margin top (percent) */
	.margin-top-md-1-p { margin-top: 1% !important; }
	.margin-top-md-2-p { margin-top: 2% !important; }
	.margin-top-md-3-p { margin-top: 3% !important; }
	.margin-top-md-4-p { margin-top: 4% !important; }
	.margin-top-md-5-p { margin-top: 5% !important; }
	.margin-top-md-6-p { margin-top: 6% !important; }
	.margin-top-md-7-p { margin-top: 7% !important; }
	.margin-top-md-8-p { margin-top: 8% !important; }
	.margin-top-md-9-p { margin-top: 9% !important; }
	.margin-top-md-10-p { margin-top: 10% !important; }
	.margin-top-md-11-p { margin-top: 11% !important; }
	.margin-top-md-12-p { margin-top: 12% !important; }
	.margin-top-md-13-p { margin-top: 13% !important; }
	.margin-top-md-14-p { margin-top: 14% !important; }
	.margin-top-md-15-p { margin-top: 15% !important; }
	.margin-top-md-16-p { margin-top: 16% !important; }
	.margin-top-md-17-p { margin-top: 17% !important; }
	.margin-top-md-18-p { margin-top: 18% !important; }
	.margin-top-md-19-p { margin-top: 19% !important; }
	.margin-top-md-20-p { margin-top: 20% !important; }

	/* margin bottom (percent) */
	.margin-bottom-md-1-p { margin-bottom: 1% !important; }
	.margin-bottom-md-2-p { margin-bottom: 2% !important; }
	.margin-bottom-md-3-p { margin-bottom: 3% !important; }
	.margin-bottom-md-4-p { margin-bottom: 4% !important; }
	.margin-bottom-md-5-p { margin-bottom: 5% !important; }
	.margin-bottom-md-6-p { margin-bottom: 6% !important; }
	.margin-bottom-md-7-p { margin-bottom: 7% !important; }
	.margin-bottom-md-8-p { margin-bottom: 8% !important; }
	.margin-bottom-md-9-p { margin-bottom: 9% !important; }
	.margin-bottom-md-10-p { margin-bottom: 10% !important; }
	.margin-bottom-md-11-p { margin-bottom: 11% !important; }
	.margin-bottom-md-12-p { margin-bottom: 12% !important; }
	.margin-bottom-md-13-p { margin-bottom: 13% !important; }
	.margin-bottom-md-14-p { margin-bottom: 14% !important; }
	.margin-bottom-md-15-p { margin-bottom: 15% !important; }
	.margin-bottom-md-16-p { margin-bottom: 16% !important; }
	.margin-bottom-md-17-p { margin-bottom: 17% !important; }
	.margin-bottom-md-18-p { margin-bottom: 18% !important; }
	.margin-bottom-md-19-p { margin-bottom: 19% !important; }
	.margin-bottom-md-20-p { margin-bottom: 20% !important; }

	/* margin left (percent) */
	.margin-left-md-1-p { margin-left: 1% !important; }
	.margin-left-md-2-p { margin-left: 2% !important; }
	.margin-left-md-3-p { margin-left: 3% !important; }
	.margin-left-md-4-p { margin-left: 4% !important; }
	.margin-left-md-5-p { margin-left: 5% !important; }
	.margin-left-md-6-p { margin-left: 6% !important; }
	.margin-left-md-7-p { margin-left: 7% !important; }
	.margin-left-md-8-p { margin-left: 8% !important; }
	.margin-left-md-9-p { margin-left: 9% !important; }
	.margin-left-md-10-p { margin-left: 10% !important; }
	.margin-left-md-11-p { margin-left: 11% !important; }
	.margin-left-md-12-p { margin-left: 12% !important; }
	.margin-left-md-13-p { margin-left: 13% !important; }
	.margin-left-md-14-p { margin-left: 14% !important; }
	.margin-left-md-15-p { margin-left: 15% !important; }
	.margin-left-md-16-p { margin-left: 16% !important; }
	.margin-left-md-17-p { margin-left: 17% !important; }
	.margin-left-md-18-p { margin-left: 18% !important; }
	.margin-left-md-19-p { margin-left: 19% !important; }
	.margin-left-md-20-p { margin-left: 20% !important; }

	/* margin right (percent) */
	.margin-right-md-1-p { margin-right: 1% !important; }
	.margin-right-md-2-p { margin-right: 2% !important; }
	.margin-right-md-3-p { margin-right: 3% !important; }
	.margin-right-md-4-p { margin-right: 4% !important; }
	.margin-right-md-5-p { margin-right: 5% !important; }
	.margin-right-md-6-p { margin-right: 6% !important; }
	.margin-right-md-7-p { margin-right: 7% !important; }
	.margin-right-md-8-p { margin-right: 8% !important; }
	.margin-right-md-9-p { margin-right: 9% !important; }
	.margin-right-md-10-p { margin-right: 10% !important; }
	.margin-right-md-11-p { margin-right: 11% !important; }
	.margin-right-md-12-p { margin-right: 12% !important; }
	.margin-right-md-13-p { margin-right: 13% !important; }
	.margin-right-md-14-p { margin-right: 14% !important; }
	.margin-right-md-15-p { margin-right: 15% !important; }
	.margin-right-md-16-p { margin-right: 16% !important; }
	.margin-right-md-17-p { margin-right: 17% !important; }
	.margin-right-md-18-p { margin-right: 18% !important; }
	.margin-right-md-19-p { margin-right: 19% !important; }
	.margin-right-md-20-p { margin-right: 20% !important; }

	/* no margins classes */
	.no-margin-md { margin: 0 !important; }
	.no-margin-md-top { margin-top: 0 !important; }
	.no-margin-md-bottom { margin-bottom: 0 !important; }
	.no-margin-md-left { margin-left: 0 !important; }
	.no-margin-md-right { margin-right: 0 !important; }
}
@media (min-width: 992px) {
	.margin-lg-5 { margin: 5px !important; }
	.margin-lg-10 { margin: 10px !important; }
	.margin-lg-15 { margin: 15px !important; }
	.margin-lg-20 { margin: 20px !important; }
	.margin-lg-25 { margin: 25px !important; }
	.margin-lg-30 { margin: 30px !important; }
	.margin-lg-35 { margin: 35px !important; }
	.margin-lg-40 { margin: 40px !important; }
	.margin-lg-45 { margin: 45px !important; }
	.margin-lg-50 { margin: 50px !important; }
	.margin-lg-55 { margin: 55px !important; }
	.margin-lg-60 { margin: 60px !important; }
	.margin-lg-65 { margin: 65px !important; }
	.margin-lg-70 { margin: 70px !important; }
	.margin-lg-75 { margin: 75px !important; }
	.margin-lg-80 { margin: 80px !important; }
	.margin-lg-85 { margin: 85px !important; }
	.margin-lg-100 { margin: 80px !important; }
	.margin-lg-105 { margin: 105px !important; }
	.margin-lg-110 { margin: 110px !important; }
	.margin-lg-115 { margin: 115px !important; }
	.margin-lg-120 { margin: 120px !important; }
	.margin-lg-125 { margin: 125px !important; }
	.margin-lg-130 { margin: 130px !important; }
	.margin-lg-135 { margin: 135px !important; }
	.margin-lg-140 { margin: 140px !important; }
	.margin-lg-145 { margin: 145px !important; }
	.margin-lg-150 { margin: 150px !important; }
	.margin-lg-155 { margin: 155px !important; }
	.margin-lg-160 { margin: 160px !important; }
	.margin-lg-165 { margin: 165px !important; }
	.margin-lg-170 { margin: 170px !important; }
	.margin-lg-175 { margin: 175px !important; }
	.margin-lg-180 { margin: 180px !important; }
	.margin-lg-185 { margin: 185px !important; }
	.margin-lg-190 { margin: 190px !important; }
	.margin-lg-195 { margin: 195px !important; }
	.margin-lg-200 { margin: 200px !important; }

	/* margin top */
	.margin-top-lg-5 { margin-top: 5px !important; }
	.margin-top-lg-10 { margin-top: 10px !important; }
	.margin-top-lg-15 { margin-top: 15px !important; }
	.margin-top-lg-20 { margin-top: 20px !important; }
	.margin-top-lg-25 { margin-top: 25px !important; }
	.margin-top-lg-30 { margin-top: 30px !important; }
	.margin-top-lg-35 { margin-top: 35px !important; }
	.margin-top-lg-40 { margin-top: 40px !important; }
	.margin-top-lg-45 { margin-top: 45px !important; }
	.margin-top-lg-50 { margin-top: 50px !important; }
	.margin-top-lg-55 { margin-top: 55px !important; }
	.margin-top-lg-60 { margin-top: 60px !important; }
	.margin-top-lg-65 { margin-top: 65px !important; }
	.margin-top-lg-70 { margin-top: 70px !important; }
	.margin-top-lg-75 { margin-top: 75px !important; }
	.margin-top-lg-80 { margin-top: 80px !important; }
	.margin-top-lg-85 { margin-top: 85px !important; }
	.margin-top-lg-100 { margin-top: 100px !important; }
	.margin-top-lg-105 { margin-top: 105px !important; }
	.margin-top-lg-110 { margin-top: 110px !important; }
	.margin-top-lg-115 { margin-top: 115px !important; }
	.margin-top-lg-120 { margin-top: 120px !important; }
	.margin-top-lg-125 { margin-top: 125px !important; }
	.margin-top-lg-130 { margin-top: 130px !important; }
	.margin-top-lg-135 { margin-top: 135px !important; }
	.margin-top-lg-140 { margin-top: 140px !important; }
	.margin-top-lg-145 { margin-top: 145px !important; }
	.margin-top-lg-150 { margin-top: 150px !important; }
	.margin-top-lg-155 { margin-top: 155px !important; }
	.margin-top-lg-160 { margin-top: 160px !important; }
	.margin-top-lg-165 { margin-top: 165px !important; }
	.margin-top-lg-170 { margin-top: 170px !important; }
	.margin-top-lg-175 { margin-top: 175px !important; }
	.margin-top-lg-180 { margin-top: 180px !important; }
	.margin-top-lg-185 { margin-top: 185px !important; }
	.margin-top-lg-190 { margin-top: 190px !important; }
	.margin-top-lg-195 { margin-top: 195px !important; }
	.margin-top-lg-200 { margin-top: 200px !important; }

	/* margin bottom */
	.margin-bottom-lg-5 { margin-bottom: 5px !important; }
	.margin-bottom-lg-10 { margin-bottom: 10px !important; }
	.margin-bottom-lg-15 { margin-bottom: 15px !important; }
	.margin-bottom-lg-20 { margin-bottom: 20px !important; }
	.margin-bottom-lg-25 { margin-bottom: 25px !important; }
	.margin-bottom-lg-30 { margin-bottom: 30px !important; }
	.margin-bottom-lg-35 { margin-bottom: 35px !important; }
	.margin-bottom-lg-40 { margin-bottom: 40px !important; }
	.margin-bottom-lg-45 { margin-bottom: 45px !important; }
	.margin-bottom-lg-50 { margin-bottom: 50px !important; }
	.margin-bottom-lg-55 { margin-bottom: 55px !important; }
	.margin-bottom-lg-60 { margin-bottom: 60px !important; }
	.margin-bottom-lg-65 { margin-bottom: 65px !important; }
	.margin-bottom-lg-70 { margin-bottom: 70px !important; }
	.margin-bottom-lg-75 { margin-bottom: 75px !important; }
	.margin-bottom-lg-80 { margin-bottom: 80px !important; }
	.margin-bottom-lg-85 { margin-bottom: 85px !important; }
	.margin-bottom-lg-100 { margin-bottom: 100px !important; }
	.margin-bottom-lg-105 { margin-bottom: 105px !important; }
	.margin-bottom-lg-110 { margin-bottom: 110px !important; }
	.margin-bottom-lg-115 { margin-bottom: 115px !important; }
	.margin-bottom-lg-120 { margin-bottom: 120px !important; }
	.margin-bottom-lg-125 { margin-bottom: 125px !important; }
	.margin-bottom-lg-130 { margin-bottom: 130px !important; }
	.margin-bottom-lg-135 { margin-bottom: 135px !important; }
	.margin-bottom-lg-140 { margin-bottom: 140px !important; }
	.margin-bottom-lg-145 { margin-bottom: 145px !important; }
	.margin-bottom-lg-150 { margin-bottom: 150px !important; }
	.margin-bottom-lg-155 { margin-bottom: 155px !important; }
	.margin-bottom-lg-160 { margin-bottom: 160px !important; }
	.margin-bottom-lg-165 { margin-bottom: 165px !important; }
	.margin-bottom-lg-170 { margin-bottom: 170px !important; }
	.margin-bottom-lg-175 { margin-bottom: 175px !important; }
	.margin-bottom-lg-180 { margin-bottom: 180px !important; }
	.margin-bottom-lg-185 { margin-bottom: 185px !important; }
	.margin-bottom-lg-190 { margin-bottom: 190px !important; }
	.margin-bottom-lg-195 { margin-bottom: 195px !important; }
	.margin-bottom-lg-200 { margin-bottom: 200px !important; }

	/* margin left */
	.margin-left-lg-5 { margin-left: 5px !important; }
	.margin-left-lg-10 { margin-left: 10px !important; }
	.margin-left-lg-15 { margin-left: 15px !important; }
	.margin-left-lg-20 { margin-left: 20px !important; }
	.margin-left-lg-25 { margin-left: 25px !important; }
	.margin-left-lg-30 { margin-left: 30px !important; }
	.margin-left-lg-35 { margin-left: 35px !important; }
	.margin-left-lg-40 { margin-left: 40px !important; }
	.margin-left-lg-45 { margin-left: 45px !important; }
	.margin-left-lg-50 { margin-left: 50px !important; }
	.margin-left-lg-55 { margin-left: 55px !important; }
	.margin-left-lg-60 { margin-left: 60px !important; }
	.margin-left-lg-65 { margin-left: 65px !important; }
	.margin-left-lg-70 { margin-left: 70px !important; }
	.margin-left-lg-75 { margin-left: 75px !important; }
	.margin-left-lg-80 { margin-left: 80px !important; }
	.margin-left-lg-85 { margin-left: 85px !important; }
	.margin-left-lg-100 { margin-left: 100px !important; }
	.margin-left-lg-105 { margin-left: 105px !important; }
	.margin-left-lg-110 { margin-left: 110px !important; }
	.margin-left-lg-115 { margin-left: 115px !important; }
	.margin-left-lg-120 { margin-left: 120px !important; }
	.margin-left-lg-125 { margin-left: 125px !important; }
	.margin-left-lg-130 { margin-left: 130px !important; }
	.margin-left-lg-135 { margin-left: 135px !important; }
	.margin-left-lg-140 { margin-left: 140px !important; }
	.margin-left-lg-145 { margin-left: 145px !important; }
	.margin-left-lg-150 { margin-left: 150px !important; }
	.margin-left-lg-155 { margin-left: 155px !important; }
	.margin-left-lg-160 { margin-left: 160px !important; }
	.margin-left-lg-165 { margin-left: 165px !important; }
	.margin-left-lg-170 { margin-left: 170px !important; }
	.margin-left-lg-175 { margin-left: 175px !important; }
	.margin-left-lg-180 { margin-left: 180px !important; }
	.margin-left-lg-185 { margin-left: 185px !important; }
	.margin-left-lg-190 { margin-left: 190px !important; }
	.margin-left-lg-195 { margin-left: 195px !important; }
	.margin-left-lg-200 { margin-left: 200px !important; }

	/* margin right */
	.margin-right-lg-5 { margin-right: 5px !important; }
	.margin-right-lg-10 { margin-right: 10px !important; }
	.margin-right-lg-15 { margin-right: 15px !important; }
	.margin-right-lg-20 { margin-right: 20px !important; }
	.margin-right-lg-25 { margin-right: 25px !important; }
	.margin-right-lg-30 { margin-right: 30px !important; }
	.margin-right-lg-35 { margin-right: 35px !important; }
	.margin-right-lg-40 { margin-right: 40px !important; }
	.margin-right-lg-45 { margin-right: 45px !important; }
	.margin-right-lg-50 { margin-right: 50px !important; }
	.margin-right-lg-55 { margin-right: 55px !important; }
	.margin-right-lg-60 { margin-right: 60px !important; }
	.margin-right-lg-65 { margin-right: 65px !important; }
	.margin-right-lg-70 { margin-right: 70px !important; }
	.margin-right-lg-75 { margin-right: 75px !important; }
	.margin-right-lg-80 { margin-right: 80px !important; }
	.margin-right-lg-85 { margin-right: 85px !important; }
	.margin-right-lg-100 { margin-right: 100px !important; }
	.margin-right-lg-105 { margin-right: 105px !important; }
	.margin-right-lg-110 { margin-right: 110px !important; }
	.margin-right-lg-115 { margin-right: 115px !important; }
	.margin-right-lg-120 { margin-right: 120px !important; }
	.margin-right-lg-125 { margin-right: 125px !important; }
	.margin-right-lg-130 { margin-right: 130px !important; }
	.margin-right-lg-135 { margin-right: 135px !important; }
	.margin-right-lg-140 { margin-right: 140px !important; }
	.margin-right-lg-145 { margin-right: 145px !important; }
	.margin-right-lg-150 { margin-right: 150px !important; }
	.margin-right-lg-155 { margin-right: 155px !important; }
	.margin-right-lg-160 { margin-right: 160px !important; }
	.margin-right-lg-165 { margin-right: 165px !important; }
	.margin-right-lg-170 { margin-right: 170px !important; }
	.margin-right-lg-175 { margin-right: 175px !important; }
	.margin-right-lg-180 { margin-right: 180px !important; }
	.margin-right-lg-185 { margin-right: 185px !important; }
	.margin-right-lg-190 { margin-right: 190px !important; }
	.margin-right-lg-195 { margin-right: 195px !important; }
	.margin-right-lg-200 { margin-right: 200px !important; }


	/* Margin (percent) 
	===================== */
	.margin-lg-1-p { margin: 1% !important; }
	.margin-lg-2-p { margin: 2% !important; }
	.margin-lg-3-p { margin: 3% !important; }
	.margin-lg-4-p { margin: 4% !important; }
	.margin-lg-5-p { margin: 5% !important; }
	.margin-lg-6-p { margin: 6% !important; }
	.margin-lg-7-p { margin: 7% !important; }
	.margin-lg-8-p { margin: 8% !important; }
	.margin-lg-9-p { margin: 9% !important; }
	.margin-lg-10-p { margin: 10% !important; }
	.margin-lg-11-p { margin: 11% !important; }
	.margin-lg-12-p { margin: 12% !important; }
	.margin-lg-13-p { margin: 13% !important; }
	.margin-lg-14-p { margin: 14% !important; }
	.margin-lg-15-p { margin: 15% !important; }
	.margin-lg-16-p { margin: 16% !important; }
	.margin-lg-17-p { margin: 17% !important; }
	.margin-lg-18-p { margin: 18% !important; }
	.margin-lg-19-p { margin: 19% !important; }
	.margin-lg-20-p { margin: 20% !important; }

	/* margin top (percent) */
	.margin-top-lg-1-p { margin-top: 1% !important; }
	.margin-top-lg-2-p { margin-top: 2% !important; }
	.margin-top-lg-3-p { margin-top: 3% !important; }
	.margin-top-lg-4-p { margin-top: 4% !important; }
	.margin-top-lg-5-p { margin-top: 5% !important; }
	.margin-top-lg-6-p { margin-top: 6% !important; }
	.margin-top-lg-7-p { margin-top: 7% !important; }
	.margin-top-lg-8-p { margin-top: 8% !important; }
	.margin-top-lg-9-p { margin-top: 9% !important; }
	.margin-top-lg-10-p { margin-top: 10% !important; }
	.margin-top-lg-11-p { margin-top: 11% !important; }
	.margin-top-lg-12-p { margin-top: 12% !important; }
	.margin-top-lg-13-p { margin-top: 13% !important; }
	.margin-top-lg-14-p { margin-top: 14% !important; }
	.margin-top-lg-15-p { margin-top: 15% !important; }
	.margin-top-lg-16-p { margin-top: 16% !important; }
	.margin-top-lg-17-p { margin-top: 17% !important; }
	.margin-top-lg-18-p { margin-top: 18% !important; }
	.margin-top-lg-19-p { margin-top: 19% !important; }
	.margin-top-lg-20-p { margin-top: 20% !important; }

	/* margin bottom (percent) */
	.margin-bottom-lg-1-p { margin-bottom: 1% !important; }
	.margin-bottom-lg-2-p { margin-bottom: 2% !important; }
	.margin-bottom-lg-3-p { margin-bottom: 3% !important; }
	.margin-bottom-lg-4-p { margin-bottom: 4% !important; }
	.margin-bottom-lg-5-p { margin-bottom: 5% !important; }
	.margin-bottom-lg-6-p { margin-bottom: 6% !important; }
	.margin-bottom-lg-7-p { margin-bottom: 7% !important; }
	.margin-bottom-lg-8-p { margin-bottom: 8% !important; }
	.margin-bottom-lg-9-p { margin-bottom: 9% !important; }
	.margin-bottom-lg-10-p { margin-bottom: 10% !important; }
	.margin-bottom-lg-11-p { margin-bottom: 11% !important; }
	.margin-bottom-lg-12-p { margin-bottom: 12% !important; }
	.margin-bottom-lg-13-p { margin-bottom: 13% !important; }
	.margin-bottom-lg-14-p { margin-bottom: 14% !important; }
	.margin-bottom-lg-15-p { margin-bottom: 15% !important; }
	.margin-bottom-lg-16-p { margin-bottom: 16% !important; }
	.margin-bottom-lg-17-p { margin-bottom: 17% !important; }
	.margin-bottom-lg-18-p { margin-bottom: 18% !important; }
	.margin-bottom-lg-19-p { margin-bottom: 19% !important; }
	.margin-bottom-lg-20-p { margin-bottom: 20% !important; }

	/* margin left (percent) */
	.margin-left-lg-1-p { margin-left: 1% !important; }
	.margin-left-lg-2-p { margin-left: 2% !important; }
	.margin-left-lg-3-p { margin-left: 3% !important; }
	.margin-left-lg-4-p { margin-left: 4% !important; }
	.margin-left-lg-5-p { margin-left: 5% !important; }
	.margin-left-lg-6-p { margin-left: 6% !important; }
	.margin-left-lg-7-p { margin-left: 7% !important; }
	.margin-left-lg-8-p { margin-left: 8% !important; }
	.margin-left-lg-9-p { margin-left: 9% !important; }
	.margin-left-lg-10-p { margin-left: 10% !important; }
	.margin-left-lg-11-p { margin-left: 11% !important; }
	.margin-left-lg-12-p { margin-left: 12% !important; }
	.margin-left-lg-13-p { margin-left: 13% !important; }
	.margin-left-lg-14-p { margin-left: 14% !important; }
	.margin-left-lg-15-p { margin-left: 15% !important; }
	.margin-left-lg-16-p { margin-left: 16% !important; }
	.margin-left-lg-17-p { margin-left: 17% !important; }
	.margin-left-lg-18-p { margin-left: 18% !important; }
	.margin-left-lg-19-p { margin-left: 19% !important; }
	.margin-left-lg-20-p { margin-left: 20% !important; }

	/* margin right (percent) */
	.margin-right-lg-1-p { margin-right: 1% !important; }
	.margin-right-lg-2-p { margin-right: 2% !important; }
	.margin-right-lg-3-p { margin-right: 3% !important; }
	.margin-right-lg-4-p { margin-right: 4% !important; }
	.margin-right-lg-5-p { margin-right: 5% !important; }
	.margin-right-lg-6-p { margin-right: 6% !important; }
	.margin-right-lg-7-p { margin-right: 7% !important; }
	.margin-right-lg-8-p { margin-right: 8% !important; }
	.margin-right-lg-9-p { margin-right: 9% !important; }
	.margin-right-lg-10-p { margin-right: 10% !important; }
	.margin-right-lg-11-p { margin-right: 11% !important; }
	.margin-right-lg-12-p { margin-right: 12% !important; }
	.margin-right-lg-13-p { margin-right: 13% !important; }
	.margin-right-lg-14-p { margin-right: 14% !important; }
	.margin-right-lg-15-p { margin-right: 15% !important; }
	.margin-right-lg-16-p { margin-right: 16% !important; }
	.margin-right-lg-17-p { margin-right: 17% !important; }
	.margin-right-lg-18-p { margin-right: 18% !important; }
	.margin-right-lg-19-p { margin-right: 19% !important; }
	.margin-right-lg-20-p { margin-right: 20% !important; }

	/* no margins classes */
	.no-margin-lg { margin: 0 !important; }
	.no-margin-lg-top { margin-top: 0 !important; }
	.no-margin-lg-bottom { margin-bottom: 0 !important; }
	.no-margin-lg-left { margin-left: 0 !important; }
	.no-margin-lg-right { margin-right: 0 !important; }
}
@media (min-width: 1025px) {
	.margin-xlg-5 { margin: 5px !important; }
	.margin-xlg-10 { margin: 10px !important; }
	.margin-xlg-15 { margin: 15px !important; }
	.margin-xlg-20 { margin: 20px !important; }
	.margin-xlg-25 { margin: 25px !important; }
	.margin-xlg-30 { margin: 30px !important; }
	.margin-xlg-35 { margin: 35px !important; }
	.margin-xlg-40 { margin: 40px !important; }
	.margin-xlg-45 { margin: 45px !important; }
	.margin-xlg-50 { margin: 50px !important; }
	.margin-xlg-55 { margin: 55px !important; }
	.margin-xlg-60 { margin: 60px !important; }
	.margin-xlg-65 { margin: 65px !important; }
	.margin-xlg-70 { margin: 70px !important; }
	.margin-xlg-75 { margin: 75px !important; }
	.margin-xlg-80 { margin: 80px !important; }
	.margin-xlg-85 { margin: 85px !important; }
	.margin-xlg-100 { margin: 80px !important; }
	.margin-xlg-105 { margin: 105px !important; }
	.margin-xlg-110 { margin: 110px !important; }
	.margin-xlg-115 { margin: 115px !important; }
	.margin-xlg-120 { margin: 120px !important; }
	.margin-xlg-125 { margin: 125px !important; }
	.margin-xlg-130 { margin: 130px !important; }
	.margin-xlg-135 { margin: 135px !important; }
	.margin-xlg-140 { margin: 140px !important; }
	.margin-xlg-145 { margin: 145px !important; }
	.margin-xlg-150 { margin: 150px !important; }
	.margin-xlg-155 { margin: 155px !important; }
	.margin-xlg-160 { margin: 160px !important; }
	.margin-xlg-165 { margin: 165px !important; }
	.margin-xlg-170 { margin: 170px !important; }
	.margin-xlg-175 { margin: 175px !important; }
	.margin-xlg-180 { margin: 180px !important; }
	.margin-xlg-185 { margin: 185px !important; }
	.margin-xlg-190 { margin: 190px !important; }
	.margin-xlg-195 { margin: 195px !important; }
	.margin-xlg-200 { margin: 200px !important; }

	/* margin top */
	.margin-top-xlg-5 { margin-top: 5px !important; }
	.margin-top-xlg-10 { margin-top: 10px !important; }
	.margin-top-xlg-15 { margin-top: 15px !important; }
	.margin-top-xlg-20 { margin-top: 20px !important; }
	.margin-top-xlg-25 { margin-top: 25px !important; }
	.margin-top-xlg-30 { margin-top: 30px !important; }
	.margin-top-xlg-35 { margin-top: 35px !important; }
	.margin-top-xlg-40 { margin-top: 40px !important; }
	.margin-top-xlg-45 { margin-top: 45px !important; }
	.margin-top-xlg-50 { margin-top: 50px !important; }
	.margin-top-xlg-55 { margin-top: 55px !important; }
	.margin-top-xlg-60 { margin-top: 60px !important; }
	.margin-top-xlg-65 { margin-top: 65px !important; }
	.margin-top-xlg-70 { margin-top: 70px !important; }
	.margin-top-xlg-75 { margin-top: 75px !important; }
	.margin-top-xlg-80 { margin-top: 80px !important; }
	.margin-top-xlg-85 { margin-top: 85px !important; }
	.margin-top-xlg-100 { margin-top: 100px !important; }
	.margin-top-xlg-105 { margin-top: 105px !important; }
	.margin-top-xlg-110 { margin-top: 110px !important; }
	.margin-top-xlg-115 { margin-top: 115px !important; }
	.margin-top-xlg-120 { margin-top: 120px !important; }
	.margin-top-xlg-125 { margin-top: 125px !important; }
	.margin-top-xlg-130 { margin-top: 130px !important; }
	.margin-top-xlg-135 { margin-top: 135px !important; }
	.margin-top-xlg-140 { margin-top: 140px !important; }
	.margin-top-xlg-145 { margin-top: 145px !important; }
	.margin-top-xlg-150 { margin-top: 150px !important; }
	.margin-top-xlg-155 { margin-top: 155px !important; }
	.margin-top-xlg-160 { margin-top: 160px !important; }
	.margin-top-xlg-165 { margin-top: 165px !important; }
	.margin-top-xlg-170 { margin-top: 170px !important; }
	.margin-top-xlg-175 { margin-top: 175px !important; }
	.margin-top-xlg-180 { margin-top: 180px !important; }
	.margin-top-xlg-185 { margin-top: 185px !important; }
	.margin-top-xlg-190 { margin-top: 190px !important; }
	.margin-top-xlg-195 { margin-top: 195px !important; }
	.margin-top-xlg-200 { margin-top: 200px !important; }

	/* margin bottom */
	.margin-bottom-xlg-5 { margin-bottom: 5px !important; }
	.margin-bottom-xlg-10 { margin-bottom: 10px !important; }
	.margin-bottom-xlg-15 { margin-bottom: 15px !important; }
	.margin-bottom-xlg-20 { margin-bottom: 20px !important; }
	.margin-bottom-xlg-25 { margin-bottom: 25px !important; }
	.margin-bottom-xlg-30 { margin-bottom: 30px !important; }
	.margin-bottom-xlg-35 { margin-bottom: 35px !important; }
	.margin-bottom-xlg-40 { margin-bottom: 40px !important; }
	.margin-bottom-xlg-45 { margin-bottom: 45px !important; }
	.margin-bottom-xlg-50 { margin-bottom: 50px !important; }
	.margin-bottom-xlg-55 { margin-bottom: 55px !important; }
	.margin-bottom-xlg-60 { margin-bottom: 60px !important; }
	.margin-bottom-xlg-65 { margin-bottom: 65px !important; }
	.margin-bottom-xlg-70 { margin-bottom: 70px !important; }
	.margin-bottom-xlg-75 { margin-bottom: 75px !important; }
	.margin-bottom-xlg-80 { margin-bottom: 80px !important; }
	.margin-bottom-xlg-85 { margin-bottom: 85px !important; }
	.margin-bottom-xlg-100 { margin-bottom: 100px !important; }
	.margin-bottom-xlg-105 { margin-bottom: 105px !important; }
	.margin-bottom-xlg-110 { margin-bottom: 110px !important; }
	.margin-bottom-xlg-115 { margin-bottom: 115px !important; }
	.margin-bottom-xlg-120 { margin-bottom: 120px !important; }
	.margin-bottom-xlg-125 { margin-bottom: 125px !important; }
	.margin-bottom-xlg-130 { margin-bottom: 130px !important; }
	.margin-bottom-xlg-135 { margin-bottom: 135px !important; }
	.margin-bottom-xlg-140 { margin-bottom: 140px !important; }
	.margin-bottom-xlg-145 { margin-bottom: 145px !important; }
	.margin-bottom-xlg-150 { margin-bottom: 150px !important; }
	.margin-bottom-xlg-155 { margin-bottom: 155px !important; }
	.margin-bottom-xlg-160 { margin-bottom: 160px !important; }
	.margin-bottom-xlg-165 { margin-bottom: 165px !important; }
	.margin-bottom-xlg-170 { margin-bottom: 170px !important; }
	.margin-bottom-xlg-175 { margin-bottom: 175px !important; }
	.margin-bottom-xlg-180 { margin-bottom: 180px !important; }
	.margin-bottom-xlg-185 { margin-bottom: 185px !important; }
	.margin-bottom-xlg-190 { margin-bottom: 190px !important; }
	.margin-bottom-xlg-195 { margin-bottom: 195px !important; }
	.margin-bottom-xlg-200 { margin-bottom: 200px !important; }

	/* margin left */
	.margin-left-xlg-5 { margin-left: 5px !important; }
	.margin-left-xlg-10 { margin-left: 10px !important; }
	.margin-left-xlg-15 { margin-left: 15px !important; }
	.margin-left-xlg-20 { margin-left: 20px !important; }
	.margin-left-xlg-25 { margin-left: 25px !important; }
	.margin-left-xlg-30 { margin-left: 30px !important; }
	.margin-left-xlg-35 { margin-left: 35px !important; }
	.margin-left-xlg-40 { margin-left: 40px !important; }
	.margin-left-xlg-45 { margin-left: 45px !important; }
	.margin-left-xlg-50 { margin-left: 50px !important; }
	.margin-left-xlg-55 { margin-left: 55px !important; }
	.margin-left-xlg-60 { margin-left: 60px !important; }
	.margin-left-xlg-65 { margin-left: 65px !important; }
	.margin-left-xlg-70 { margin-left: 70px !important; }
	.margin-left-xlg-75 { margin-left: 75px !important; }
	.margin-left-xlg-80 { margin-left: 80px !important; }
	.margin-left-xlg-85 { margin-left: 85px !important; }
	.margin-left-xlg-100 { margin-left: 100px !important; }
	.margin-left-xlg-105 { margin-left: 105px !important; }
	.margin-left-xlg-110 { margin-left: 110px !important; }
	.margin-left-xlg-115 { margin-left: 115px !important; }
	.margin-left-xlg-120 { margin-left: 120px !important; }
	.margin-left-xlg-125 { margin-left: 125px !important; }
	.margin-left-xlg-130 { margin-left: 130px !important; }
	.margin-left-xlg-135 { margin-left: 135px !important; }
	.margin-left-xlg-140 { margin-left: 140px !important; }
	.margin-left-xlg-145 { margin-left: 145px !important; }
	.margin-left-xlg-150 { margin-left: 150px !important; }
	.margin-left-xlg-155 { margin-left: 155px !important; }
	.margin-left-xlg-160 { margin-left: 160px !important; }
	.margin-left-xlg-165 { margin-left: 165px !important; }
	.margin-left-xlg-170 { margin-left: 170px !important; }
	.margin-left-xlg-175 { margin-left: 175px !important; }
	.margin-left-xlg-180 { margin-left: 180px !important; }
	.margin-left-xlg-185 { margin-left: 185px !important; }
	.margin-left-xlg-190 { margin-left: 190px !important; }
	.margin-left-xlg-195 { margin-left: 195px !important; }
	.margin-left-xlg-200 { margin-left: 200px !important; }

	/* margin right */
	.margin-right-xlg-5 { margin-right: 5px !important; }
	.margin-right-xlg-10 { margin-right: 10px !important; }
	.margin-right-xlg-15 { margin-right: 15px !important; }
	.margin-right-xlg-20 { margin-right: 20px !important; }
	.margin-right-xlg-25 { margin-right: 25px !important; }
	.margin-right-xlg-30 { margin-right: 30px !important; }
	.margin-right-xlg-35 { margin-right: 35px !important; }
	.margin-right-xlg-40 { margin-right: 40px !important; }
	.margin-right-xlg-45 { margin-right: 45px !important; }
	.margin-right-xlg-50 { margin-right: 50px !important; }
	.margin-right-xlg-55 { margin-right: 55px !important; }
	.margin-right-xlg-60 { margin-right: 60px !important; }
	.margin-right-xlg-65 { margin-right: 65px !important; }
	.margin-right-xlg-70 { margin-right: 70px !important; }
	.margin-right-xlg-75 { margin-right: 75px !important; }
	.margin-right-xlg-80 { margin-right: 80px !important; }
	.margin-right-xlg-85 { margin-right: 85px !important; }
	.margin-right-xlg-100 { margin-right: 100px !important; }
	.margin-right-xlg-105 { margin-right: 105px !important; }
	.margin-right-xlg-110 { margin-right: 110px !important; }
	.margin-right-xlg-115 { margin-right: 115px !important; }
	.margin-right-xlg-120 { margin-right: 120px !important; }
	.margin-right-xlg-125 { margin-right: 125px !important; }
	.margin-right-xlg-130 { margin-right: 130px !important; }
	.margin-right-xlg-135 { margin-right: 135px !important; }
	.margin-right-xlg-140 { margin-right: 140px !important; }
	.margin-right-xlg-145 { margin-right: 145px !important; }
	.margin-right-xlg-150 { margin-right: 150px !important; }
	.margin-right-xlg-155 { margin-right: 155px !important; }
	.margin-right-xlg-160 { margin-right: 160px !important; }
	.margin-right-xlg-165 { margin-right: 165px !important; }
	.margin-right-xlg-170 { margin-right: 170px !important; }
	.margin-right-xlg-175 { margin-right: 175px !important; }
	.margin-right-xlg-180 { margin-right: 180px !important; }
	.margin-right-xlg-185 { margin-right: 185px !important; }
	.margin-right-xlg-190 { margin-right: 190px !important; }
	.margin-right-xlg-195 { margin-right: 195px !important; }
	.margin-right-xlg-200 { margin-right: 200px !important; }


	/* Margin (percent) 
	===================== */
	.margin-xlg-1-p { margin: 1% !important; }
	.margin-xlg-2-p { margin: 2% !important; }
	.margin-xlg-3-p { margin: 3% !important; }
	.margin-xlg-4-p { margin: 4% !important; }
	.margin-xlg-5-p { margin: 5% !important; }
	.margin-xlg-6-p { margin: 6% !important; }
	.margin-xlg-7-p { margin: 7% !important; }
	.margin-xlg-8-p { margin: 8% !important; }
	.margin-xlg-9-p { margin: 9% !important; }
	.margin-xlg-10-p { margin: 10% !important; }
	.margin-xlg-11-p { margin: 11% !important; }
	.margin-xlg-12-p { margin: 12% !important; }
	.margin-xlg-13-p { margin: 13% !important; }
	.margin-xlg-14-p { margin: 14% !important; }
	.margin-xlg-15-p { margin: 15% !important; }
	.margin-xlg-16-p { margin: 16% !important; }
	.margin-xlg-17-p { margin: 17% !important; }
	.margin-xlg-18-p { margin: 18% !important; }
	.margin-xlg-19-p { margin: 19% !important; }
	.margin-xlg-20-p { margin: 20% !important; }

	/* margin top (percent) */
	.margin-top-xlg-1-p { margin-top: 1% !important; }
	.margin-top-xlg-2-p { margin-top: 2% !important; }
	.margin-top-xlg-3-p { margin-top: 3% !important; }
	.margin-top-xlg-4-p { margin-top: 4% !important; }
	.margin-top-xlg-5-p { margin-top: 5% !important; }
	.margin-top-xlg-6-p { margin-top: 6% !important; }
	.margin-top-xlg-7-p { margin-top: 7% !important; }
	.margin-top-xlg-8-p { margin-top: 8% !important; }
	.margin-top-xlg-9-p { margin-top: 9% !important; }
	.margin-top-xlg-10-p { margin-top: 10% !important; }
	.margin-top-xlg-11-p { margin-top: 11% !important; }
	.margin-top-xlg-12-p { margin-top: 12% !important; }
	.margin-top-xlg-13-p { margin-top: 13% !important; }
	.margin-top-xlg-14-p { margin-top: 14% !important; }
	.margin-top-xlg-15-p { margin-top: 15% !important; }
	.margin-top-xlg-16-p { margin-top: 16% !important; }
	.margin-top-xlg-17-p { margin-top: 17% !important; }
	.margin-top-xlg-18-p { margin-top: 18% !important; }
	.margin-top-xlg-19-p { margin-top: 19% !important; }
	.margin-top-xlg-20-p { margin-top: 20% !important; }

	/* margin bottom (percent) */
	.margin-bottom-xlg-1-p { margin-bottom: 1% !important; }
	.margin-bottom-xlg-2-p { margin-bottom: 2% !important; }
	.margin-bottom-xlg-3-p { margin-bottom: 3% !important; }
	.margin-bottom-xlg-4-p { margin-bottom: 4% !important; }
	.margin-bottom-xlg-5-p { margin-bottom: 5% !important; }
	.margin-bottom-xlg-6-p { margin-bottom: 6% !important; }
	.margin-bottom-xlg-7-p { margin-bottom: 7% !important; }
	.margin-bottom-xlg-8-p { margin-bottom: 8% !important; }
	.margin-bottom-xlg-9-p { margin-bottom: 9% !important; }
	.margin-bottom-xlg-10-p { margin-bottom: 10% !important; }
	.margin-bottom-xlg-11-p { margin-bottom: 11% !important; }
	.margin-bottom-xlg-12-p { margin-bottom: 12% !important; }
	.margin-bottom-xlg-13-p { margin-bottom: 13% !important; }
	.margin-bottom-xlg-14-p { margin-bottom: 14% !important; }
	.margin-bottom-xlg-15-p { margin-bottom: 15% !important; }
	.margin-bottom-xlg-16-p { margin-bottom: 16% !important; }
	.margin-bottom-xlg-17-p { margin-bottom: 17% !important; }
	.margin-bottom-xlg-18-p { margin-bottom: 18% !important; }
	.margin-bottom-xlg-19-p { margin-bottom: 19% !important; }
	.margin-bottom-xlg-20-p { margin-bottom: 20% !important; }

	/* margin left (percent) */
	.margin-left-xlg-1-p { margin-left: 1% !important; }
	.margin-left-xlg-2-p { margin-left: 2% !important; }
	.margin-left-xlg-3-p { margin-left: 3% !important; }
	.margin-left-xlg-4-p { margin-left: 4% !important; }
	.margin-left-xlg-5-p { margin-left: 5% !important; }
	.margin-left-xlg-6-p { margin-left: 6% !important; }
	.margin-left-xlg-7-p { margin-left: 7% !important; }
	.margin-left-xlg-8-p { margin-left: 8% !important; }
	.margin-left-xlg-9-p { margin-left: 9% !important; }
	.margin-left-xlg-10-p { margin-left: 10% !important; }
	.margin-left-xlg-11-p { margin-left: 11% !important; }
	.margin-left-xlg-12-p { margin-left: 12% !important; }
	.margin-left-xlg-13-p { margin-left: 13% !important; }
	.margin-left-xlg-14-p { margin-left: 14% !important; }
	.margin-left-xlg-15-p { margin-left: 15% !important; }
	.margin-left-xlg-16-p { margin-left: 16% !important; }
	.margin-left-xlg-17-p { margin-left: 17% !important; }
	.margin-left-xlg-18-p { margin-left: 18% !important; }
	.margin-left-xlg-19-p { margin-left: 19% !important; }
	.margin-left-xlg-20-p { margin-left: 20% !important; }

	/* margin right (percent) */
	.margin-right-xlg-1-p { margin-right: 1% !important; }
	.margin-right-xlg-2-p { margin-right: 2% !important; }
	.margin-right-xlg-3-p { margin-right: 3% !important; }
	.margin-right-xlg-4-p { margin-right: 4% !important; }
	.margin-right-xlg-5-p { margin-right: 5% !important; }
	.margin-right-xlg-6-p { margin-right: 6% !important; }
	.margin-right-xlg-7-p { margin-right: 7% !important; }
	.margin-right-xlg-8-p { margin-right: 8% !important; }
	.margin-right-xlg-9-p { margin-right: 9% !important; }
	.margin-right-xlg-10-p { margin-right: 10% !important; }
	.margin-right-xlg-11-p { margin-right: 11% !important; }
	.margin-right-xlg-12-p { margin-right: 12% !important; }
	.margin-right-xlg-13-p { margin-right: 13% !important; }
	.margin-right-xlg-14-p { margin-right: 14% !important; }
	.margin-right-xlg-15-p { margin-right: 15% !important; }
	.margin-right-xlg-16-p { margin-right: 16% !important; }
	.margin-right-xlg-17-p { margin-right: 17% !important; }
	.margin-right-xlg-18-p { margin-right: 18% !important; }
	.margin-right-xlg-19-p { margin-right: 19% !important; }
	.margin-right-xlg-20-p { margin-right: 20% !important; }

	/* no margins classes */
	.no-margin-xlg { margin: 0 !important; }
	.no-margin-xlg-top { margin-top: 0 !important; }
	.no-margin-xlg-bottom { margin-bottom: 0 !important; }
	.no-margin-xlg-left { margin-left: 0 !important; }
	.no-margin-xlg-right { margin-right: 0 !important; }
}


/* ------------------------------------------------------------- *
 * Grid system (similar logic to the Bootstrap framework).
/* ------------------------------------------------------------- */

/* Row */
.tt-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
body.tt-boxed .tt-wrap .tt-row {
	margin-right: -15px;
	margin-left: -15px;
}

/* no-gutters */
.tt-no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.tt-no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* Columns */
[class*="tt-col-"] {
	position: relative;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}

.tt-col-1 {
	-ms-flex: 0 0 8.333333%;
	flex: 0 0 8.333333%;
	max-width: 8.333333%;
}
.tt-col-2 {
	-ms-flex: 0 0 16.666667%;
	flex: 0 0 16.666667%;
	max-width: 16.666667%;
}
.tt-col-3 {
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}
.tt-col-4 {
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}
.tt-col-5 {
	-ms-flex: 0 0 41.666667%;
	flex: 0 0 41.666667%;
	max-width: 41.666667%;
}
.tt-col-6 {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}
.tt-col-7 {
	-ms-flex: 0 0 58.333333%;
	flex: 0 0 58.333333%;
	max-width: 58.333333%;
}
.tt-col-8 {
	-ms-flex: 0 0 66.666667%;
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
}
.tt-col-9 {
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}
.tt-col-10 {
	-ms-flex: 0 0 83.333333%;
	flex: 0 0 83.333333%;
	max-width: 83.333333%;
}
.tt-col-11 {
	-ms-flex: 0 0 91.666667%;
	flex: 0 0 91.666667%;
	max-width: 91.666667%;
}
.tt-col-12 {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

/* Row reverse */
.tt-row-reverse {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}

/* Column reverse */
.tt-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

/* Justify content */
.tt-justify-content-start {
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}
.tt-justify-content-end {
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}
.tt-justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}
.tt-justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}
.tt-justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}

/* Align items */
.tt-align-items-start {
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}
.tt-align-items-end {
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}
.tt-align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}
.tt-align-items-baseline {
	-ms-flex-align: baseline !important;
	align-items: baseline !important;
}
.tt-align-items-stretch {
	-ms-flex-align: stretch !important;
	align-items: stretch !important;
}

/* Align content */
.tt-align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important;
}
.tt-align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important;
}
.tt-align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important;
}
.tt-align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important;
}
.tt-align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important;
}
.tt-align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important;
}

/* Align self */
.tt-align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important;
}
.tt-align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
}
.tt-align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important;
}
.tt-align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important;
}
.tt-align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important;
}


/* Breakpoints 
=============== */
@media (min-width: 576px) {
	.tt-col-sm {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	/* Row columns */
	.tt-row-cols-sm-1 > * {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.tt-row-cols-sm-2 > * {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.tt-row-cols-sm-3 > * {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.tt-row-cols-sm-4 > * {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.tt-row-cols-sm-5 > * {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.tt-row-cols-sm-6 > * {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	/* Columns auto */
	.tt-col-sm-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}

	/* Columns */
	.tt-col-sm-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.tt-col-sm-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.tt-col-sm-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.tt-col-sm-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.tt-col-sm-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.tt-col-sm-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.tt-col-sm-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.tt-col-sm-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.tt-col-sm-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.tt-col-sm-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.tt-col-sm-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.tt-col-sm-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	/* Order */
	.tt-order-sm-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.tt-order-sm-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.tt-order-sm-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.tt-order-sm-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.tt-order-sm-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.tt-order-sm-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.tt-order-sm-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.tt-order-sm-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.tt-order-sm-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.tt-order-sm-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.tt-order-sm-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.tt-order-sm-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.tt-order-sm-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.tt-order-sm-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.tt-order-sm-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	/* Offset */
	.tt-offset-sm-0 {
		margin-left: 0;
	}
	.tt-offset-sm-1 {
		margin-left: 8.333333%;
	}
	.tt-offset-sm-2 {
		margin-left: 16.666667%;
	}
	.tt-offset-sm-3 {
		margin-left: 25%;
	}
	.tt-offset-sm-4 {
		margin-left: 33.333333%;
	}
	.tt-offset-sm-5 {
		margin-left: 41.666667%;
	}
	.tt-offset-sm-6 {
		margin-left: 50%;
	}
	.tt-offset-sm-7 {
		margin-left: 58.333333%;
	}
	.tt-offset-sm-8 {
		margin-left: 66.666667%;
	}
	.tt-offset-sm-9 {
		margin-left: 75%;
	}
	.tt-offset-sm-10 {
		margin-left: 83.333333%;
	}
	.tt-offset-sm-11 {
		margin-left: 91.666667%;
	}

	/* Justify content */
	.tt-justify-content-sm-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.tt-justify-content-sm-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.tt-justify-content-sm-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.tt-justify-content-sm-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.tt-justify-content-sm-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}

	/* Align items */
	.tt-align-items-sm-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.tt-align-items-sm-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.tt-align-items-sm-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.tt-align-items-sm-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.tt-align-items-sm-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}

	/* Align content */
	.tt-align-content-sm-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.tt-align-content-sm-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.tt-align-content-sm-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.tt-align-content-sm-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.tt-align-content-sm-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.tt-align-content-sm-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}

	/* Align self */
	.tt-align-self-sm-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.tt-align-self-sm-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.tt-align-self-sm-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.tt-align-self-sm-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.tt-align-self-sm-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.tt-align-self-sm-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}

	/* Reverce */
	.tt-sm-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.tt-sm-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
}

@media (min-width: 768px) {
	.tt-col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	/* Row columns */
	.tt-row-cols-md-1 > * {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.tt-row-cols-md-2 > * {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.tt-row-cols-md-3 > * {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.tt-row-cols-md-4 > * {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.tt-row-cols-md-5 > * {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.tt-row-cols-md-6 > * {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	/* Columns auto */
	.tt-col-md-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}

	/* Columns */
	.tt-col-md-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.tt-col-md-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.tt-col-md-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.tt-col-md-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.tt-col-md-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.tt-col-md-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.tt-col-md-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.tt-col-md-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.tt-col-md-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.tt-col-md-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.tt-col-md-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.tt-col-md-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	/* Order */
	.tt-order-md-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.tt-order-md-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.tt-order-md-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.tt-order-md-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.tt-order-md-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.tt-order-md-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.tt-order-md-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.tt-order-md-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.tt-order-md-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.tt-order-md-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.tt-order-md-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.tt-order-md-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.tt-order-md-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.tt-order-md-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.tt-order-md-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	/* Offset */
	.tt-offset-md-0 {
		margin-left: 0;
	}
	.tt-offset-md-1 {
		margin-left: 8.333333%;
	}
	.tt-offset-md-2 {
		margin-left: 16.666667%;
	}
	.tt-offset-md-3 {
		margin-left: 25%;
	}
	.tt-offset-md-4 {
		margin-left: 33.333333%;
	}
	.tt-offset-md-5 {
		margin-left: 41.666667%;
	}
	.tt-offset-md-6 {
		margin-left: 50%;
	}
	.tt-offset-md-7 {
		margin-left: 58.333333%;
	}
	.tt-offset-md-8 {
		margin-left: 66.666667%;
	}
	.tt-offset-md-9 {
		margin-left: 75%;
	}
	.tt-offset-md-10 {
		margin-left: 83.333333%;
	}
	.tt-offset-md-11 {
		margin-left: 91.666667%;
	}

	/* Justify content */
	.tt-justify-content-md-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.tt-justify-content-md-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.tt-justify-content-md-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.tt-justify-content-md-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.tt-justify-content-md-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}

	/* Align items */
	.tt-align-items-md-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.tt-align-items-md-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.tt-align-items-md-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.tt-align-items-md-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.tt-align-items-md-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}

	/* Align content */
	.tt-align-content-md-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.tt-align-content-md-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.tt-align-content-md-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.tt-align-content-md-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.tt-align-content-md-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.tt-align-content-md-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}

	/* Align self */
	.tt-align-self-md-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.tt-align-self-md-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.tt-align-self-md-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.tt-align-self-md-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.tt-align-self-md-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.tt-align-self-md-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}

	/* Reverce */
	.tt-md-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.tt-md-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
}

@media (min-width: 992px) {
	.tt-col-lg {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	/* Row columns */
	.tt-row-cols-lg-1 > * {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.tt-row-cols-lg-2 > * {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.tt-row-cols-lg-3 > * {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.tt-row-cols-lg-4 > * {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.tt-row-cols-lg-5 > * {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.tt-row-cols-lg-6 > * {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	/* Columns auto */
	.tt-col-lg-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}

	/* Columns */
	.tt-col-lg-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.tt-col-lg-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.tt-col-lg-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.tt-col-lg-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.tt-col-lg-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.tt-col-lg-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.tt-col-lg-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.tt-col-lg-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.tt-col-lg-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.tt-col-lg-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.tt-col-lg-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.tt-col-lg-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	/* Order */
	.tt-order-lg-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.tt-order-lg-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.tt-order-lg-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.tt-order-lg-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.tt-order-lg-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.tt-order-lg-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.tt-order-lg-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.tt-order-lg-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.tt-order-lg-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.tt-order-lg-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.tt-order-lg-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.tt-order-lg-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.tt-order-lg-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.tt-order-lg-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.tt-order-lg-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	/* Offset */
	.tt-offset-lg-0 {
		margin-left: 0;
	}
	.tt-offset-lg-1 {
		margin-left: 8.333333%;
	}
	.tt-offset-lg-2 {
		margin-left: 16.666667%;
	}
	.tt-offset-lg-3 {
		margin-left: 25%;
	}
	.tt-offset-lg-4 {
		margin-left: 33.333333%;
	}
	.tt-offset-lg-5 {
		margin-left: 41.666667%;
	}
	.tt-offset-lg-6 {
		margin-left: 50%;
	}
	.tt-offset-lg-7 {
		margin-left: 58.333333%;
	}
	.tt-offset-lg-8 {
		margin-left: 66.666667%;
	}
	.tt-offset-lg-9 {
		margin-left: 75%;
	}
	.tt-offset-lg-10 {
		margin-left: 83.333333%;
	}
	.tt-offset-lg-11 {
		margin-left: 91.666667%;
	}

	/* Justify content */
	.tt-justify-content-lg-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.tt-justify-content-lg-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.tt-justify-content-lg-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.tt-justify-content-lg-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.tt-justify-content-lg-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}

	/* Align items */
	.tt-align-items-lg-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.tt-align-items-lg-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.tt-align-items-lg-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.tt-align-items-lg-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.tt-align-items-lg-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}

	/* Align content */
	.tt-align-content-lg-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.tt-align-content-lg-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.tt-align-content-lg-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.tt-align-content-lg-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.tt-align-content-lg-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.tt-align-content-lg-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}

	/* Align self */
	.tt-align-self-lg-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.tt-align-self-lg-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.tt-align-self-lg-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.tt-align-self-lg-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.tt-align-self-lg-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.tt-align-self-lg-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}

	/* Reverce */
	.tt-lg-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.tt-lg-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
}

@media (min-width: 1025px) {
	.tt-col-xl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	/* Row columns */
	.tt-row-cols-xl-1 > * {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.tt-row-cols-xl-2 > * {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.tt-row-cols-xl-3 > * {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.tt-row-cols-xl-4 > * {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.tt-row-cols-xl-5 > * {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.tt-row-cols-xl-6 > * {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	/* Columns auto */
	.tt-col-xl-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}

	/* Columns */
	.tt-col-xl-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.tt-col-xl-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.tt-col-xl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.tt-col-xl-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.tt-col-xl-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.tt-col-xl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.tt-col-xl-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.tt-col-xl-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.tt-col-xl-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.tt-col-xl-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.tt-col-xl-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.tt-col-xl-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	/* Order */
	.tt-order-xl-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.tt-order-xl-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.tt-order-xl-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.tt-order-xl-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.tt-order-xl-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.tt-order-xl-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.tt-order-xl-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.tt-order-xl-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.tt-order-xl-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.tt-order-xl-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.tt-order-xl-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.tt-order-xl-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.tt-order-xl-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.tt-order-xl-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.tt-order-xl-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	/* Offset */
	.tt-offset-xl-0 {
		margin-left: 0;
	}
	.tt-offset-xl-1 {
		margin-left: 8.333333%;
	}
	.tt-offset-xl-2 {
		margin-left: 16.666667%;
	}
	.tt-offset-xl-3 {
		margin-left: 25%;
	}
	.tt-offset-xl-4 {
		margin-left: 33.333333%;
	}
	.tt-offset-xl-5 {
		margin-left: 41.666667%;
	}
	.tt-offset-xl-6 {
		margin-left: 50%;
	}
	.tt-offset-xl-7 {
		margin-left: 58.333333%;
	}
	.tt-offset-xl-8 {
		margin-left: 66.666667%;
	}
	.tt-offset-xl-9 {
		margin-left: 75%;
	}
	.tt-offset-xl-10 {
		margin-left: 83.333333%;
	}
	.tt-offset-xl-11 {
		margin-left: 91.666667%;
	}

	/* Justify content */
	.tt-justify-content-xl-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.tt-justify-content-xl-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.tt-justify-content-xl-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.tt-justify-content-xl-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.tt-justify-content-xl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}

	/* Align items */
	.tt-align-items-xl-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.tt-align-items-xl-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.tt-align-items-xl-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.tt-align-items-xl-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.tt-align-items-xl-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}

	/* Align content */
	.tt-align-content-xl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.tt-align-content-xl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.tt-align-content-xl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.tt-align-content-xl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.tt-align-content-xl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.tt-align-content-xl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}

	/* Align self */
	.tt-align-self-xl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.tt-align-self-xl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.tt-align-self-xl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.tt-align-self-xl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.tt-align-self-xl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.tt-align-self-xl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}

	/* Reverce */
	.tt-xl-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.tt-xl-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
}

@media (min-width: 1400px) {
	.tt-col-xxl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	/* Row columns */
	.tt-row-cols-xxl-1 > * {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.tt-row-cols-xxl-2 > * {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.tt-row-cols-xxl-3 > * {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.tt-row-cols-xxl-4 > * {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.tt-row-cols-xxl-5 > * {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.tt-row-cols-xxl-6 > * {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	/* Columns auto */
	.tt-col-xxl-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}

	/* Columns */
	.tt-col-xxl-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.tt-col-xxl-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.tt-col-xxl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.tt-col-xxl-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.tt-col-xxl-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.tt-col-xxl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.tt-col-xxl-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.tt-col-xxl-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.tt-col-xxl-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.tt-col-xxl-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.tt-col-xxl-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.tt-col-xxl-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	/* Order */
	.tt-order-xxl-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.tt-order-xxl-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.tt-order-xxl-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.tt-order-xxl-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.tt-order-xxl-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.tt-order-xxl-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.tt-order-xxl-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.tt-order-xxl-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.tt-order-xxl-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.tt-order-xxl-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.tt-order-xxl-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.tt-order-xxl-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.tt-order-xxl-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.tt-order-xxl-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.tt-order-xxl-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	/* Offset */
	.tt-offset-xxl-0 {
		margin-left: 0;
	}
	.tt-offset-xxl-1 {
		margin-left: 8.333333%;
	}
	.tt-offset-xxl-2 {
		margin-left: 16.666667%;
	}
	.tt-offset-xxl-3 {
		margin-left: 25%;
	}
	.tt-offset-xxl-4 {
		margin-left: 33.333333%;
	}
	.tt-offset-xxl-5 {
		margin-left: 41.666667%;
	}
	.tt-offset-xxl-6 {
		margin-left: 50%;
	}
	.tt-offset-xxl-7 {
		margin-left: 58.333333%;
	}
	.tt-offset-xxl-8 {
		margin-left: 66.666667%;
	}
	.tt-offset-xxl-9 {
		margin-left: 75%;
	}
	.tt-offset-xxl-10 {
		margin-left: 83.333333%;
	}
	.tt-offset-xxl-11 {
		margin-left: 91.666667%;
	}

	/* Justify content */
	.tt-justify-content-xxl-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.tt-justify-content-xxl-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.tt-justify-content-xxl-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.tt-justify-content-xxl-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.tt-justify-content-xxl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}

	/* Align items */
	.tt-align-items-xxl-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.tt-align-items-xxl-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.tt-align-items-xxl-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.tt-align-items-xxl-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.tt-align-items-xxl-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}

	/* Align content */
	.tt-align-content-xxl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.tt-align-content-xxl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.tt-align-content-xxl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.tt-align-content-xxl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.tt-align-content-xxl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.tt-align-content-xxl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}

	/* Align self */
	.tt-align-self-xxl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.tt-align-self-xxl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.tt-align-self-xxl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.tt-align-self-xxl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.tt-align-self-xxl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.tt-align-self-xxl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}

	/* Reverce */
	.tt-xxl-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.tt-xxl-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
}
